import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "utils/firebase";
import type { ExerciseData } from "features/exercises";

export function useClientWorkoutExercise({
  clientId,
  workoutId,
  exerciseId,
}: {
  clientId: string;
  workoutId: string;
  exerciseId: string;
}) {
  const [value, loading, error] = useDocumentDataOnce(
    doc(
      firestore,
      "users",
      clientId,
      "workouts",
      workoutId,
      "exercises",
      exerciseId
    )
  );

  return {
    loading,
    error,
    data: value as ExerciseData | null,
  };
}
