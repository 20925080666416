import { useCallback } from "react";

import ChatMessageVideo from "./ChatMessageVideo";
import ChatMessageAudio from "./ChatMessageAudio";
import ChatMessageText from "./ChatMessageText";
import ChatMessageReplyTo from "./ChatMessageReplyTo";
import useLongPress from "utils/hooks/useLongPress";
import type { ChatMessage } from "features/chat";
import type { User } from "features/user";
// import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// import Paper from "@mui/material/Paper";
import MessageBubble from "./MessageBubble";
import MessageMeta from "./MessageMeta";
import MessageTime from "./MessageTime";
import MessageHeader from "./MessageHeader";
import MessageCreator from "./MessageCreator";
import MessageAvatar from "./MessageAvatar";
import MessageRoot from "./MessageRoot";

export default function ChatMessageListItem({
  chatMessage,
  chatMessageCreator,
  chatMessageReplyToCreator,
  isDifferentCreator,
  isCreator,
  isSystem,
  isRead,
  setSelectedChatMessage,
  selected,
}: {
  chatMessage: ChatMessage;
  chatMessageCreator: User;
  chatMessageReplyToCreator: User | null;
  isDifferentCreator: boolean;
  isCreator: boolean;
  isSystem: boolean;
  isRead: boolean;
  setSelectedChatMessage: (chatMessage: ChatMessage) => void;
  selected: boolean;
}) {
  const onLongPress = useCallback(() => {
    setSelectedChatMessage(chatMessage);
  }, [chatMessage, setSelectedChatMessage]);

  const props = useLongPress(onLongPress);
  const showAvatar = !isCreator && isDifferentCreator;
  const showHeader = !isCreator;

  return (
    <MessageRoot {...props} selected={selected}>
      {showAvatar && (
        <MessageAvatar src={chatMessageCreator.photoURL}>
          {chatMessageCreator.displayName[0]}
        </MessageAvatar>
      )}

      <MessageBubble
        align={isCreator ? "right" : "left"}
        backgroundColor={isCreator ? "primary.dark" : "background.paper"}
      >
        {showHeader && (
          <MessageHeader>
            <MessageCreator color={isSystem ? "secondary" : "primary"}>
              {chatMessageCreator.displayName}
            </MessageCreator>
          </MessageHeader>
        )}
        {chatMessage.replyTo && chatMessageReplyToCreator && (
          <ChatMessageReplyTo
            replyTo={chatMessage.replyTo}
            creator={chatMessageReplyToCreator}
            color={isCreator ? "creator" : isRead ? "read" : "default"}
          />
        )}
        {chatMessage.video && <ChatMessageVideo video={chatMessage.video} />}
        {chatMessage.audio && <ChatMessageAudio audio={chatMessage.audio} />}
        {chatMessage.text && (
          <ChatMessageText
            text={chatMessage.text}
            color={isCreator ? "creator" : isRead ? "read" : "default"}
            meta={
              <MessageMeta>
                <MessageTime
                  updatedAt={chatMessage.updatedAt.toDate()}
                  createdAt={chatMessage.createdAt.toDate()}
                  color={
                    isCreator ? "rgba(255, 255, 255, 0.5)" : "text.secondary"
                  }
                />
              </MessageMeta>
            }
          />
        )}
      </MessageBubble>
    </MessageRoot>
  );
}
