import { Link } from "react-router";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const VIDEO_URL =
  "https://firebasestorage.googleapis.com/v0/b/fitness-app-57686.appspot.com/o/statics%2Fvideos%2Fbg%2F3.mp4?alt=media&token=97001d74-10fe-4d81-877a-beda3c04e330";

export default function Homepage() {
  const { t } = useTranslation("website");

  return (
    <>
      <Helmet>
        <title>Online Fitness App</title>
      </Helmet>

      <Paper test-id="hero-block" elevation={0} sx={{ position: "relative" }}>
        <Box
          component="video"
          src={VIDEO_URL}
          autoPlay
          loop
          muted
          playsInline
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            opacity: 0,
            transition: "opacity 1s",
          }}
          onCanPlayThrough={(event: React.SyntheticEvent<HTMLVideoElement>) => {
            event.currentTarget.style.opacity = "1";
          }}
        />
        <Container
          maxWidth={false}
          sx={{ position: "relative", backgroundColor: "rgba(0,0,0,0.8)" }}
        >
          <Box
            sx={{
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                py: 5,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                align="center"
                color="textPrimary"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  fontWeight: 900,
                  maxWidth: 800,
                  lineHeight: 1.2,
                  mb: 3,
                }}
              >
                {t("homepage.hero.title.line1")}
                <br />
                {t("homepage.hero.title.line2")}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/signup"
              >
                {t("homepage.hero.button")}
              </Button>
              <Typography
                color="textSecondary"
                variant="caption"
                display="block"
                align="center"
                sx={{ mt: 2 }}
              >
                {t("homepage.hero.footer.line1")}
                <br />
                {t("homepage.hero.footer.line2")}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Paper>
    </>
  );
}
