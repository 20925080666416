import FormList, { type Schema } from "components/FormList";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useExercise } from "./queries";
import { updateExercise } from "./actions";
import Player from "components/Player";
import NotFound from "components/NotFound";
import ErrorScreen from "components/ErrorScreen";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { logEvent } from "utils/analytics";
// import { AIButton } from "features/ai";
// import { generateDescriptionPrompt } from "./prompts";

export default function EditExerciseScreen() {
  const { t } = useTranslation(["coach", "core"]);

  const { exerciseId } = useParams() as { exerciseId: string };

  const { loading, error, data } = useExercise({ exerciseId });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!data) {
    return (
      <NotFound
        icon={<VideoLibraryIcon sx={{ fontSize: 60 }} />}
        title={t("screens.edit-exercise.not-found.title")}
        text={t("screens.edit-exercise.not-found.text")}
      />
    );
  }

  const schema: Schema = [
    // {
    //   header: t("screens.edit-exercise.fields.videoUrl"),
    //   fields: [
    //     {
    //       type: "text",
    //       name: "videoUrl",
    //       placeholder: "https://www.youtube.com/watch?v=...",
    //     },
    //   ],
    // },
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.edit-exercise.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "markdown",
          name: "description",
          placeholder: t("screens.edit-exercise.fields.description"),
        },
      ],
    },
    {
      header: t("screens.edit-exercise.fields.tags.header"),
      square: true,
      fields: [
        {
          type: "tags",
          name: "tags",
          placeholder: t("screens.edit-exercise.fields.tags.placeholder"),
          addPlaceholder: t(
            "screens.edit-exercise.fields.tags.add-placeholder"
          ),
          deleteTooltip: t("screens.edit-exercise.fields.tags.delete-tooltip"),
        },
      ],
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.edit-exercise.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    title: string;
    description?: string;
    tags?: string[];
    // videoUrl?: string;
    // reps?: number;
    // sets?: number;
    // rest?: number;
    // weight?: number;
    // duration?: number;
  }) => {
    await updateExercise({ exerciseId: exerciseId!, ...values });

    logEvent("update_exercise", {
      exerciseId: exerciseId,
    });

    enqueueSnackbar(t("screens.edit-exercise.success"), {
      variant: "success",
    });
  };

  const values = {
    title: data.title,
    description: data.description,
    tags: data.tags,
    // videoUrl: data.videoUrl,
    // reps: data.reps,
    // sets: data.sets,
    // rest: data.rest,
    // weight: data.weight,
    // duration: data.duration,
  };

  return (
    <>
      <Player
        video={data.video}
        metadataVideoId={exerciseId}
        metadataVideoTitle={data.title}
      />
      <FormList
        // key={data.updatedAt.toString()}
        schema={schema}
        values={values}
        onSubmit={onSubmit}
        spacing={1}
      />
    </>
  );
}
