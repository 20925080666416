import ExercisesPage from "./page";
// import CreateExercisePage from "./create/page";
// import ExercisePage from "./[exerciseId]/page";
import EditExercisePage from "./[exerciseId]/edit/page";
import { deleteExercise } from "./[exerciseId]/edit/actions";

import { CreateVideoUploadButton } from "features/exercises";
import HeaderBackButton from "components/HeaderBackButton";
import HeaderDeleteButton from "components/HeaderDeleteButton";

const routes = [
  {
    index: true,
    element: <ExercisesPage />,
    handle: {
      header: {
        title: "coach.screens.exercises.title",
        action: <CreateVideoUploadButton title="Create exercise" />,
        search: true,
      },
    },
  },

  {
    path: ":exerciseId",
    children: [
      {
        path: "edit",
        element: <EditExercisePage />,
        handle: {
          header: {
            title: "coach.screens.edit-exercise.title",
            back: <HeaderBackButton to={-1} />,
            action: (
              <HeaderDeleteButton
                title="Delete exercise"
                success="Exercise deleted"
                action={deleteExercise}
              />
            ),
          },
        },
      },
    ],
  },
];

export default routes;
