interface Section<Item> {
  header: string;
  items: Item[];
}

export default function groupToSections<Item, Key extends keyof Item>(
  items: Item[],
  groupByField: Key,
  formatHeader: (item: Item[Key]) => string
) {
  const sections: Section<Item>[] = [];

  if (!items) {
    return sections;
  }

  items.forEach((item, index, items) => {
    const key = item[groupByField];
    const header = formatHeader(key);
    const prevWorkout = items[index - 1];

    if (prevWorkout && formatHeader(prevWorkout[groupByField]) === header) {
      sections[sections.length - 1].items.push(item);
    } else {
      sections.push({
        header: header,
        items: [item],
      });
    }
  });

  return sections;
}
