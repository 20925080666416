import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { createProgram } from "./actions";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";

export default function CreateProgramPage() {
  const { t } = useTranslation("coach");
  const navigate = useNavigate();

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.create-program.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.create-program.fields.description"),
          multiline: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-program.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: { title: string; description?: string }) => {
    const program = await createProgram(values);

    enqueueSnackbar(t("screens.create-program.success"), {
      variant: "success",
    });

    navigate(`../${program.id}`);
  };

  return <FormList schema={schema} onSubmit={onSubmit} />;
}
