/**
 * Pricing config
 *
 * TODO: Use data from backend.
 */

export const plans = [
  {
    id: "starter",
    title: "Starter",
    description: "Perfect for new trainers with a small client base.",
    price: 30,
    clients: 5,
  },
  {
    id: "pro",
    title: "Pro",
    description: "Ideal for trainers expanding their client base.",
    price: 50,
    clients: 10,
  },
  {
    id: "Elite",
    title: "Elite",
    description: "Designed for trainers with a larger client base.",
    price: 80,
    clients: 20,
  },
];
