import FormList, { type Schema } from "components/FormList";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useClientWorkoutExercise } from "./queries";
import { updateClientWorkoutExercise } from "./actions";
import Player from "components/Player";
import ErrorScreen from "components/ErrorScreen";
import { logEvent } from "utils/analytics";

export default function EditClientWorkoutExerciseScreen() {
  const { t } = useTranslation(["coach", "core"]);

  const { clientId, workoutId, exerciseId } = useParams() as {
    clientId: string;
    workoutId: string;
    exerciseId: string;
  };

  const { loading, error, data } = useClientWorkoutExercise({
    clientId,
    workoutId,
    exerciseId,
  });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!data) return null;

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.edit-client-workout-exercise.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t(
            "screens.edit-client-workout-exercise.fields.description"
          ),
          multiline: true,
        },
      ],
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.edit-client-workout-exercise.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    title: string;
    description?: string;
    type?: string;
    // videoUrl?: string;
  }) => {
    await updateClientWorkoutExercise({
      clientId,
      workoutId,
      exerciseId,
      ...values,
    });

    logEvent("update_client_workout_exercise", {
      clientId,
      workoutId,
      exerciseId,
    });

    enqueueSnackbar(t("screens.edit-client-workout-exercise.success"), {
      variant: "success",
    });
  };

  const values = {
    title: data.title,
    description: data.description,
    videoUrl: data.videoUrl,
  };

  return (
    <>
      <Player
        video={data.video}
        metadataVideoId={exerciseId}
        metadataVideoTitle={data.title}
      />

      <FormList schema={schema} values={values} onSubmit={onSubmit} />
    </>
  );
}
