import { signInWithCustomToken } from "firebase/auth";
import { auth } from "utils/firebase";
import api from "utils/api";

export async function signUp(values: {
  email: string;
  password: string;
  displayName: string;
  language: string;
}) {
  const res = await api("signUp", values);

  const { authToken } = res.data as { authToken: string };

  return signInWithCustomToken(auth, authToken);
}

export async function requestAnInvitation({
  email,
  language,
}: {
  email: string;
  language: string;
}) {
  return api("requestAnInvitation", { email, language });
}
