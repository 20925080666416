export default function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      //   width="512"
      //   height="512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M254.086 41.953c35.738 0 71.927 1.932 95.993 3.552 46.164 3.108 82.114 40.35 83.621 86.629 1.168 35.931 1.76 77.553 1.76 123.712 0 46.159-.592 87.78-1.76 123.71-1.507 46.281-37.457 83.523-83.623 86.63-24.064 1.62-60.253 3.552-95.991 3.552s-71.926-1.932-95.992-3.552c-46.165-3.107-82.114-40.349-83.621-86.628-1.169-35.932-1.76-77.553-1.76-123.712 0-46.159.591-87.781 1.76-123.711 1.507-46.28 37.456-83.522 83.623-86.63 24.064-1.62 60.253-3.552 95.99-3.552Z"
        fill="#2E67CF"
      />
      <path
        d="M350.078 466.186a91.348 91.348 0 0 0 11.711-1.569c-135.708-78.758-222.672-256.859-115.836-422.63-33.024.259-65.619 2.021-87.857 3.518-46.167 3.108-82.116 40.35-83.623 86.63-1.169 35.93-1.76 77.552-1.76 123.711 0 46.159.591 87.78 1.76 123.712 1.507 46.279 37.456 83.521 83.621 86.628 24.065 1.62 60.255 3.552 95.992 3.552s71.928-1.931 95.992-3.552Z"
        fill="#0046AF"
      />
      <path
        d="M274.958 431.976h-41.741a6.422 6.422 0 1 1 0-12.843h41.741a6.421 6.421 0 0 1 0 12.843Z"
        fill="#EEECFF"
      />
      <path
        d="M391.792 133.498c-.799-24.54-20.032-44.508-44.53-46.157-25.629-1.726-59.685-3.457-93.175-3.457-10.439 0-20.932.169-31.209.452-87.392 71.649-64.139 259.916 45.478 300.639 32.081-.435 63.893-2.223 87.187-3.854 20.635-1.446 36.772-18.378 37.186-39.06.468-23.292.803-51.944.803-86.214-.001-54.793-.857-95.219-1.74-122.349Z"
        fill="#FF6E80"
      />
      <path
        d="M268.356 384.975c-65.876-80.506-94.633-191.186-45.479-300.639-22.692.627-44.325 1.817-61.966 3.005-24.497 1.65-43.731 21.617-44.53 46.157-.883 27.13-1.739 67.556-1.739 122.348 0 34.271.335 62.922.802 86.215.414 20.681 16.552 37.614 37.187 39.059 26.749 1.873 64.732 3.955 101.455 3.955 4.745 0 9.51-.036 14.27-.1Z"
        fill="#F8475E"
      />
      <path
        d="M280.426 301.666 83.97 104.207c-8.62-8.652-22.623-8.677-31.275-.056l-9.374 9.34c-8.652 8.621-8.676 22.624-.055 31.276l196.454 197.46c8.621 8.652 22.624 8.677 31.276.056l9.373-9.34c8.653-8.622 8.678-22.624.057-31.277Z"
        fill="#EEECFF"
      />
      <path
        d="m167.372 118.686-5.582-5.603c-9.385-9.419-22.456-12.925-34.589-10.519-47.16 29.56-24.058 86.213 12.824 80.613l27.281-27.185c10.321-10.283 10.35-26.986.066-37.306Z"
        fill="#59D5FF"
      />
      <path
        d="M127.201 102.564a38.08 38.08 0 0 0-19.537 10.423l-55.347 55.151c-14.973 14.92-15.016 39.153-.096 54.126l5.583 5.603c10.283 10.32 26.987 10.35 37.307.065l44.915-44.755c-25.855-9.834-39.406-53.99-12.825-80.613Z"
        fill="#00C4FC"
      />
      <path
        d="m271.822 223.813-5.582-5.603c-10.284-10.32-26.987-10.35-37.308-.066l-12.616 12.573c-27.854 39.729-16.084 83.598 28.356 74.181l27.053-26.958c14.974-14.921 15.017-39.154.097-54.127Z"
        fill="#59D5FF"
      />
      <path
        d="M244.672 304.897c-34.673-9.64-41.985-49.417-28.356-74.181l-59.579 59.368c-10.321 10.284-10.351 26.987-.066 37.307l5.582 5.602c14.92 14.973 39.153 15.016 54.126.097l28.293-28.193Z"
        fill="#00C4FC"
      />
      <path
        d="M452.057 122.472h-74.19c-33.447 12.807-30.747 38.114 1.701 46.254h72.489c12.773 0 23.127-10.354 23.127-23.126 0-12.774-10.354-23.128-23.127-23.128Z"
        fill="#F7E969"
      />
      <path
        d="M452.057 211.352h-40.604c-35.108 9.128-27.233 38.708 1.702 46.254h38.902c12.773 0 23.127-10.354 23.127-23.126 0-12.774-10.354-23.128-23.127-23.128Z"
        fill="#72D98B"
      />
      <path
        d="M377.867 122.472H261.233c-12.773 0-23.127 10.355-23.127 23.127 0 12.773 10.354 23.126 23.127 23.126h118.335c-13.721-9.238-21.538-30.153-1.701-46.253Z"
        fill="#F5C956"
      />
      <path
        d="M411.453 211.352h-60.184c-12.773 0-23.127 10.355-23.127 23.127 0 12.772 10.354 23.126 23.127 23.126h61.886c-13.722-9.238-21.539-30.153-1.702-46.253Z"
        fill="#00C67F"
      />
    </svg>
  );
}
