import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import TelegramIcon from "components/Icons/TelegramIcon";
import InstagramIcon from "components/Icons/InstagramIcon";
import TikTokIcon from "components/Icons/TikTokIcon";

export default function Footer() {
  const { t } = useTranslation("website");

  // https://simpleicons.org/
  const socialLinks = [
    {
      href: "https://t.me/onlinefitnessapp",
      icon: <TelegramIcon />,
    },
    {
      href: "https://www.instagram.com/onlinefitnessapp",
      icon: <InstagramIcon />,
    },
    {
      href: "https://www.tiktok.com/@onlinefitnessapp",
      icon: <TikTokIcon />,
    },
  ];

  const links = [
    { to: "/", text: t("footer.links.homepage") },
    { to: "/features", text: t("footer.links.features") },
    { to: "/pricing", text: t("footer.links.pricing") },
    { to: "/faq", text: t("footer.links.faq") },
    { to: "/about", text: t("footer.links.about") },
    { to: "/founders", text: t("footer.links.founders") },
  ];

  const terms = [
    { to: "/terms-of-service", text: t("footer.terms.termsOfService") },
    { to: "/refund-policy", text: t("footer.terms.refundPolicy") },
    { to: "/privacy-policy", text: t("footer.terms.privacyPolicy") },
    { to: "/guarantee", text: t("footer.terms.guarantee") },
  ];

  return (
    <Box component="footer" sx={{ pb: 10, textAlign: "center" }}>
      <Divider sx={{ mb: 5 }} />
      <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
        <Breadcrumbs
          separator="•"
          sx={{
            "& .MuiBreadcrumbs-ol": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          {links.map((link) => (
            <MuiLink
              component={Link}
              to={link.to}
              key={link.to}
              underline="hover"
            >
              {link.text}
            </MuiLink>
          ))}
        </Breadcrumbs>

        <Breadcrumbs
          separator="•"
          sx={{
            "& .MuiBreadcrumbs-ol": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          {terms.map((link) => (
            <MuiLink
              component={Link}
              to={link.to}
              key={link.to}
              color="textSecondary"
              underline="hover"
            >
              {link.text}
            </MuiLink>
          ))}
        </Breadcrumbs>

        <Stack direction="row" spacing={2} alignItems={"center"}>
          {socialLinks.map((socialLink) => (
            <IconButton
              key={socialLink.href}
              href={socialLink.href}
              target="_blank"
            >
              <SvgIcon>{socialLink.icon}</SvgIcon>
            </IconButton>
          ))}
        </Stack>

        <Typography variant="body2" color="textSecondary">
          {t("footer.copyright")}
        </Typography>
      </Stack>
    </Box>
  );
}
