import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Faq from "components/Faq";
import { Link } from "react-router";
import MuiLink from "@mui/material/Link";

export default function FaqPage() {
  return (
    <Container maxWidth="md" sx={{ my: 5 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Frequently Asked Questions
      </Typography>
      <Faq
        items={[
          {
            question: "What is OnlineFitness.app?",
            answer: (
              <>
                OnlineFitness.app is a powerful platform designed for personal
                trainers to manage clients, sell workout programs, and grow
                their online coaching business with ease. Read more{" "}
                <MuiLink component={Link} to="/about" underline="hover">
                  about us
                </MuiLink>{" "}
                and our{" "}
                <MuiLink component={Link} to="/features" underline="hover">
                  features
                </MuiLink>
                .
              </>
            ),
          },
          {
            question: "How does the 14-day free trial work?",
            answer:
              "You get full access to all features for 14 days — no credit card required. If you love it, you can continue with a paid plan.",
          },
          {
            question: "Can I cancel my subscription anytime?",
            answer:
              "Yes! You can cancel your subscription at any time, and you won’t be charged after your current billing cycle ends.",
          },
          {
            question:
              "Do I need any technical skills to use OnlineFitness.app?",
            answer:
              "Not at all! The platform is designed to be user-friendly, so you can focus on coaching while we handle the tech.",
          },
          {
            question: "How do I get paid for my coaching programs?",
            answer:
              "You can set up your payment options inside the app and get paid directly through PayPal, Stripe, or other supported payment providers.",
          },
          {
            question: "Can I upload my own workout videos and programs?",
            answer:
              "Yes! You can upload custom workout videos, create structured training programs, and deliver personalized coaching to your clients.",
          },
          {
            question: "Does OnlineFitness.app work on mobile devices?",
            answer:
              "Absolutely! OnlineFitness.app is a progressive web app (PWA), meaning it works seamlessly on any device—desktop, tablet, or smartphone.",
          },
          {
            question: "How many clients can I manage?",
            answer: (
              <>
                It depends on your plan! Our pricing is based on the number of
                clients you train online. Check our{" "}
                <MuiLink component={Link} to="/pricing" underline="hover">
                  pricing page
                </MuiLink>{" "}
                for details.
              </>
            ),
          },
          {
            question: "Can I brand the platform with my logo and colors?",
            answer:
              "Yes! Each trainer gets a personalized app version with their own branding to make it feel like a custom solution.",
          },
          {
            question: "What kind of support do you offer?",
            answer: (
              <>
                We provide email support, in-app guidance, and a knowledge base
                to help you get the most out of OnlineFitness.app. Please reach
                out to{" "}
                <MuiLink
                  href="mailto:support@onlinefitness.app"
                  underline="hover"
                >
                  support@onlinefintness.app
                </MuiLink>{" "}
                if you have any questions.
              </>
            ),
          },
        ]}
      />
    </Container>
  );
}
