import { useState, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { NavLink, Link as RouterLink } from "react-router";
import Logo from "components/Logo";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Avatar from "@mui/material/Avatar";
import { useViewer } from "features/viewer";
import Menu, { type MenuItem } from "components/Menu";
import { useTranslation } from "react-i18next";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HomeIcon from "@mui/icons-material/Home";
import { purple, green, blue, orange } from "@mui/material/colors";

export default function Header({
  position,
  color,
}: {
  position?: "fixed" | "absolute" | "sticky";
  color?: "default" | "transparent";
}) {
  const { t } = useTranslation("website");
  const viewer = useViewer();

  const trigger = useScrollTrigger({
    threshold: 64,
  });

  const elevation = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [open, setOpen] = useState(false);

  const toggleDrawer = useCallback(
    (newOpen: boolean) => () => {
      setOpen(newOpen);
    },
    []
  );

  const mainMenuItems: MenuItem[] = [
    {
      primary: t("header.menu.features"),
      icon: <FormatListNumberedIcon color="inherit" />,
      iconColor: purple[500],
      link: { to: "/features" },
    },
    {
      primary: t("header.menu.pricing"),
      icon: <LocalOfferIcon color="inherit" />,
      iconColor: orange[500],
      link: { to: "/pricing" },
    },
    {
      primary: t("header.menu.about"),
      icon: <InfoOutlinedIcon color="inherit" />,
      iconColor: blue[500],
      link: { to: "/about" },
    },
    // { label: "Features", to: "/features" },
    // { label: "Pricing", to: "/pricing" },
    // { label: "About", to: "/about" },
  ];
  const mobileMainMenuItems: MenuItem[] = [
    {
      primary: t("header.menu.home"),
      icon: <HomeIcon color="inherit" />,
      iconColor: green[500],
      link: { to: "/" },
    },
  ];

  const menuItems: MenuItem[][] = [
    mobileMainMenuItems.concat(mainMenuItems),
    //
  ];

  // const mobileMenuLinks = [
  //   { label: "Home", to: "/" },
  //   ...menuLinks, // add other links
  // ];

  const drawerList = (
    <Box
      sx={{ width: "100vw" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Menu menu={menuItems} />
    </Box>
  );

  let bgcolor = "background.paper";

  if (color === "transparent") {
    bgcolor = "transparent";
  }

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          elevation={0}
          position={position}
          color="default"
          style={{
            backgroundColor: bgcolor,
          }}
          sx={{
            bgcolor,
            // elevation && position !== "absolute"
            //   ? "background.paper"
            //   : "rgba(255, 255, 255, 0)",
            transition: "all ease 0.3s",
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters sx={{ alignItems: "center" }}>
              <IconButton
                edge="start"
                onClick={toggleDrawer(true)}
                sx={{ display: { xs: "flex", md: "none" } }}
                size="large"
              >
                <MenuIcon />
              </IconButton>
              <Box
                component={RouterLink}
                to="/"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Logo height="40" width="40" />
              </Box>
              <Typography
                component={RouterLink}
                to="/"
                variant="h6"
                color="textPrimary"
                sx={{
                  ml: 1,
                  mr: 6,
                  fontWeight: 700,
                  textDecoration: "none",
                  display: { xs: "none", sm: "block" },
                }}
              >
                {t("header.title")}
              </Typography>
              <Stack
                direction="row"
                spacing={4}
                alignItems="center"
                sx={{
                  // mx: "auto",
                  display: { xs: "none", md: "block" },
                  "& a": {
                    color: "text.primary",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    "&.active": {
                      color: "primary.main",
                    },
                  },
                }}
              >
                {mainMenuItems.map((menuLink) => (
                  <Link
                    key={menuLink.primary}
                    to={menuLink.link!.to!}
                    component={NavLink}
                    underline="none"
                    variant="subtitle1"
                    color="inherit"
                    sx={{ fontWeight: 500, textTransform: "uppercase" }}
                  >
                    {menuLink.primary}
                  </Link>
                ))}
              </Stack>
              {viewer ? (
                <IconButton
                  sx={{ ml: "auto" }}
                  edge="end"
                  component={RouterLink}
                  to={viewer.role}
                >
                  <Avatar sx={{ width: 32, height: 32 }} src={viewer.photoURL}>
                    {viewer.displayName && viewer.displayName[0]}
                  </Avatar>
                </IconButton>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ ml: "auto" }}
                >
                  <Button
                    sx={{ mr: "auto" }}
                    component={RouterLink}
                    to="/signin"
                    size="large"
                  >
                    SIGN IN
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    component={RouterLink}
                    to="/signup"
                    size="large"
                  >
                    SIGN UP
                  </Button>
                </Stack>
              )}
            </Toolbar>
          </Container>
          {elevation && position !== "absolute" && (
            <Divider sx={{ borderColor: "background.default" }} />
          )}
        </AppBar>
      </Slide>
      <Drawer open={open} onClose={toggleDrawer(false)} elevation={0}>
        {drawerList}
      </Drawer>
    </>
  );
}
