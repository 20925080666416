import { createBrowserRouter } from "react-router";

import ErrorBoundary from "components/ErrorBoundary";

import RootLayout from "app/layout";
import rootRoutes from "app/routes";

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: rootRoutes,
  },
]);
