import dayjs from "utils/dayjs";

export default function formatDate(
  date: string | null | undefined | Date | dayjs.Dayjs
): string {
  if (!date) {
    return "Never";
  }

  if (dayjs(date).isToday()) {
    return "Today";
  }

  if (dayjs(date).isYesterday()) {
    return "Yesterday";
  }

  if (dayjs(date).isTomorrow()) {
    return "Tomorrow";
  }

  if (dayjs().isSame(date, "week")) {
    return dayjs(date).format("dddd");
  }

  if (dayjs().isSame(date, "year")) {
    return dayjs(date).format("MMMM D");
  }

  return dayjs(date).format("MMMM D, YYYY");
}
