import ReferralPage from "./page";

const routes = [
  {
    index: true,
    element: <ReferralPage />,
    handle: {
      header: {
        title: "coach.screens.referral.title",
      },
    },
  },
];

export default routes;
