import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { collection, where, query, doc } from "firebase/firestore";
import { firestore } from "utils/firebase";
import { InvitationStatus } from "./types";

export function useInvitations({
  clientId,
  coachId,
  status,
}: {
  clientId: string;
  coachId: string;
  status?: InvitationStatus;
}) {
  let q = query(collection(firestore, "invitations"));

  if (status) {
    q = query(q, where("status", "==", status));
  }

  return useCollection(
    query(
      q, //
      where("clientId", "==", clientId),
      where("coachId", "==", coachId)
    )
  );
}

export function useInvitation({ invitationId }: { invitationId: string }) {
  return useDocument(doc(firestore, "invitations", invitationId));
}
