import SelectField from "./SelectField";
import { useMemo } from "react";

export default function SelectDay({
  value,
  onChange,
  daysInMonth,
}: {
  value: number;
  onChange: (value: number) => void;
  daysInMonth: number;
}) {
  const options = useMemo(
    () =>
      Array.from({ length: daysInMonth }, (_, day) => {
        return {
          value: day + 1,
          label: (day + 1).toString(),
        };
      }),
    [daysInMonth]
  );

  return <SelectField onChange={onChange} options={options} value={value} />;
}
