import Typography from "@mui/material/Typography";

export default function ChatMessageText({
  text,
  color,
  meta,
}: {
  text: string;
  color: "creator" | "read" | "default";
  meta?: React.ReactNode;
}) {
  return (
    <Typography
      variant="body2"
      sx={{ color: colorToColor[color] }}
      test-id="ChatMessageText"
      component="div"
    >
      <span dangerouslySetInnerHTML={formatTextToHtml(text)} />
      {meta}
    </Typography>
  );
}

const colorToColor = {
  creator: "primary.contrastText",
  read: "textPrimary",
  default: "primary",
};

function formatTextToHtml(text: string) {
  return {
    __html: text?.replace(/\n/gim, "<br />"),
  };
}
