import ProgramsPage from "./page";
import CreateProgramPage from "./create/page";
import ProgramPage from "./[programId]/page";
import EditProgramPage from "./[programId]/edit/page";
import { deleteProgram } from "./[programId]/edit/actions";

import CreateProgramWorkoutPage from "./[programId]/workouts/create/page";
import ProgramWorkoutPage from "./[programId]/workouts/[workoutId]/page";
import EditProgramWorkoutPage from "./[programId]/workouts/[workoutId]/edit/page";
import { deleteProgramWorkout } from "./[programId]/workouts/[workoutId]/edit/actions";

import CreateProgramWorkoutExercisePage from "./[programId]/workouts/[workoutId]/exercises/create/page";
import AddProgramWorkoutExercisePage from "./[programId]/workouts/[workoutId]/exercises/add/page";
import EditProgramWorkoutExercisePage from "./[programId]/workouts/[workoutId]/exercises/[exerciseId]/edit/page";
import { deleteProgramWorkoutExercise } from "./[programId]/workouts/[workoutId]/exercises/[exerciseId]/edit/actions";

import HeaderAddButton from "components/HeaderAddButton";
import HeaderBackButton from "components/HeaderBackButton";
import HeaderEditButton from "components/HeaderEditButton";
import HeaderDeleteButton from "components/HeaderDeleteButton";

const routes = [
  {
    index: true,
    element: <ProgramsPage />,
    handle: {
      header: {
        title: "coach.screens.programs.title",
        action: <HeaderAddButton title="Create program" to="create" />,
        search: true,
      },
    },
  },
  {
    path: "create",
    element: <CreateProgramPage />,
    handle: {
      header: {
        title: "coach.screens.create-program.title",
        back: <HeaderBackButton to="." />,
      },
    },
  },
  {
    path: ":programId",
    children: [
      {
        index: true,
        element: <ProgramPage />,
        handle: {
          header: {
            title: "coach.screens.program.title",
            back: <HeaderBackButton to="." />,
            action: [
              <HeaderEditButton title="Edit program" key="edit" />,
              <HeaderAddButton
                key="add"
                title="Create workout"
                to="workouts/create"
              />,
            ],
          },
        },
      },
      {
        path: "edit",
        element: <EditProgramPage />,
        handle: {
          header: {
            title: "coach.screens.edit-program.title",
            back: <HeaderBackButton to="." />,
            action: (
              <HeaderDeleteButton
                title="Delete program"
                success="Program deleted"
                action={deleteProgram}
                redirectTo="coach/programs"
              />
            ),
          },
        },
      },
      {
        path: "workouts",
        children: [
          {
            path: "create",
            element: <CreateProgramWorkoutPage />,
            handle: {
              header: {
                title: "coach.screens.create-program-workout.title",
                back: <HeaderBackButton to=".." />,
              },
            },
          },
          {
            path: ":workoutId",
            children: [
              {
                index: true,
                element: <ProgramWorkoutPage />,
                handle: {
                  header: {
                    title: "coach.screens.program-workout.title",
                    back: <HeaderBackButton to=".." />,
                    action: [
                      <HeaderEditButton title="Edit workout" key="edit" />,
                      <HeaderAddButton
                        key="add"
                        title="Add exercise"
                        to="exercises/add"
                      />,
                    ],
                  },
                },
              },
              {
                path: "edit",
                element: <EditProgramWorkoutPage />,
                handle: {
                  header: {
                    title: "coach.screens.edit-program-workout.title",
                    back: <HeaderBackButton to="." />,
                    action: (
                      <HeaderDeleteButton
                        title="Delete workout"
                        success="Workout deleted"
                        action={deleteProgramWorkout}
                        redirectTo={-2}
                      />
                    ),
                  },
                },
              },
              {
                path: "exercises",
                children: [
                  {
                    path: "create",
                    element: <CreateProgramWorkoutExercisePage />,
                    handle: {
                      header: {
                        title:
                          "coach.screens.create-program-workout-exercise.title",
                        back: <HeaderBackButton to=".." />,
                      },
                    },
                  },
                  {
                    path: "add",
                    element: <AddProgramWorkoutExercisePage />,
                    handle: {
                      header: {
                        search: true,
                        title:
                          "coach.screens.add-program-workout-exercise.title",
                        back: <HeaderBackButton to=".." />,
                      },
                    },
                  },
                  {
                    path: ":exerciseId",
                    children: [
                      {
                        path: "edit",
                        element: <EditProgramWorkoutExercisePage />,
                        handle: {
                          header: {
                            title:
                              "coach.screens.edit-program-workout-exercise.title",
                            back: <HeaderBackButton to="../.." />,
                            action: (
                              <HeaderDeleteButton
                                title="Delete exercise"
                                success="Exercise deleted"
                                action={deleteProgramWorkoutExercise}
                                redirectTo="../.."
                              />
                            ),
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
