import Box from "@mui/material/Box";

export default function MessageHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box test-id="MessageHeader" sx={{ display: "flex" }}>
      {children}
    </Box>
  );
}
