import { useClientWorkout, useClientWorkoutExercises } from "./queries";
import {
  type Section,
  SectionList,
  SectionListItem,
  SectionListItemThumbnail,
} from "components/SectionList";
import formatTextToHtml from "utils/formatTextToHtml";
import BottomButton from "components/BottomButton";
import { updateWorkout } from "./actions";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTranslation } from "react-i18next";
import { getThumbnailUrl } from "utils/mux";
import DoneIconButton from "components/DoneIconButton";
import { useParams } from "react-router";
import ErrorScreen from "components/ErrorScreen";
import { WorkoutData, Workout } from "features/workouts";
import { Exercise } from "features/exercises";

export default function WorkoutPage() {
  const { t } = useTranslation("client");
  const { workoutId } = useParams() as { workoutId: string };
  const {
    error,
    loading,
    data: workout,
  } = useClientWorkout({
    workoutId,
  });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!workout) return null;

  const hasExercises = workout.exerciseIds.length > 0;
  const sections: Section<Workout>[] = [
    {
      items: [workout],
      renderItem: (workout) => (
        <SectionListItem
          primary={workout.title}
          slotProps={{
            primary: {
              variant: "h6",
              gutterBottom: !!workout.description,
            },
          }}
          secondary={
            workout.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: formatTextToHtml(workout.description),
                }}
              />
            )
          }
          action={
            hasExercises &&
            workout.completed && (
              <DoneIconButton
                done={workout.completed}
                onClick={() => {
                  updateWorkout({
                    workoutId,
                    completed: !workout.completed,
                  });
                }}
                title={
                  workout.completed
                    ? t("screens.workout.markAsUncompleted")
                    : t("screens.workout.markAsCompleted")
                }
              />
            )
          }
        />
      ),
    },
  ];

  return (
    <>
      <SectionList sections={sections} spacing={2} />
      {hasExercises && (
        <WorkoutExerciseList workoutId={workoutId} workout={workout} />
      )}
    </>
  );
}

function WorkoutExerciseList({
  workoutId,
  workout,
}: {
  workoutId: string;
  workout: WorkoutData;
}) {
  const { t } = useTranslation("client");
  const {
    error,
    loading,
    data: exercises,
  } = useClientWorkoutExercises({ workoutId });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;

  const sections: Section<Exercise>[] = [
    {
      header: t("screens.workout.exercises", { count: exercises.length }),
      items: exercises,
      renderItem: (exercise) => {
        const thumbnailUrl = getThumbnailUrl(exercise?.video?.playbackId, {
          width: "80",
          height: "80",
          fit_mode: "smartcrop",
        });

        return (
          <SectionListItem
            to={`exercises/${exercise.id}`}
            primary={exercise.title}
            avatar={<SectionListItemThumbnail src={thumbnailUrl} />}
            action={exercise.completed ? <TaskAltIcon color="success" /> : null}
          />
        );
      },
    },
  ];

  if (!workout.completed) {
    if (exercises.length) {
      const onClickCompleteWorkout = () => {
        updateWorkout({ workoutId, completed: true });
      };

      const disabled =
        exercises.filter((exercise) => exercise.completed).length !==
        exercises.length;

      sections.push({
        component: (
          <BottomButton
            size="large"
            variant="contained"
            color="primary"
            to="complete"
            onClick={onClickCompleteWorkout}
            disabled={disabled}
          >
            {t("screens.workout.complete-workout")}
          </BottomButton>
        ),
      });
    }
  }

  return <SectionList sections={sections} />;
}
