import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useRef, useState, useCallback } from "react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { storage } from "utils/firebase";
import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";

export default function ChatMessageVideo({ video }: { video: string }) {
  const videoRef = useRef(null);
  const [
    value,
    loading,
    // error
  ] = useDownloadURL(ref(storage, video));

  const [muted, setMuted] = useState(true);

  const onClickVideo = useCallback(
    (event: React.MouseEvent<HTMLVideoElement>) => {
      event.currentTarget.currentTime = 0;
      if (muted) {
        event.currentTarget.play();
      }
      setMuted(!muted);
    },
    [muted]
  );

  return (
    <Box
      sx={{
        position: "relative",
        width: muted ? 120 : 240,
        height: muted ? 120 : 240,
        borderRadius: "50%",
        overflow: "hidden",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <video
          ref={videoRef}
          style={{ width: "100%", height: "100%" }}
          muted={muted}
          autoPlay={!muted}
          //   loop={muted}
          src={value}
          onClick={onClickVideo}
          preload="auto"
          playsInline
        />
      )}
      {muted && (
        <VolumeOffIcon sx={{ position: "absolute", top: 10, left: 50 }} />
      )}
    </Box>
  );
}
