import { collection, query, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import { Client, ClientData } from "features/clients";

export function useClients(search?: string) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "users", auth.currentUser!.uid, "clients"),
      orderBy("createdAt", "desc")
    )
  );

  let data = [] as Client[];

  if (snapshot?.docs) {
    data = snapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...(doc.data() as ClientData),
      }))
      .filter((client: Client) => {
        if (!search) return true;

        return search
          .toLowerCase()
          .split(" ")
          .every((word) => {
            return (
              client?.displayName?.toLowerCase().includes(word.trim()) ||
              client?.email?.toLowerCase().includes(word.trim())
            );
          });
      });
  }

  return {
    loading,
    error,
    data,
  };
}
