import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ChatIcon from "@mui/icons-material/Chat";
// import SupportAgentIcon from "@mui/icons-material/SupportAgent";
// import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useTranslation } from "react-i18next";
import { ChatType } from "../../types";
import { useChat, useUnreadMessagesCount } from "../../queries";
import { SxProps, Theme } from "@mui/material/styles";

export default function ChatIconButton({
  onClick,
  type,
  workoutId,
  exerciseId,
  clientId,
  coachId,
  sx,
}: {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type: ChatType;
  workoutId?: string;
  exerciseId?: string;
  clientId?: string;
  coachId?: string;
  sx?: SxProps<Theme>;
}) {
  const { t } = useTranslation("components");
  const { chatId } = useChat({
    type,
    workoutId,
    exerciseId,
    clientId,
    coachId,
  });

  let icon = <ChatIcon />;

  if (chatId) {
    icon = <UnreadMessagesCount chatId={chatId}>{icon}</UnreadMessagesCount>;
  }

  return (
    <Tooltip title={t("header.chat")}>
      <IconButton edge="end" onClick={onClick} sx={sx} size="large">
        {icon}
      </IconButton>
    </Tooltip>
  );
}

function UnreadMessagesCount({
  chatId,
  children,
}: {
  chatId: string;
  children: React.ReactNode;
}) {
  const { count } = useUnreadMessagesCount({ chatId });

  return (
    <Badge badgeContent={count} color="error">
      {children}
    </Badge>
  );
}
