import api from "utils/api";

export async function updateClientWorkout({
  clientId,
  workoutId,
  title,
  description,
  date,
}: {
  clientId: string;
  workoutId: string;
  title: string;
  description?: string;
  date: string;
}) {
  await api("updateClientWorkout", {
    clientId,
    workoutId,
    title,
    description,
    date,
  });
}

export async function deleteClientWorkout({
  clientId,
  workoutId,
}: {
  clientId: string;
  workoutId: string;
}) {
  await api("deleteClientWorkout", { clientId, workoutId });
}
