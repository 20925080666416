import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { isIOS } from "utils/env";
import Grid from "@mui/material/Grid2";

// 592x1280
const IOS_VIDEO_URL =
  "https://firebasestorage.googleapis.com/v0/b/fitness-app-57686.appspot.com/o/statics%2Fvideos%2Finstall-pwa%2Fios.mov?alt=media&token=93b37199-ec31-47c2-99be-3f0626d20861";

// 590x1280
const ANDROID_VIDEO_URL =
  "https://firebasestorage.googleapis.com/v0/b/fitness-app-57686.appspot.com/o/statics%2Fvideos%2Finstall-pwa%2Fandroid.mov?alt=media&token=4534ec05-2cd7-4f0b-a91b-9492d84855dd";

export default function HowToInstallPWA() {
  const texts = {
    IOS: [
      <>
        {"1. Open Safari and go to the "}
        <Link href="https://onlinefitness.app" target="_blank">
          OnlineFitness.app
        </Link>
        {" website."}
      </>,
      "2. Tap the Share icon (an upward arrow in a box).",
      "3. In the menu that appears, select Add to Home Screen.",
      "4. Choose a convenient name (e.g., 'OnlineFitness') and tap Add.",
      "5. Done! The app icon will appear on your Home Screen.",
    ],
    ANDROID: [
      <>
        {"1. Open Chrome and go to the "}
        <Link href="https://onlinefitness.app" target="_blank">
          OnlineFitness.app
        </Link>
        {" website."}
      </>,
      "2. Tap the Menu icon (three dots in the upper-right corner).",
      "3. Select Add to Home Screen.",
      "4. Choose a convenient name and confirm the action.",
      "5. Done! The app icon will appear on your Home Screen.",
    ],
  };

  const platform = isIOS() ? "IOS" : "ANDROID";

  return (
    <Paper elevation={0} sx={{ p: { xs: 2, md: 4 } }} square>
      <Grid container spacing={4}>
        <Grid size={{ xs: 12, lg: 5 }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="video"
              src={isIOS() ? IOS_VIDEO_URL : ANDROID_VIDEO_URL}
              muted
              loop
              autoPlay
              playsInline
              sx={{
                width: "100%",
                maxWidth: { xs: 200, sm: "100%" },
                // height: "60vh",
                borderRadius: 3,
                overflow: "hidden",
                aspectRatio: "592/1280",
                background: "black",
              }}
            />
          </Box>
        </Grid>
        <Grid size={{ xs: 12, lg: 7 }}>
          <Typography variant="h6">
            How to Install Online Fitness App on Your Home Screen
          </Typography>

          <List>
            {texts[platform].map((text, index) => (
              <ListItem disableGutters key={index}>
                <ListItemText>{text}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Paper>
  );
}
