import { useState, useCallback } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FaqItem {
  question: string;
  answer: React.ReactNode;
}

export default function Faq({ items }: { items: FaqItem[] }) {
  const [expanded, setExpanded] = useState<number | false>(false);

  const onClickItem = useCallback(
    (index: number) => () => {
      setExpanded((prev) => (prev === index ? false : index));
    },
    []
  );

  return (
    <Stack spacing={1}>
      {items.map(({ question, answer }, index) => (
        <Card key={index} elevation={0}>
          <Button
            color="inherit"
            sx={{ borderRadius: 0, justifyContent: "flex-start", py: 1, px: 2 }}
            fullWidth
            onClick={onClickItem(index)}
            startIcon={
              <ExpandMoreIcon
                color="action"
                sx={{
                  transition: "transform 0.3s",
                  transform:
                    expanded === index ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              />
            }
          >
            <Typography variant="subtitle1" align="left">
              {question}
            </Typography>
          </Button>

          <Collapse in={expanded === index}>
            <CardContent>
              <Typography variant="body2">{answer}</Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </Stack>
  );
}
