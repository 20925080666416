import { NotificationType, Notification } from "../../types";
import { UserRole } from "features/user";

export default function getNotificationText(
  notification: Notification,
  t: (key: string) => string
) {
  const { creator, client, coach, workout, exercise, result, comment } =
    notification.data;

  switch (notification.type) {
    case NotificationType.WORKOUT_COMPLETED:
      return {
        to: `/coach/clients/${creator!.id}/workouts/${workout!.id}`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.workoutCompleted.completed")}{" "}
            <b>{workout!.title}</b>.
          </>
        ),
      };

    case NotificationType.EXERCISE_COMPLETED:
      return {
        to: `/coach/clients/${creator!.id}/workouts/${workout!.id}/exercises/${
          exercise!.id
        }`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.exerciseCompleted.completed")}{" "}
            <b>{exercise!.title}</b>.
          </>
        ),
        secondary: result?.text,
      };

    case NotificationType.EXERCISE_RESULT_CREATED:
      return {
        to: `/coach/clients/${creator!.id}/workouts/${workout!.id}/exercises/${
          exercise!.id
        }`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.exerciseResultCreated.created")}{" "}
            <b>{exercise!.title}</b>.
          </>
        ),
        secondary: result?.text,
      };

    case NotificationType.CLIENT_COMMENT_CREATED:
      return {
        to:
          creator!.role === UserRole.Client
            ? `/coach/clients/${client!.id}?chat=true&messageId=${comment!.id}`
            : `/client/coaches/${coach!.id}?chat=true&messageId=${comment!.id}`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.clientCommentCreated.commented")}{" "}
          </>
        ),
        secondary: comment!.text,
      };

    case NotificationType.WORKOUT_COMMENT_CREATED:
      return {
        to:
          creator!.role === UserRole.Client
            ? `/coach/clients/${creator!.id}/workouts/${
                workout!.id
              }?chat=true&messageId=${comment!.id}`
            : `/client/workouts/${workout!.id}?chat=true&messageId=${
                comment!.id
              }`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.workoutCommentCreated.commented")}{" "}
            <b>{workout!.title}</b>.
          </>
        ),
        secondary: comment!.text,
      };

    case NotificationType.EXERCISE_COMMENT_CREATED:
      return {
        to:
          creator!.role === UserRole.Client
            ? `/coach/clients/${creator!.id}/workouts/${
                workout!.id
              }/exercises/${exercise!.id}?chat=true&messageId=${comment!.id}`
            : `/client/workouts/${workout!.id}/exercises/${
                exercise!.id
              }?chat=true&messageId=${comment!.id}`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.exerciseCommentCreated.commented")}{" "}
            <b>{exercise!.title}</b>.
          </>
        ),
        secondary: comment!.text,
      };

    case NotificationType.CLIENT_INVITATION_ACCEPTED:
      return {
        to: `/coach/clients/${creator!.id}`,
        primary: (
          <>
            <b>{creator!.displayName}</b>{" "}
            {t("notifications.texts.clientInvitationAccepted.accepted")}.
          </>
        ),
      };
  }
}
