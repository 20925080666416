import api from "utils/api";

export async function createProgram(values: {
  title: string;
  description?: string;
}) {
  const res = await api("createProgram", values);

  return res.data as { id: string };
}
