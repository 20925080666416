import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router";

export default function Plan() {
  const progress = 70;
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid size={8}>
            <Typography variant="h6">10 Clients - Monthly</Typography>
          </Grid>
          <Grid size={4}>
            <Typography variant="h6" align="right">
              7 of 10
            </Typography>
          </Grid>
          <Grid size={12}>
            <LinearProgress variant="determinate" value={progress} />
          </Grid>
          <Grid size={12}>
            <Typography color="textSecondary" variant="subtitle1">
              NEXT BILLING DATE
            </Typography>
            <Typography color="textPrimary" variant="body1">
              Tuesday, August 13th 2024
            </Typography>
          </Grid>
          <Grid size={12}>
            <Typography color="textSecondary" variant="subtitle1">
              CARD ON FILE
            </Typography>
            <Typography color="textPrimary" variant="body1">
              Visa ••••1335
            </Typography>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="plan"
            >
              Upgrade Plan
            </Button>
          </Grid>
          <Grid>
            <Button variant="outlined" color="error">
              Cancel Plan
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
