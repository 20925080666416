import AppBar from "@mui/material/AppBar";
import { useMatches, useSearchParams, useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import SearchToolbar from "./SearchToolbar";
import BaseToolbar from "./BaseToolbar";
import Divider from "@mui/material/Divider";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "utils/ui";
import { ChatIconButton, ChatType } from "features/chat";
import MenuIconButton from "./MenuIconButton";
import pick from "utils/pick";

export default function HeaderMenu({
  toggleLeftSidebar,
  toggleRightSidebar,
}: {
  toggleLeftSidebar: (open: boolean) => () => void;
  toggleRightSidebar: (open: boolean) => () => void;
}) {
  const { t } = useTranslation("components");
  const routerParams = useParams();
  const matches = useMatches();
  const [searchParams] = useSearchParams();

  const trigger = useScrollTrigger({
    threshold: 64,
  });

  // const elevation = useScrollTrigger({
  //   disableHysteresis: true,
  //   threshold: 0,
  // });

  const match = matches[matches.length - 1] as {
    handle?: {
      chat?: {
        type: ChatType;
        params: string[];
      };
      header?: {
        title: string;
        action?: React.ReactNode;
        back?: boolean;
        search?: boolean;
      };
    };
  };

  if (!match?.handle?.header) {
    return null;
  }

  const { title, action, back } = match.handle.header;
  const { type, params } = match?.handle?.chat || {};

  const { workoutId, exerciseId, clientId, coachId } = pick(
    routerParams,
    params
  ) as {
    workoutId?: string;
    exerciseId?: string;
    clientId?: string;
    coachId?: string;
  };

  const searching = searchParams.has("search");

  const menuButton = <MenuIconButton onClick={toggleLeftSidebar(true)} />;
  const chatButton = type && (
    <ChatIconButton
      onClick={toggleRightSidebar(true)}
      type={type}
      workoutId={workoutId}
      exerciseId={exerciseId}
      clientId={clientId}
      coachId={coachId}
      sx={{ display: { xs: "flex", lg: "none" } }}
    />
  );

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position="sticky"
          color="default"
          elevation={0}
          sx={{
            paddingLeft: {
              xs: 0,
              md: `${LEFT_SIDEBAR_WIDTH}px`,
            },
            paddingRight: {
              xs: 0,
              lg: `${RIGHT_SIDEBAR_WIDTH}px`,
            },
            // paddingTop: "env(safe-area-inset-bottom, 0)",
            bgcolor: "background.paper",
          }}
        >
          <Helmet>
            <title>{t(title)}</title>
          </Helmet>

          {searching ? (
            <SearchToolbar />
          ) : (
            <BaseToolbar
              back={back}
              menuButton={menuButton}
              chatButton={chatButton}
              title={t(title)}
              action={action}
              search={match.handle.header.search}
            />
          )}

          <Divider sx={{ borderColor: "background.default" }} />
        </AppBar>
      </Slide>
    </>
  );
}
