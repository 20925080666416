import { useNotifications } from "../../queries";
import Badge from "@mui/material/Badge";
import { useMemo } from "react";

export default function NotificationsIconButton() {
  const { data } = useNotifications();

  const notificationsCount = data?.length || 0;

  const unreadNotificationsCount = useMemo(
    () => data.filter((n) => !n.isRead).length,
    [data]
  );

  if (notificationsCount === 0) return null;

  if (unreadNotificationsCount > 0) {
    return <Badge badgeContent={unreadNotificationsCount} color="info" />;
  }
}
