import { useState } from "react";
import {
  type Section,
  SectionList,
  SectionListItem,
  SectionListEmpty,
  SectionListItemThumbnail,
} from "components/SectionList";
import { useExercises } from "./queries";
import { addExerciseToProgramWorkout } from "./actions";
import { useSearchParams, useParams, useNavigate } from "react-router";
import { getThumbnailUrl } from "utils/mux";
import { useTranslation } from "react-i18next";
import ErrorScreen from "components/ErrorScreen";
import BottomButton from "components/BottomButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface Exercise {
  id: string;
  title: string;
  description?: string;
  video?: {
    playbackId: string;
  };
}
export default function AddProgramWorkoutExercisePage() {
  const { t } = useTranslation("coach");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") as string;
  const { loading, error, data: exercises } = useExercises(search);
  const { programId, workoutId } = useParams() as {
    programId: string;
    workoutId: string;
  };
  const [selectedExerciseIds, setSelectedExerciseIds] = useState<string[]>([]);
  const navigate = useNavigate();

  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  if (exercises.length === 0) {
    if (search) {
      return (
        <SectionListEmpty
          title={t("screens.add-program-workout-exercise.search.empty.title")}
          text={t("screens.add-program-workout-exercise.search.empty.text")}
          icon="/flaticon/search.png"
        />
      );
    }
    return (
      <SectionListEmpty
        title={t("screens.add-program-workout-exercise.empty.title")}
        text={t("screens.add-program-workout-exercise.empty.text")}
        buttonText={t("screens.add-program-workout-exercise.empty.button")}
        buttonTo="../create"
        icon="/flaticon/computer.png"
      />
    );
  }

  const sections: Section<Exercise>[] = [
    {
      header: t("screens.add-program-workout-exercise.header"),
      dividerVariant: "fullWidth" as const,
      items: exercises,
      square: true,
      renderItem: (exercise: Exercise) => {
        const thumbnailUrl = getThumbnailUrl(exercise?.video?.playbackId, {
          width: "80",
          height: "80",
          fit_mode: "smartcrop",
        });

        return (
          <SectionListItem
            primary={exercise.title}
            // secondary={exercise.type}
            selected={selectedExerciseIds.includes(exercise.id)}
            avatar={<SectionListItemThumbnail src={thumbnailUrl} />}
            action={
              selectedExerciseIds.includes(exercise.id) ? (
                <CheckCircleOutlineIcon />
              ) : null
            }
            onClick={() => {
              setSelectedExerciseIds((prev) =>
                prev.includes(exercise.id)
                  ? prev.filter((id) => id !== exercise.id)
                  : [...prev, exercise.id]
              );
            }}
          />
        );
      },
    },
  ];

  if (selectedExerciseIds.length > 0) {
    sections.push({
      square: true,
      component: (
        <BottomButton
          size="large"
          variant="contained"
          color="primary"
          onClick={() => {
            const promises = selectedExerciseIds.map((exerciseId) => {
              return addExerciseToProgramWorkout({
                programId: programId!,
                workoutId: workoutId!,
                exerciseId,
              });
            });
            Promise.all(promises).then(() => navigate(`../..`));
          }}
          disabled={selectedExerciseIds.length === 0}
        >
          {t("screens.add-program-workout-exercise.add-button", {
            count: selectedExerciseIds.length,
          })}
        </BottomButton>
      ),
    });
  }

  return <SectionList sections={sections} />;
}
