import Paper from "@mui/material/Paper";
import Container from "components/Container";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { Helmet } from "react-helmet-async";

export default function RefundPolicyPage() {
  return (
    <Paper elevation={0}>
      <Container maxWidth="sm">
        <Typography variant="body1" component="div">
          <Helmet>
            <title>Refund Policy</title>
          </Helmet>

          <h2>Refund Policy</h2>

          <p>
            Effective Date: <b>1 January 2025</b>
          </p>

          <p>
            Thank you for choosing OnlineFitness.app (the “Service”, “we”, “our”
            or “us”). We are committed to providing a platform that meets your
            needs as a fitness trainer. This Refund Policy outlines the terms
            under which refunds may be issued for subscription payments.
          </p>

          <h3>1. Subscription Payments</h3>

          <p>
            OnlineFitness.app operates on a subscription-based model, with fees
            billed monthly or annually depending on your selected plan. Payments
            are processed securely and are non-refundable except as outlined
            below.
          </p>

          <h3>2. Refund Eligibility</h3>

          <p>Refunds may be issued under the following circumstances:</p>

          <h4>Technical Issues:</h4>

          <ul>
            <li>
              If a significant technical issue prevents you from using the
              Service and we are unable to resolve the issue within a reasonable
              time, you may request a refund for the affected billing period.
            </li>
          </ul>

          <h4>Accidental Charges:</h4>

          <ul>
            <li>
              If you were accidentally charged due to a system error or
              duplicate payment, please contact us within 14 days to request a
              refund.
            </li>
          </ul>

          <h4>Unauthorized Transactions:</h4>

          <ul>
            <li>
              If you believe a transaction was unauthorized, notify us
              immediately. Refunds for unauthorized transactions may require
              verification of your claim.
            </li>
          </ul>

          <h3>3. Non-Refundable Situations</h3>

          <p>Refunds will not be issued in the following cases:</p>

          <h4>Change of Mind:</h4>

          <ul>
            <li>
              Refunds are not provided if you decide to stop using the Service
              before the end of your subscription period.
            </li>
          </ul>

          <h4>Partial Use:</h4>

          <ul>
            <li>
              Refunds are not issued for unused portions of a subscription.
            </li>
          </ul>

          <h4>Violation of Terms:</h4>

          <ul>
            <li>
              Refunds are not available if your account is terminated for
              violating our Terms of Service.
            </li>
          </ul>

          <h3>4. How to Request a Refund</h3>

          <h4>To request a refund, please follow these steps:</h4>

          <p>
            Contact Support: Email us at{" "}
            <MuiLink href="mailto:support@onlinefitness.app">
              support@onlinefitness.app
            </MuiLink>{" "}
            with the subject line “Refund Request” and provide the following
            details:
          </p>
          <ul>
            <li>Your account email address.</li>
            <li>Date and amount of the transaction.</li>
            <li>Reason for the refund request.</li>
          </ul>

          <h4>Verification:</h4>

          <p>
            We may require additional information to verify your claim, such as
            proof of payment or screenshots of the issue.
          </p>

          <h4>Response Time:</h4>

          <p>
            Refund requests will be reviewed and processed within 7-10 business
            days.
          </p>

          <h3>5. Refund Method</h3>

          <p>
            Approved refunds will be issued to the original payment method.
            Please note that it may take additional time for your bank or
            payment provider to process the refund.
          </p>

          <h3>6. Changes to the Refund Policy</h3>

          <p>
            We may update this Refund Policy from time to time. Changes will be
            posted on our platform, and your continued use of the Service
            indicates acceptance of the updated policy.
          </p>

          <h3>7. Contact Us</h3>

          <p>
            If you have questions about this Refund Policy or need assistance,
            please contact us at:
          </p>

          <p>
            Email:{" "}
            <MuiLink href="mailto:support@onlinefitness.app">
              support@onlinefitness.app
            </MuiLink>
          </p>

          <p>
            Thank you for using OnlineFitness.app. We value your satisfaction
            and are here to assist you.
          </p>
        </Typography>
      </Container>
    </Paper>
  );
}
