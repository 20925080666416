import Box from "@mui/material/Box";

export default function MessageRoot({
  selected,
  ...props
}: React.HTMLProps<HTMLDivElement> & { selected?: boolean }) {
  return (
    <Box
      test-id="MessageRoot"
      sx={{
        display: "flex",
        alignItems: "flex-start",
        px: 1,
        py: 0.5,
        position: "relative",
        ...(selected && { bgcolor: "info.main" }),
      }}
      {...props}
    />
  );
}
