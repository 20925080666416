import { useTheme } from "@mui/material/styles";
import MuxPlayer from "@mux/mux-player-react";
import { auth } from "utils/firebase";
import Skeleton from "@mui/material/Skeleton";
import { useCallback, useMemo } from "react";

// https://github.com/muxinc/elements/blob/main/packages/mux-player/REFERENCE.md
// https://github.com/muxinc/elements/blob/main/packages/mux-player-react/REFERENCE.md

export default function Player({
  video,
  metadataVideoId,
  metadataVideoTitle,
  loop,
}: {
  video?: {
    playbackId: string;
    aspectRatio: string;
  };
  metadataVideoId: string;
  metadataVideoTitle: string;
  loop?: boolean;
}) {
  const theme = useTheme();

  const key = metadataVideoId + "-" + "videoTime";

  const onTimeUpdate = useCallback(
    (event: Event) => {
      try {
        const target = event.target as HTMLVideoElement;
        localStorage.setItem(key, String(target.currentTime));
      } catch (error) {
        console.error(error);
      }
    },
    [key]
  );

  const startTime = useMemo(() => localStorage?.getItem(key), [key]);

  const aspectRatio = video?.aspectRatio?.replace(":", "/") || "16/9";

  if (!video?.playbackId) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{
          aspectRatio,
          width: "100%",
          height: "auto",
        }}
      />
    );
  }

  return (
    <MuxPlayer
      style={{
        aspectRatio,
        display: "block",
        overflow: "hidden",
        maxHeight: "60vh",
      }}
      streamType="on-demand"
      playbackId={video.playbackId}
      accentColor={theme.palette.primary.main}
      onTimeUpdate={onTimeUpdate}
      preload="auto"
      metadataVideoId={metadataVideoId} // это нужно чтобы в mux data отслеживать по каждому юзеру
      metadataVideoTitle={metadataVideoTitle}
      metadataViewerUserId={auth.currentUser?.uid}
      defaultHiddenCaptions={true}
      startTime={startTime ? parseFloat(startTime) : 0}
      loop={loop}
    />
  );
}
