import dayjs from "utils/dayjs";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function MessageTime({
  updatedAt,
  createdAt,
  color,
}: {
  updatedAt: Date;
  createdAt: Date;
  color: string;
}) {
  const { t } = useTranslation("components");
  const isEdited = !dayjs(createdAt).isSame(updatedAt);

  return (
    <Typography variant="caption" sx={{ my: "6px", color }}>
      {isEdited ? t("chat.message.edited") : null}{" "}
      {dayjs(updatedAt).format("hh:mm")}
    </Typography>
  );
}
