import { useRef, useMemo } from "react";
import ChatMessageListItem from "./ChatMessageListItem";
import { GroupedVirtuoso } from "react-virtuoso";
import type { ChatMessage } from "features/chat";
import type { User } from "features/user";
import Box from "@mui/material/Box";
import { auth } from "utils/firebase";
import { useColorScheme } from "@mui/material/styles";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import formatDate from "utils/formatDate";

export default function ChatMessageList({
  chatMessages,
  selectedChatMessage,
  setSelectedChatMessage,
  editedChatMessage,
  atBottomStateChange,
  atTopStateChange,
  startReached,
  endReached,
  usersById,
  scrollToMessageId,
}: {
  chatMessages: ChatMessage[];
  selectedChatMessage?: ChatMessage;
  setSelectedChatMessage: (
    selectedChatMessage: ChatMessage | undefined
  ) => void;
  editedChatMessage?: ChatMessage;
  atBottomStateChange?: (atBottom: boolean) => void;
  atTopStateChange?: (atTop: boolean) => void;
  startReached?: (index: number) => void;
  endReached?: (index: number) => void;
  usersById: Record<string, User>;
  scrollToMessageId?: string;
}) {
  const virtuoso = useRef(null);
  const { mode } = useColorScheme();

  const { groups, groupCounts } = useMemo(() => {
    const groups: string[] = [];
    const groupCounts: number[] = [];

    chatMessages.forEach((chatMessage, index) => {
      const messageCreatedAt = dayjs(chatMessage.createdAt.toDate());

      const prevMessage = chatMessages[index - 1];

      if (!prevMessage) {
        groups.push(formatDate(messageCreatedAt));
        groupCounts.push(1);
      } else {
        const prevMessageCreatedAt = dayjs(prevMessage.createdAt.toDate());

        if (!messageCreatedAt.isSame(prevMessageCreatedAt, "day")) {
          groups.push(formatDate(messageCreatedAt));
          groupCounts.push(1);
        } else {
          groupCounts[groupCounts.length - 1]++;
        }
      }
    });

    return { groups, groupCounts };
  }, [chatMessages]);

  if (chatMessages.length === 0) {
    return <Box sx={{ height: "100%" }} />;
  }

  let initialTopMostItemIndex = chatMessages.length - 1; // TODO вообще тут надо скролить к первому не прочитанному

  if (scrollToMessageId) {
    const scrollToMessageIndex = chatMessages.findIndex(
      (chatMessage) => chatMessage.id === scrollToMessageId
    );

    if (scrollToMessageIndex !== -1) {
      initialTopMostItemIndex = scrollToMessageIndex;
    }
  }

  return (
    <GroupedVirtuoso
      style={{
        height: "100%",
        backgroundImage: "url('/images/chat/backgrounds/chat-bg-pattern.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: mode === "light" ? "aliceblue" : undefined,
      }}
      ref={virtuoso}
      // components={MUIComponents}
      groupCounts={groupCounts}
      // data={chatMessages}
      initialTopMostItemIndex={initialTopMostItemIndex} // скролл вниз к последнему сообщению
      startReached={startReached}
      endReached={endReached}
      alignToBottom // прижать сообщения к низу (если их меньше чем высота чата)
      followOutput="smooth" // при изменении кол-ва сообщений скроллить вниз к последнему
      atBottomStateChange={atBottomStateChange}
      atTopStateChange={atTopStateChange}
      atTopThreshold={100}
      // computeItemKey={(_: number, chatMessage: ChatMessage) => chatMessage.id}
      groupContent={(index: number) => {
        return (
          <Box sx={{ display: "flex", py: 1 }}>
            <Chip size="small" label={groups[index]} sx={{ margin: "auto" }} />
          </Box>
        );
      }}
      itemContent={(index: number) => {
        const chatMessage = chatMessages[index];
        const prevMessage = chatMessages[index - 1];
        const prevMessageCreatorId = prevMessage?.creatorId;
        const chatMessageCreatorId = chatMessage.creatorId;
        const chatMessageCreator = usersById[chatMessage.creatorId];
        const chatMessageReplyToCreator = chatMessage.replyTo
          ? usersById[chatMessage.replyTo.creatorId]
          : null;

        const isDifferentCreator =
          prevMessageCreatorId !== chatMessageCreatorId;
        const selected =
          selectedChatMessage?.id === chatMessage.id ||
          editedChatMessage?.id === chatMessage.id;
        const viewerId = auth.currentUser!.uid;
        const isCreator = chatMessage.creatorId === viewerId;
        const isRead = chatMessage?.readerIds?.includes(viewerId);
        const isSystem = chatMessage.creatorId === "system";

        return (
          <ChatMessageListItem
            key={chatMessage.id}
            chatMessage={chatMessage}
            chatMessageCreator={chatMessageCreator}
            chatMessageReplyToCreator={chatMessageReplyToCreator}
            isDifferentCreator={isDifferentCreator}
            isCreator={isCreator}
            isSystem={isSystem}
            isRead={isRead}
            setSelectedChatMessage={setSelectedChatMessage}
            selected={selected}
          />
        );
      }}
    />
  );
}
