import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { Fragment } from "react";
import { Section } from "./types";

export default function SectionListItems<Item extends { id?: string }>({
  section,
  renderItem,
}: {
  section: Section<Item>;
  renderItem: (item: Item, index: number) => React.ReactNode;
}) {
  return (
    <Paper elevation={0} square={section.square}>
      <List disablePadding>
        {section.items!.map((item: Item, index: number) => (
          <Fragment key={item.id || index}>
            {index > 0 && section.dividerVariant && (
              <Divider
                variant={section.dividerVariant}
                sx={{ borderColor: "background.default" }}
              />
            )}

            {section.renderItem
              ? section.renderItem(item, index)
              : renderItem(item, index)}
          </Fragment>
        ))}
      </List>
    </Paper>
  );
}
