import { collection, query, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import { Coach, CoachData } from "features/coaches";

export function useCoaches(search?: string) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "users", auth.currentUser!.uid, "coaches"),
      orderBy("createdAt", "desc")
    )
  );

  let data = [] as Coach[];

  if (snapshot?.docs) {
    data = snapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...(doc.data() as CoachData),
      }))
      .filter((coach: Coach) => {
        if (!search) return true;

        return search
          .toLowerCase()
          .split(" ")
          .every((word) => {
            return (
              coach?.displayName?.toLowerCase().includes(word.trim()) ||
              coach?.email?.toLowerCase().includes(word.trim())
            );
          });
      });
  }

  return {
    loading,
    error,
    data,
  };
}
