import FormList, { type Schema } from "components/FormList";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "utils/firebase";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function ForgotPasswordScreen() {
  const { t } = useTranslation("auth");

  const onSubmit = async (values: { email: string }) => {
    await sendPasswordResetEmail(auth, values.email);

    enqueueSnackbar(
      t("screens.forgot-password.success", { email: values.email }),
      {
        variant: "success",
      }
    );
  };

  const schema: Schema = [
    {
      header: t("screens.forgot-password.header"),
      dividerVariant: "fullWidth",
      fields: [
        {
          type: "email",
          placeholder: t("screens.forgot-password.email"),
          name: "email",
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.forgot-password.submit"),
          color: "primary",
          variant: "contained",
          size: "large",
        },
      ],
    },
    {
      fields: [
        {
          type: "link",
          primary: t("screens.forgot-password.signIn"),
          to: "/signin",
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("screens.forgot-password.title")}</title>
      </Helmet>
      <FormList schema={schema} onSubmit={onSubmit} />
    </>
  );
}
