import Avatar from "@mui/material/Avatar";

export default function MessageAvatar({
  src,
  children,
}: {
  src?: string;
  children: React.ReactNode;
}) {
  return (
    <Avatar
      src={src}
      alt="User Avatar"
      sx={{
        position: "absolute",
        bottom: 0,
        top: 0,
      }}
      test-id="MessageAvatar"
    >
      {children}
    </Avatar>
  );
}
