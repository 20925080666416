import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CardHeader from "@mui/material/CardHeader";
import { ChatType } from "../../../types";
import { useUsersById } from "features/user";

export default function ChatHeader({
  onClose,
  type,
  memberIds,
}: {
  onClose: () => void;
  type: ChatType;
  memberIds: string[];
}) {
  const { usersById } = useUsersById({
    userIds: memberIds,
  });

  return (
    <>
      <CardHeader
        avatar={
          <AvatarGroup spacing="small">
            {memberIds.map((userId) => {
              if (userId === "system") {
                return (
                  <Avatar
                    key={userId}
                    sx={{
                      bgcolor: "primary.main",
                      color: "primary.contrastText",
                    }}
                  >
                    <SupportAgentIcon color="inherit" />
                  </Avatar>
                );
              }

              const user = usersById[userId];

              if (!user) {
                return null;
              }

              return (
                <Avatar key={userId} alt={user.displayName} src={user.photoURL}>
                  {user.displayName[0]}
                </Avatar>
              );
            })}
          </AvatarGroup>
        }
        title={memberIds
          .map((userId) => {
            const user = usersById[userId];

            if (!user) {
              return null;
            }

            return user.displayName;
          })
          .join(" + ")}
        subheader={type}
        action={
          <IconButton
            onClick={onClose}
            sx={{ display: { xs: "flex", lg: "none" } }}
          >
            <CloseIcon />
          </IconButton>
        }
      />
      <Divider sx={{ borderColor: "background.default" }} />
    </>
  );
}
