import dayjs from "utils/dayjs";
import SelectField from "./SelectField";
import { useMemo } from "react";

export default function SelectMonth({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  const options = useMemo(
    () =>
      Array.from({ length: 12 }, (_, month) => {
        return {
          value: month,
          label: dayjs().month(month).format("MMMM"),
        };
      }),
    []
  );

  return <SelectField onChange={onChange} options={options} value={value} />;
}
