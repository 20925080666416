import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  UploadTask,
} from "firebase/storage";
import { storage, auth } from "utils/firebase";

type Uploads = Record<
  string,
  {
    error: string | null | unknown;
    progress: number;
    success: boolean;
    upload: UploadTask;
    downloadURL?: string;
  }
>;

type Listener = (uploads: Uploads) => void;

let uploads: Uploads = {};
let listeners: Listener[] = [];

export const uploadsStore = {
  addUpload(id: string, file: File) {
    // Create the file metadata
    const metadata = {
      contentType: file.type,
      size: file.size,
      name: file.name,
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(
      storage,
      `users/${auth.currentUser!.uid}/files/${id}/${file.name}`
    );

    const upload = uploadBytesResumable(storageRef, file, metadata);

    uploads[id] = { error: null, progress: 0, success: false, upload };
    uploads = { ...uploads };

    // Listen for state changes, errors, and completion of the upload.
    upload.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");

        // switch (snapshot.state) {
        //   case "paused":
        //     console.log("Upload is paused");
        //     break;
        //   case "running":
        //     console.log("Upload is running");
        //     break;
        // }

        console.log("state_changed", snapshot);

        uploads[id] = {
          ...uploads[id],
          progress,
        };
        uploads = { ...uploads };

        emitChange();
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // switch (error.code) {
        //   case "storage/unauthorized":
        //     // User doesn't have permission to access the object
        //     break;
        //   case "storage/canceled":
        //     // User canceled the upload
        //     break;

        //   // ...

        //   case "storage/unknown":
        //     // Unknown error occurred, inspect error.serverResponse
        //     break;
        // }

        console.log("error", error);
        uploads[id] = {
          ...uploads[id],
          error,
        };
        uploads = { ...uploads };

        emitChange();
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(upload.snapshot.ref).then((downloadURL) => {
          // console.log("File available at", downloadURL);
          //   console.log("success");
          uploads[id] = {
            ...uploads[id],
            downloadURL,
            success: true,
          };
          uploads = { ...uploads };

          emitChange();
        });
      }
    );

    emitChange();
  },
  subscribe(listener: Listener) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
  getSnapshot() {
    return uploads;
  },
};

function emitChange() {
  for (const listener of listeners) {
    listener(uploads);
  }
}
