import api from "utils/api";

export async function createClientWorkout(values: {
  title: string;
  description?: string;
  date: string;
  clientId: string;
}) {
  const res = await api("createClientWorkout", values);

  return res.data as { id: string };
}
