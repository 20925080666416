import api from "utils/api";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "utils/firebase";

export function resendInvitation(invitationId: string) {
  return api("resendInvitation", { invitationId });
}

export async function acceptInvitation(values: {
  invitationId: string;
  password?: string;
}): Promise<void> {
  const res = await api("acceptInvitation", values);

  const { authToken } = res.data as { authToken?: string };

  if (authToken) {
    await signInWithCustomToken(auth, authToken);
  }
}
