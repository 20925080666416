import Paper from "@mui/material/Paper";
import Container from "components/Container";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";

import TelegramIcon from "components/Icons/TelegramIcon";
import InstagramIcon from "components/Icons/InstagramIcon";
import EmailIcon from "@mui/icons-material/Email";

const AVATAR_SIZE = 80;

enum ContactType {
  email = "email",
  telegram = "telegram",
  instagram = "instagram",
}

const iconByContactType = {
  [ContactType.email]: EmailIcon,
  [ContactType.telegram]: TelegramIcon,
  [ContactType.instagram]: InstagramIcon,
};

export default function FoundersPage() {
  const founders = [
    {
      name: "Antony",
      description:
        "A software developer with extensive experience in building apps and websites. He is also a trail running enthusiast, an entrepreneur, and someone who believes in the power of technology to transform industries.",
      avatar: "/images/pages/founders/antony.jpg",
      contacts: [
        {
          type: ContactType.telegram,
          href: "https://t.me/kulakowka",
        },
        {
          type: ContactType.instagram,
          href: "https://www.instagram.com/antonkulakovcom",
        },
        {
          type: ContactType.email,
          href: "mailto:ak@onlinefitness.app",
        },
      ],
    },
    {
      name: "Maria",
      description:
        "A personal fitness trainer with years of experience working with clients. She successfully transitioned her business online and have helped hundreds of happy clients reach their fitness goals. She knows firsthand what it takes for a personal trainer to build a thriving online business.",
      avatar: "/images/pages/founders/maria.jpg",
      contacts: [
        {
          type: ContactType.telegram,
          href: "https://t.me/maria_delvig",
        },
        {
          type: ContactType.instagram,
          href: "https://www.instagram.com/mashaprofit",
        },
        {
          type: ContactType.email,
          href: "mailto:maria@onlinefitness.app",
        },
      ],
    },
  ];

  return (
    <Paper elevation={0}>
      <Container maxWidth="sm">
        <Typography variant="body1" component="div">
          <Helmet>
            <title>Founders of Online Fitness App</title>
          </Helmet>

          <h1>Founders of Online&nbsp;Fitness&nbsp;App</h1>

          <p>
            Hi there! We’re Antony and Maria, the creators of OnlineFitness.app
            – a platform designed to help personal trainers take their business
            online and grow their income by training clients from anywhere in
            the world.
          </p>

          <p>
            Together, we created OnlineFitness.app to make it easier for
            trainers to go online, reach more clients, and focus on what they do
            best – helping others get fit and healthy!
          </p>

          <p>
            We love connecting with new people, so if you have any questions or
            suggestions, feel free to reach out to us on social media
            (Instagram, Telegram) or via email:
          </p>

          <List>
            {founders.map((founder) => (
              <ListItem
                disablePadding
                key={founder.name}
                alignItems="flex-start"
              >
                <ListItemAvatar sx={{ pr: 2 }}>
                  <Avatar
                    src={founder.avatar}
                    alt={founder.name}
                    sx={{
                      width: { xs: AVATAR_SIZE / 1.2, sm: AVATAR_SIZE },
                      height: { xs: AVATAR_SIZE / 1.2, sm: AVATAR_SIZE },
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h6">{founder.name}</Typography>}
                  secondary={
                    <>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {founder.description}
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        {founder.contacts.map((contact) => {
                          const Icon = iconByContactType[contact.type];
                          return (
                            <IconButton
                              size="small"
                              key={contact.type}
                              href={contact.href}
                              target="_blank"
                            >
                              <SvgIcon>
                                <Icon />
                              </SvgIcon>
                            </IconButton>
                          );
                        })}
                      </Stack>
                    </>
                  }
                  disableTypography
                />
              </ListItem>
            ))}
          </List>

          <p>Looking forward to hearing from you! 🚀💙</p>
        </Typography>
      </Container>
    </Paper>
  );
}
