import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function DoneIconButton({
  done,
  onClick,
  title,
}: {
  done: boolean;
  onClick: () => void;
  title: string;
}) {
  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        {done ? (
          <TaskAltIcon color="success" />
        ) : (
          <RadioButtonUncheckedIcon color="action" />
        )}
      </IconButton>
    </Tooltip>
  );
}
