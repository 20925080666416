import api from "utils/api";

export async function updateProgramWorkout(values: {
  programId: string;
  workoutId: string;
  title: string;
  description?: string;
  day: number;
}) {
  await api("updateProgramWorkout", values);
}

export async function deleteProgramWorkout({
  programId,
  workoutId,
}: {
  programId: string;
  workoutId: string;
}) {
  await api("deleteProgramWorkout", { programId, workoutId });
}
