import HowToInstallPWA from "components/HowToInstallPWA";
import {
  SystemChatMessageType,
  useCreateSystemChatMessage,
} from "features/chat";

export default function InstallPWAPage() {
  useCreateSystemChatMessage(
    SystemChatMessageType.WELCOME_MESSAGE_ON_INSTALL_PAGE
  );

  return <HowToInstallPWA />;
}
