import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { updateUser } from "./actions";

export default function PasswordScreen() {
  const { t } = useTranslation("core");

  const onSubmit = async (values: {
    newPassword: string;
    confirmNewPassword: string;
  }) => {
    const { newPassword, confirmNewPassword } = values;

    if (newPassword !== confirmNewPassword) {
      enqueueSnackbar(t("screens.settings-password.passwords-do-not-match"), {
        variant: "error",
      });
      return;
    }

    await updateUser({ password: newPassword });

    enqueueSnackbar(t("screens.settings-password.success"), {
      variant: "success",
    });
  };

  const schema: Schema = [
    {
      square: true,
      footer: t("screens.settings-password.footer"),
      dividerVariant: "middle",
      fields: [
        {
          type: "password",
          placeholder: t("screens.settings-password.new-password"),
          name: "newPassword",
          required: true,
        },
        {
          type: "password",
          placeholder: t("screens.settings-password.confirm-new-password"),
          name: "confirmNewPassword",
          required: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.settings-password.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
          size: "large",
        },
      ],
    },
  ];

  return <FormList schema={schema} onSubmit={onSubmit} />;
}
