import HeaderBackButton from "components/HeaderBackButton";

import RootPage from "./page";
import ProfilePage from "./profile/page";
import EmailPage from "./email/page";
import PasswordPage from "./password/page";
import ThemePage from "./theme/page";
import LanguagePage from "./language/page";

const routes = [
  {
    index: true,
    element: <RootPage />,
    handle: {
      header: {
        title: "core.screens.settings.title",
      },
    },
  },
  {
    path: "profile",
    element: <ProfilePage />,
    handle: {
      header: {
        title: "core.screens.profile.title",
        back: <HeaderBackButton />,
      },
    },
  },
  {
    path: "email",
    element: <EmailPage />,
    handle: {
      header: {
        title: "core.screens.email.title",
        back: <HeaderBackButton />,
      },
    },
  },
  {
    path: "password",
    element: <PasswordPage />,
    handle: {
      header: {
        title: "core.screens.password.title",
        back: <HeaderBackButton />,
      },
    },
  },
  {
    path: "theme",
    element: <ThemePage />,
    handle: {
      header: {
        title: "core.screens.theme.title",
        back: <HeaderBackButton />,
      },
    },
  },
  {
    path: "language",
    element: <LanguagePage />,
    handle: {
      header: {
        title: "core.screens.language.title",
        back: <HeaderBackButton />,
      },
    },
  },
];

export default routes;
