import {
  type Section,
  SectionList,
  // SectionListItem,
  SectionListEmpty,
} from "components/SectionList";
import { useTeam, useTeamMembers } from "./queries";
import { TeamMember } from "features/teams";

export default function TeamScreen() {
  const team = useTeam();
  const teamMembers = useTeamMembers();

  if (!team) return null;

  const sections: Section<TeamMember>[] = [
    {
      // items: [
      //   {
      //     primary: team.title,
      //     primaryTypographyProps: {
      //       variant: "h6",
      //       gutterBottom: !!team.description,
      //     },
      //     secondary: team.description,
      //   },
      // ],
      // renderItem: (item: any) => <SectionListItem {...item} />,
    },
  ];

  if (teamMembers.length === 0) {
    sections.push({
      component: (
        <SectionListEmpty
          title="No members"
          text="You don't have any members yet."
          buttonText="Invite members"
          buttonTo="members/create"
          icon="/flaticon/staff.png"
        />
      ),
    });
  } else if (teamMembers.length > 0) {
    sections.push({
      header: "Members",
      items: teamMembers,
      // renderItem: (member: Member) => (
      //   <SectionListItem
      //     to={`members/${member.id}`}
      //     primary={member.displayName}
      //     secondary={member.email}
      //     action={member.role}
      //     avatar={<Avatar src={member.photoURL} alt={member.displayName} />}
      //   />
      // ),
    });
  }

  return <SectionList sections={sections} spacing={2} />;
}
