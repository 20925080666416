import { Timestamp } from "firebase/firestore";

export enum InvitationType {
  Client = "client",
  Coach = "coach",
}

export enum InvitationStatus {
  Pending = "pending",
  Accepted = "accepted",
  Rejected = "rejected",
}

export interface InvitationData {
  type: InvitationType;
  status: InvitationStatus;
  coachId: string;
  clientId: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface Invitation extends InvitationData {
  id: string;
}
