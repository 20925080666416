import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function MenuIconButton({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation("components");

  return (
    <Tooltip title={t("header.menu")}>
      <IconButton
        edge="start"
        onClick={onClick}
        sx={{ display: { xs: "flex", md: "none" } }}
        size="large"
      >
        <MenuIcon />
      </IconButton>
    </Tooltip>
  );
}
