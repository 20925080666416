import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import {
  markNotificationAsRead,
  markNotificationAsUnread,
} from "../../actions";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

interface Notification {
  id: string;
  isRead: boolean;
}

export default function NotificationReadStatusToggleButton({
  notification,
}: {
  notification: Notification;
}) {
  const { t } = useTranslation("components");

  const title = notification.isRead
    ? t("notifications.markAsUnread")
    : t("notifications.markAsRead");

  const onClick = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (notification.isRead) {
        markNotificationAsUnread({ notificationId: notification.id });
      } else {
        markNotificationAsRead({ notificationId: notification.id });
      }
    },
    [notification]
  );

  return (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>
        {notification.isRead ? (
          <TaskAltIcon color="disabled" />
        ) : (
          <RadioButtonUncheckedIcon color="action" />
        )}
      </IconButton>
    </Tooltip>
  );
}
