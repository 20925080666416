import { Timestamp } from "firebase/firestore";

export enum ChatType {
  Client = "client",
  Workout = "workout",
  Exercise = "exercise",
}

export interface ChatData {
  type: ChatType;
  title: string;
  memberIds: string[];
}

export enum SystemChatMessageType {
  WELCOME_MESSAGE_ON_EXERCISES_PAGE = "WELCOME_MESSAGE_ON_EXERCISES_PAGE",
  WELCOME_MESSAGE_ON_SETTINGS_PROFILE_PAGE = "WELCOME_MESSAGE_ON_SETTINGS_PROFILE_PAGE",
  WELCOME_MESSAGE_ON_SETTINGS_EMAIL_PAGE = "WELCOME_MESSAGE_ON_SETTINGS_EMAIL_PAGE",
  WELCOME_MESSAGE_ON_INSTALL_PAGE = "WELCOME_MESSAGE_ON_INSTALL_PAGE",
}

export interface ChatMessageData {
  text?: string;
  video?: string;
  audio?: string;
  file?: string;
  replyTo?: ChatMessage;
  creatorId: string;
  readerIds: string[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface ChatMessage extends ChatMessageData {
  id: string;
}
