import Paper from "@mui/material/Paper";
import Container from "components/Container";
import Typography from "@mui/material/Typography";
import { Link } from "react-router";
import MuiLink from "@mui/material/Link";
import { Helmet } from "react-helmet-async";

export default function TermsOfServicePage() {
  return (
    <Paper elevation={0}>
      <Container maxWidth="sm">
        <Typography variant="body1" component="div">
          <Helmet>
            <title>Terms of Service</title>
          </Helmet>

          <h2>Terms of Service</h2>

          <p>
            Effective Date: <b>1 January 2025</b>
          </p>
          <p>
            Welcome to OnlineFitness.app (the “Service”, “we”, “our” or “us”), a
            platform designed to empower fitness trainers to transition their
            services online. These Terms of Service (“Terms”) govern your access
            to and use of the Service, including any associated mobile or web
            applications. By accessing or using the Service, you agree to these
            Terms. If you do not agree, please do not use the Service.
          </p>
          <h3>1. Acceptance of Terms</h3>
          <p>
            By creating an account, accessing, or using the Service, you confirm
            that you are at least 18 years old and agree to comply with these
            Terms. If you are accessing the Service on behalf of an
            organization, you represent and warrant that you have the authority
            to bind that organization to these Terms.
          </p>
          <h3>2. Description of the Service</h3>
          <p>
            OnlineFitness.app provides tools for fitness trainers to manage
            clients, design training programs, upload video courses, and
            communicate with clients. The Service may include free features as
            well as premium subscription plans for additional functionality.
          </p>
          <h3>3. Account Registration</h3>
          <p>
            <b>Eligibility:</b> You must provide accurate and up-to-date
            information during registration.
          </p>
          <p>
            <b>Responsibility:</b> You are responsible for maintaining the
            confidentiality of your account credentials and for all activities
            that occur under your account.
          </p>
          <p>
            <b>Account Termination:</b> We reserve the right to suspend or
            terminate your account if you violate these Terms.
          </p>
          <h3>4. Subscription and Fees</h3>
          <p>
            <b>Subscription Plans:</b> Trainers can subscribe to plans based on
            the number of clients they manage. Fees are as follows:
          </p>
          <ul>
            <li>
              Up to 5 clients: <b>$30/month</b>
            </li>
            <li>
              Up to 10 clients: <b>$50/month</b>
            </li>
            <li>
              Up to 20 clients: <b>$80/month</b>
            </li>
          </ul>
          <p>
            <b>Payment:</b> Payments are non-refundable and are processed
            securely through our third-party payment provider. Subscriptions
            automatically renew unless canceled before the renewal date.
          </p>
          <p>
            <b>Taxes:</b> You are responsible for any applicable taxes
            associated with your subscription.
          </p>
          <h3>5. User Content</h3>
          <p>
            <b>Ownership:</b> You retain ownership of any content you upload to
            the Service (e.g., videos, training programs).
          </p>
          <p>
            <b>License:</b> By uploading content, you grant OnlineFitness.app a
            worldwide, royalty-free, and non-exclusive license to host and
            display your content for the purpose of providing the Service.
          </p>
          <p>
            <b>Prohibited Content:</b> You may not upload content that is
            illegal, offensive, or violates third-party rights.
          </p>
          <h3>6. Prohibited Uses</h3>
          <p>You agree not to:</p>
          <p>Use the Service for any unlawful purposes.</p>
          <p>Interfere with or disrupt the operation of the Service.</p>
          <p>
            Reverse engineer or attempt to extract the source code of the
            Service.
          </p>
          <h3>7. Intellectual Property</h3>
          <p>
            All intellectual property rights in the Service, including the
            software, design, and trademarks, are owned by OnlineFitness.app.
            You may not use these rights without prior written permission.
          </p>
          <h3>8. Privacy Policy</h3>
          <p>
            Your use of the Service is subject to our{" "}
            <MuiLink component={Link} to="/privacy-policy">
              Privacy Policy
            </MuiLink>
            , which explains how we collect, use, and protect your personal
            information.
          </p>
          <h3>9. Limitation of Liability</h3>
          <p>
            To the fullest extent permitted by law, OnlineFitness.app and its
            affiliates will not be liable for any indirect, incidental, or
            consequential damages arising out of your use of the Service.
          </p>
          <h3>10. Indemnification</h3>
          <p>
            You agree to indemnify and hold OnlineFitness.app harmless from any
            claims, damages, or expenses resulting from your use of the Service
            or violation of these Terms.
          </p>
          <h3>11. Changes to the Terms</h3>
          <p>
            We may update these Terms from time to time. Changes will take
            effect upon posting the revised Terms to the Service. Continued use
            of the Service constitutes acceptance of the updated Terms.
          </p>
          <h3>12. Termination</h3>
          <p>
            We reserve the right to suspend or terminate your access to the
            Service at our sole discretion if you violate these Terms.
          </p>
          <h3>13. Governing Law</h3>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of Georgia. Any disputes will be resolved in the courts of
            Georgia.
          </p>
          <h3>14. Contact Information</h3>
          <p>For questions or support, please contact us at:</p>
          <p>
            Email:{" "}
            <MuiLink href="mailto:support@onlinefitness.app">
              support@onlinefitness.app
            </MuiLink>
          </p>

          <p>
            Thank you for using OnlineFitness.app! We’re excited to help you
            grow your fitness business online.
          </p>
        </Typography>
      </Container>
    </Paper>
  );
}
