import { InvitationData, InvitationStatus } from "../../types";
import { useInvitations } from "../../queries";
import InvitationListItem from "./InvitationListItem";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";

export default function InvitationList({
  clientId,
  coachId,
  status,
}: {
  clientId: string;
  coachId: string;
  status?: InvitationStatus;
}) {
  const [snapshot] = useInvitations({ clientId, coachId, status });

  return (
    <Paper elevation={0} square>
      <List disablePadding>
        {snapshot?.docs.map((doc) => (
          <InvitationListItem
            key={doc.id}
            invitationId={doc.id}
            invitationData={doc.data() as InvitationData}
          />
        ))}
      </List>
    </Paper>
  );
}
