import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "utils/firebase";
import type { WorkoutData } from "features/workouts";

export function useClientWorkout({
  clientId,
  workoutId,
}: {
  clientId: string;
  workoutId: string;
}) {
  const [value, loading, error] = useDocumentDataOnce(
    doc(firestore, "users", clientId, "workouts", workoutId)
  );

  return {
    error,
    loading,
    data: value as WorkoutData | null,
  };
}
