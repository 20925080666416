import { markAllNotificationsAsRead } from "../../actions";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import HeaderButton from "components/HeaderButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function MarkAllNotificationsAsReadButton() {
  const { t } = useTranslation("components");

  const onClick = () => {
    markAllNotificationsAsRead();

    enqueueSnackbar(t("notifications.markedAllAsRead"), {
      variant: "success",
    });
  };

  return (
    <HeaderButton
      onClick={onClick}
      title={t("notifications.markAllAsRead")}
      icon={<DoneAllIcon />}
    />
  );
}
