import { SectionList, SectionListEmpty } from "components/SectionList";
import { useNotifications } from "../../queries";
import { isPushNotificationsSupported } from "../../utils";
import groupToSections from "utils/groupToSections";
import formatDate from "utils/formatDate";
import PushNotification from "../PushNotification";
import { Timestamp } from "firebase/firestore";
import NotificationItem from "./NotificationItem";
import { useTranslation } from "react-i18next";

function header(date: Timestamp): string {
  return formatDate(date.toDate());
}

export default function Notifications() {
  const { t } = useTranslation("components");
  const { loading, error, data } = useNotifications();

  const sections = groupToSections(data, "createdAt", header).map(
    (section) => ({
      square: true,
      dividerVariant: "fullWidth" as const,
      header: section.header,
      items: section.items,
    })
  );

  return (
    <>
      {isPushNotificationsSupported && <PushNotification />}

      <SectionList
        sections={sections}
        loading={loading}
        error={error}
        renderEmpty={() => {
          return (
            <SectionListEmpty
              title={t("notifications.empty.title")}
              text={t("notifications.empty.text")}
              icon="/flaticon/notification.png"
            />
          );
        }}
        renderItem={(notification) => {
          return <NotificationItem notification={notification} />;
        }}
      />
    </>
  );
}
