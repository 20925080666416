import FormList, { type Schema } from "components/FormList";
import { useNavigate } from "react-router";
import { updateUser } from "./actions";
import { useTranslation } from "react-i18next";
// import { useOutletContext } from "react-router";
// import { InvitationData } from "features/invitations";
// import Avatar from "@mui/material/Avatar";
import { guessTimeZone, timeZones } from "utils/dayjs";
import { countries } from "utils/countries";
import { useViewer } from "features/viewer";

export default function InvitationProfilePage() {
  const { t } = useTranslation("invitation");
  const navigate = useNavigate();
  const viewer = useViewer();
  // const { invitationData } = useOutletContext() as {
  //   invitationId: string;
  //   invitationData: InvitationData;
  // };
  if (!viewer) return null; // loading...

  const schema: Schema = [
    // {
    //   // backgroundColor: "transparent",
    //   fields: [
    //     {
    //       type: "typography",
    //       text: t("screens.invitation-profile.coach.text", {
    //         displayName: invitationData.client.displayName,
    //       }),
    //       color: "textPrimary",
    //       variant: "body1",
    //     },
    //     {
    //       type: "item",
    //       primary: invitationData.coach.displayName,
    //       secondary: t("screens.invitation.your-coach"),
    //       avatar: <Avatar src={invitationData.coach.photoURL} />,
    //     },
    //   ],
    // },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "avatar",
          name: "photoURL",
          label: t("screens.invitation-profile.fields.photoURL"),
        },
      ],
    },
    {
      header: t("screens.invitation-profile.fields.displayName"),
      fields: [
        {
          type: "text",
          name: "displayName",
          placeholder: t("screens.invitation-profile.fields.displayName"),
          required: true,
        },
      ],
    },
    {
      header: t("screens.invitation-profile.fields.timeZone"),
      fields: [
        {
          type: "select",
          placeholder: t("screens.invitation-profile.fields.timeZone"),
          name: "timeZone",
          options: timeZones.map((option) => ({
            label: option,
            value: option,
          })),
          required: true,
        },
      ],
    },
    {
      header: t("screens.invitation-profile.fields.country"),
      fields: [
        {
          type: "select",
          placeholder: t("screens.invitation-profile.fields.country"),
          name: "country",
          options: countries.map((option) => ({
            label: option.name,
            value: option.code,
          })),
          required: true,
        },
      ],
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.invitation-profile.submit"),
          color: "primary",
          variant: "contained",
          size: "large",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    photoURL?: string;
    displayName?: string;
    timeZone?: string;
    country?: string;
  }) => {
    await updateUser(values);

    navigate("/install");
  };

  const values = {
    displayName: viewer!.displayName,
    email: viewer!.email,
    timeZone: viewer!.timeZone || guessTimeZone(),
  };

  return (
    <FormList
      defaultSaved={false}
      schema={schema}
      values={values}
      onSubmit={onSubmit}
    />
  );
}
