import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { createProgramWorkoutExercise } from "./actions";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router";

export default function CreateProgramWorkoutExercisePage() {
  const { t } = useTranslation(["coach", "core"]);
  const navigate = useNavigate();
  const { programId, workoutId } = useParams() as {
    programId: string;
    workoutId: string;
  };

  const schema: Schema = [
    {
      header: t("screens.create-program-workout-exercise.fields.videoUrl"),
      fields: [
        {
          type: "text",
          name: "videoUrl",
          placeholder: "https://www.youtube.com/watch?v=...",
        },
      ],
    },
    {
      header: t("screens.create-program-workout-exercise.header"),
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t(
            "screens.create-program-workout-exercise.fields.title"
          ),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t(
            "screens.create-program-workout-exercise.fields.description"
          ),
          multiline: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-program-workout-exercise.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    videoUrl: string;
    title: string;
    description?: string;
  }) => {
    await createProgramWorkoutExercise({
      programId,
      workoutId,
      ...values,
    });

    enqueueSnackbar(t("screens.create-program-workout-exercise.success"), {
      variant: "success",
    });

    navigate(`../..`);
  };

  const values = {};

  return <FormList schema={schema} onSubmit={onSubmit} values={values} />;
}
