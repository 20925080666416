export enum ClientStatus {
  Active = "active",
  Archived = "archived",
}

export interface Client extends ClientData {
  id: string;
}

export interface ClientData {
  status: ClientStatus;
  displayName: string;
  email: string;
  photoURL: string;
}
