import { Timestamp } from "firebase/firestore";

export enum UserRole {
  Client = "client",
  Coach = "coach",
  System = "system",
}

export interface BaseUserData {
  displayName: string;
  email?: string;
  photoURL?: string;
  timeZone?: string;
  country?: string;
  language?: string;
  updatedAt?: Timestamp;
  createdAt?: Timestamp;
}

export interface CoachUserData extends BaseUserData {
  role: UserRole.Coach;
  isArchived?: boolean;
}

export interface ClientUserData extends BaseUserData {
  role: UserRole.Client;
  isArchived?: boolean;
}

export interface SystemUserData extends BaseUserData {
  role: UserRole.System;
}

interface BaseUser {
  id: string;
}

export type UserData = CoachUserData | ClientUserData;

export type CoachUser = CoachUserData & BaseUser;

export type ClientUser = ClientUserData & BaseUser;

export type SystemUser = SystemUserData & BaseUser;

export type User = CoachUser | ClientUser | SystemUser;
