import VideoUploadProgress from "components/VideoUploadProgress";
import {
  type Section,
  SectionList,
  SectionListItem,
  SectionListItemThumbnail,
  SectionListEmpty,
} from "components/SectionList";
import { useExercises } from "./queries";
import { useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getThumbnailUrl } from "utils/mux";
import { uploadsStore } from "utils/upload-video";
import { useSyncExternalStore } from "react";
import {
  SystemChatMessageType,
  useCreateSystemChatMessage,
} from "features/chat";
import { Exercise } from "features/exercises";

export default function ExercisesPage() {
  const { t } = useTranslation("coach");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") as string | undefined;
  const { loading, error, data } = useExercises({ search });
  useCreateSystemChatMessage(
    SystemChatMessageType.WELCOME_MESSAGE_ON_EXERCISES_PAGE
  );
  const uploads = useSyncExternalStore(
    uploadsStore.subscribe,
    uploadsStore.getSnapshot
  );

  const sections: Section<Exercise>[] = [
    {
      loading,
      error,
      dividerVariant: "fullWidth",
      items: data,
      square: true,
      renderItem: (exercise: Exercise) => {
        const thumbnailUrl = getThumbnailUrl(exercise?.video?.playbackId, {
          width: "80",
          height: "80",
          fit_mode: "smartcrop",
        });
        return (
          <SectionListItem
            to={exercise.id + "/edit"}
            primary={exercise.title}
            secondary={
              exercise?.video?.status !== "ready" && exercise?.video?.status
            }
            avatar={<SectionListItemThumbnail src={thumbnailUrl} />}
            action={<VideoUploadProgress upload={uploads[exercise.id]} />}
          />
        );
      },
      renderEmpty: () => {
        if (search) {
          return (
            <SectionListEmpty
              title={t("screens.exercises.search.empty.title")}
              text={t("screens.exercises.search.empty.text")}
              icon="/flaticon/search.png"
            />
          );
        }
        return (
          <SectionListEmpty
            title={t("screens.exercises.empty.title")}
            text={t("screens.exercises.empty.text")}
            // buttonText={t("screens.exercises.empty.button")}
            // buttonTo="create"
            icon="/flaticon/computer.png"
          />
        );
      },
    },
  ];

  return <SectionList sections={sections} />;
}
