import { useWorkouts } from "./queries";
import { useTranslation } from "react-i18next";
import { useMemo, useCallback } from "react";
import dayjs, { Dayjs } from "utils/dayjs";
import { useSearchParams } from "react-router";
import ErrorScreen from "components/ErrorScreen";
import { WorkoutCalendar, WorkoutCalendarNavigation } from "features/workouts";
import { Workout } from "features/workouts";
import {
  SectionList,
  SectionListItem,
  SectionListEmpty,
} from "components/SectionList";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export default function WorkoutsPage() {
  const { t } = useTranslation("client");

  const [searchParams, setSearchParams] = useSearchParams();

  const isListView = (searchParams.get("date") || "").length > 7;
  const view = isListView ? "day" : "month";
  const formatByView = {
    month: "YYYY-MM",
    day: "YYYY-MM-DD",
  };
  const format = formatByView[view];

  const date = useMemo(
    () => dayjs(searchParams.get("date") || undefined),
    [searchParams]
  );

  const onChangeDate = useCallback(
    (date: Dayjs) => {
      searchParams.set("date", date.format(format));
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams, format]
  );

  const { loading, error, empty, workoutsByDate } = useWorkouts();

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (empty) {
    return (
      <SectionListEmpty
        title={t("screens.workouts.empty-calendar.title")}
        text={t("screens.workouts.empty-calendar.text")}
        icon="/flaticon/schedule.png"
      />
    );
  }

  if (!isListView) {
    return (
      <>
        <WorkoutCalendarNavigation
          onChangeDate={onChangeDate}
          date={date}
          view={view}
        />
        <WorkoutCalendar
          date={date}
          workoutsByDate={workoutsByDate}
          openWorkoutPageIfOnlyOneWorkout
          disableEmptyDays
        />
      </>
    );
  }

  const key = date.format("YYYY-MM-DD");
  const sections = [
    {
      square: true,
      dividerVariant: "fullWidth" as const,
      items: workoutsByDate[key],
      renderItem: (workout: Workout) => (
        <SectionListItem
          to={workout.id}
          primary={workout.title}
          secondary={
            workout.exerciseIds.length > 0 &&
            t("screens.workouts.exercises", {
              count: workout.exerciseIds.length,
            })
          }
          action={workout.completed && <TaskAltIcon color="success" />}
        />
      ),
      renderEmpty: () => (
        <SectionListEmpty
          title={t("screens.workouts.empty.title")}
          text={t("screens.workouts.empty.text", { date })}
          icon="/flaticon/schedule.png"
        />
      ),
    },
  ];

  return (
    <>
      <WorkoutCalendarNavigation
        onChangeDate={onChangeDate}
        date={date}
        view={view}
      />
      <SectionList sections={sections} spacing={2} />
    </>
  );
}
