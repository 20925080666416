import HomeLayout from "./(home)/layout";
import HomePage from "./(home)/page";

import PagesLayout from "./(pages)/layout";
import pagesRoutes from "./(pages)/routes";

const routes = [
  {
    element: <HomeLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },
  {
    element: <PagesLayout />,
    children: pagesRoutes,
  },
];

export default routes;
