import api from "utils/api";

export function addExerciseToClientWorkout({
  clientId,
  workoutId,
  exerciseId,
}: {
  clientId: string;
  workoutId: string;
  exerciseId: string;
}) {
  return api("addExerciseToClientWorkout", {
    clientId,
    workoutId,
    exerciseId,
  });
}
