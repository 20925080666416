import Button from "@mui/material/Button";

export default function CancelButton({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <Button fullWidth onClick={onClick}>
      Cancel
    </Button>
  );
}
