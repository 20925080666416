import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ErrorScreen from "components/ErrorScreen";
import { Section } from "./types";
import SectionListItems from "./SectionListItems";
import SectionListItemsSortable from "./SectionListItemsSortable";
import SectionFooter from "./SectionFooter";
import SectionHeader from "./SectionHeader";

export default function SectionList<Item extends { id: string }>({
  sections,
  spacing = 0,
  renderItem = () => null,
  renderEmpty = () => null,
  error,
  loading,
}: {
  sections: Section<Item>[];
  spacing?: number;
  renderItem?: (item: Item, index: number) => React.ReactNode;
  renderEmpty?: () => React.ReactNode;
  error?: Error;
  loading?: boolean;
}) {
  if (error) return <ErrorScreen error={error} />;

  return (
    <Stack spacing={spacing}>
      {sections.map((section, index) => {
        return (
          <Box key={index}>
            {section.header && <SectionHeader>{section.header}</SectionHeader>}

            {section.component}

            {section.error && <ErrorScreen error={section.error} />}

            {section.items && section.items.length > 0 && (
              <>
                {section.onReorder ? (
                  <SectionListItemsSortable
                    section={section}
                    renderItem={renderItem}
                  />
                ) : (
                  <SectionListItems section={section} renderItem={renderItem} />
                )}
              </>
            )}

            {!section.loading &&
              !section.items?.length &&
              (section.renderEmpty ? section.renderEmpty() : renderEmpty())}

            {section.footer && (
              <SectionFooter align={section.footerAlign}>
                {section.footer}
              </SectionFooter>
            )}
          </Box>
        );
      })}

      {!loading && sections.length === 0 && renderEmpty()}
    </Stack>
  );
}
