import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { doc, collection, query, orderBy } from "firebase/firestore";
import { firestore } from "utils/firebase";
import { useParams } from "react-router";
import { TeamMember, Team } from "features/teams";

export function useTeam() {
  const { teamId } = useParams() as { teamId: string };
  const [snapshot, loading, error] = useDocument(
    doc(firestore, "teams", teamId)
  );

  if (error) throw error;

  if (loading) return null;

  return {
    id: snapshot!.id,
    ...snapshot!.data(),
  } as Team;
}

export function useTeamMembers() {
  const { teamId } = useParams() as { teamId: string };
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "teams", teamId, "members"),
      orderBy("displayName", "asc")
    )
  );

  if (error) throw error;

  if (loading) return [];

  if (!snapshot) return [];
  if (snapshot.empty) return [];

  const docs = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return docs as TeamMember[];
}
