import BillingPage from "./page";
import BillingPlanPage from "./plan/page";

import HeaderBackButton from "components/HeaderBackButton";

const routes = [
  {
    index: true,
    element: <BillingPage />,
    handle: {
      header: {
        title: "coach.screens.billing.title",
      },
    },
  },
  {
    path: "plan",
    element: <BillingPlanPage />,
    handle: {
      header: {
        title: "coach.screens.billing-plan.title",
        back: <HeaderBackButton to="." />,
      },
    },
  },
];

export default routes;
