import api from "utils/api";

export async function updateClientWorkoutExercise(values: {
  clientId: string;
  workoutId: string;
  exerciseId: string;
  title?: string;
  description?: string;
}) {
  await api("updateClientWorkoutExercise", values);
}

export async function deleteClientWorkoutExercise({
  clientId,
  workoutId,
  exerciseId,
}: {
  clientId: string;
  workoutId: string;
  exerciseId: string;
}) {
  await api("deleteClientWorkoutExercise", { clientId, workoutId, exerciseId });
}
