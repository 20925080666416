import { useState } from "react";
import {
  type Section,
  SectionList,
  SectionListItem,
  SectionListEmpty,
  SectionListItemThumbnail,
} from "components/SectionList";
import { useExercises } from "./queries";
import { addExerciseToClientWorkout } from "./actions";
import { useSearchParams, useParams, useNavigate } from "react-router";
import { getThumbnailUrl } from "utils/mux";
import { useTranslation } from "react-i18next";
import ErrorScreen from "components/ErrorScreen";
import BottomButton from "components/BottomButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import { useClientWorkout } from "./queries";
import { logEvent } from "utils/analytics";
import { enqueueSnackbar } from "notistack";

interface Exercise {
  id: string;
  title: string;
  description?: string;
  video?: {
    playbackId: string;
  };
}
export default function AddClientWorkoutExercisePage() {
  const { t } = useTranslation("coach");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") as string;
  const { clientId, workoutId } = useParams() as {
    clientId: string;
    workoutId: string;
  };
  // const clientWorkout = useClientWorkout() as Workout;
  const { loading, error, data: exercises } = useExercises(search);
  const [selectedExerciseIds, setSelectedExerciseIds] = useState<string[]>([]);

  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  if (exercises.length === 0) {
    if (search) {
      return (
        <SectionListEmpty
          title={t("screens.add-client-workout-exercise.search.empty.title")}
          text={t("screens.add-client-workout-exercise.search.empty.text")}
          icon="/flaticon/search.png"
        />
      );
    }
    return (
      <SectionListEmpty
        title={t("screens.add-client-workout-exercise.empty.title")}
        text={t("screens.add-client-workout-exercise.empty.text")}
        buttonText={t("screens.add-client-workout-exercise.empty.button")}
        buttonTo="../create"
        icon="/flaticon/computer.png"
      />
    );
  }

  const sections: Section<Exercise>[] = [
    {
      header: t("screens.add-client-workout-exercise.header"),
      dividerVariant: "fullWidth",
      items: exercises,
      square: true,
      renderItem: (exercise: Exercise) => {
        const thumbnailUrl = getThumbnailUrl(exercise?.video?.playbackId, {
          width: "80",
          height: "80",
          fit_mode: "smartcrop",
        });

        return (
          <SectionListItem
            primary={exercise.title}
            // secondary={exercise.type}
            selected={selectedExerciseIds.includes(exercise.id)}
            avatar={<SectionListItemThumbnail src={thumbnailUrl} />}
            action={
              selectedExerciseIds.includes(exercise.id) ? (
                <CheckCircleOutlineIcon />
              ) : null
            }
            onClick={() => {
              setSelectedExerciseIds((prev) =>
                prev.includes(exercise.id)
                  ? prev.filter((id) => id !== exercise.id)
                  : [...prev, exercise.id]
              );
            }}
          />
        );
      },
    },
  ];

  if (selectedExerciseIds.length > 0) {
    sections.push({
      square: true,
      component: (
        <AddButton
          selectedExerciseIds={selectedExerciseIds}
          clientId={clientId}
          workoutId={workoutId}
        />
      ),
    });
  }

  return <SectionList sections={sections} />;
}

function AddButton({
  selectedExerciseIds,
  clientId,
  workoutId,
}: {
  selectedExerciseIds: string[];
  clientId: string;
  workoutId: string;
}) {
  const { t } = useTranslation("coach");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <BottomButton
      size="large"
      variant="contained"
      color="primary"
      loading={loading}
      onClick={() => {
        setLoading(true);

        const promises = selectedExerciseIds.map((exerciseId) => {
          return addExerciseToClientWorkout({
            clientId,
            workoutId,
            exerciseId,
          });
        });
        Promise.all(promises)
          .then(() => {
            logEvent("client_workout_exercise_added", {
              clientId,
              workoutId,
              count: selectedExerciseIds.length,
            });
            navigate(`../..`);
          })
          .catch((error) => {
            console.dir(error);
            enqueueSnackbar(error.message, { variant: "error" });
          })
          .finally(() => {
            setLoading(false);
          });
      }}
      disabled={selectedExerciseIds.length === 0}
    >
      {t("screens.add-client-workout-exercise.add-button", {
        count: selectedExerciseIds.length,
      })}
    </BottomButton>
  );
}
