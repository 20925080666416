import {
  SectionListEmpty,
  SectionList,
  SectionListItem,
} from "components/SectionList";
import { useCoaches } from "./queries";
import Avatar from "@mui/material/Avatar";
import { useSearchParams, useNavigate } from "react-router";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Coach, CoachStatus } from "features/coaches";
import { ChatIconButton, ChatType } from "features/chat";
import { auth } from "utils/firebase";
import SectionTabs from "components/SectionTabs";

export default function CoachesPage() {
  const { t } = useTranslation("client");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") as string;
  const tab = (searchParams.get("tab") as CoachStatus) || CoachStatus.Active;
  const { data, loading, error } = useCoaches(search);
  const activeCoaches = data.filter(
    (coach: Coach) => coach.status === CoachStatus.Active
  );
  const archivedCoaches = data.filter(
    (coach: Coach) => coach.status === CoachStatus.Archived
  );
  const activeCoachesCount = activeCoaches.length;
  const archivedCoachesCount = archivedCoaches.length;
  const items = tab === CoachStatus.Active ? activeCoaches : archivedCoaches;

  const sections = [
    {
      loading,
      error,
      items,
      dividerVariant: "fullWidth" as const,
      square: true,
      renderItem: (coach: Coach) => (
        <CoachListItem coach={coach} navigate={navigate} />
      ),
      renderEmpty: () => {
        if (search) {
          return (
            <SectionListEmpty
              title={t("screens.coaches.search.empty.title")}
              text={t("screens.coaches.search.empty.text")}
              icon="/flaticon/search.png"
            />
          );
        }
        return (
          <SectionListEmpty
            title={t("screens.coaches.empty.title")}
            text={t("screens.coaches.empty.text")}
            icon="/flaticon/lifestyle.png"
          />
        );
      },
    },
  ];

  const handleChangeTab = useCallback(
    (_: unknown, newValue: string) => {
      searchParams.set("tab", newValue);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const displayTabs = activeCoachesCount > 0 && archivedCoachesCount > 0;

  return (
    <>
      {displayTabs && (
        <SectionTabs
          tab={tab}
          onChange={handleChangeTab}
          tabs={[
            {
              value: CoachStatus.Active,
              label: t("screens.coaches.tabs.active", {
                count: activeCoachesCount,
              }),
            },
            {
              value: CoachStatus.Archived,
              label: t("screens.coaches.tabs.archived", {
                count: archivedCoachesCount,
              }),
            },
          ]}
        />
      )}
      <SectionList sections={sections} />
    </>
  );
}

function CoachListItem({
  coach,
  navigate,
}: {
  coach: Coach;
  navigate: (path: string) => void;
}) {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      navigate(coach.id + "?chat=true");
    },
    [coach.id, navigate]
  );
  return (
    <SectionListItem
      primary={coach.displayName}
      secondary={coach.email}
      to={coach.id}
      avatar={<Avatar src={coach.photoURL} />}
      action={
        <ChatIconButton
          type={ChatType.Client}
          clientId={auth.currentUser!.uid}
          coachId={coach.id}
          onClick={onClick}
        />
      }
    />
  );
}
