import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { firestore, auth } from "utils/firebase";
import { ClientWorkoutExercise } from "features/exercises";

export function useClientWorkoutExercise({
  workoutId,
  exerciseId,
}: {
  workoutId: string;
  exerciseId: string;
}) {
  const [value, loading, error] = useDocumentData(
    doc(
      firestore,
      "users",
      auth.currentUser!.uid,
      "workouts",
      workoutId,
      "exercises",
      exerciseId
    )
  );

  return {
    loading,
    error,
    data: value
      ? ({
          id: exerciseId,
          ...value,
        } as ClientWorkoutExercise)
      : undefined,
  };
}
