import Typography from "@mui/material/Typography";

export default function SectionHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography variant="subtitle2" color="textSecondary" sx={{ px: 2, py: 1 }}>
      {children}
    </Typography>
  );
}
