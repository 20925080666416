import Menu from "components/Menu";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import ContrastIcon from "@mui/icons-material/Contrast";
// import AddIcon from "@mui/icons-material/Add";
// import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  //   purple,
  // indigo,
  red,
  //   blue,
  green,
  deepOrange,
  deepPurple,
  teal,
} from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { signOut } from "firebase/auth";
import { auth } from "utils/firebase";
import { enqueueSnackbar } from "notistack";

export default function HomePage() {
  const { t } = useTranslation("coach");

  const onClickSignOut = () => {
    signOut(auth)
      .then(() => {
        enqueueSnackbar(t("screens.menu.signout.success"), {
          variant: "success",
        });
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: "error" }));
  };

  const menu = [
    [
      {
        primary: t("screens.menu.profile"),
        icon: <PersonIcon color="inherit" />,
        iconColor: green[500],
        link: { to: "/settings/profile" },
      },
      {
        primary: t("screens.menu.email"),
        icon: <EmailIcon color="inherit" />,
        iconColor: deepOrange[500],
        link: { to: "/settings/email" },
      },
      {
        primary: t("screens.menu.password"),
        icon: <PasswordIcon color="inherit" />,
        iconColor: deepPurple[500],
        link: { to: "/settings/password" },
      },
      {
        primary: t("screens.menu.theme"),
        icon: <ContrastIcon color="inherit" />,
        iconColor: teal[500],
        link: { to: "/settings/theme" },
      },
      // {
      //   primary: t("screens.menu.language"),
      //   icon: <LanguageIcon color="inherit" />,
      //   iconColor: indigo[500],
      //   link: { to: "/settings/language" },
      // },
    ],
    [
      {
        primary: t("screens.menu.signout"),
        icon: <LogoutIcon color="inherit" />,
        iconColor: red[500],
        onClick: onClickSignOut,
      },
    ],
  ];

  return <Menu menu={menu} />;
}
