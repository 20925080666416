import "utils/firebase";
import "utils/sentry";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router";
import { router } from "./router";
import { SnackbarProvider } from "notistack";
import OnlineStatusProvider from "components/OnlineStatusProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import i18n from "utils/i18n";
import ThemeProvider from "components/ThemeProvider";
import OfflineAlert from "components/OfflineAlert";
import SplashScreen from "components/SplashScreen";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

console.log("__COMMIT_HASH__", __COMMIT_HASH__);
console.log("__BUILD_DATE__", __BUILD_DATE__);

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HelmetProvider>
          <OnlineStatusProvider>
            <ThemeProvider>
              <Helmet titleTemplate="%s | OnlineFitness.app">
                <html lang="en" />
                <meta
                  name="description"
                  content="OnlineFitness.app is a fitness app that helps you stay healthy and fit."
                />
              </Helmet>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={2000}
                preventDuplicate
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              />
              <OfflineAlert />
              <Suspense fallback={<SplashScreen />}>
                <RouterProvider router={router} />
              </Suspense>
            </ThemeProvider>
          </OnlineStatusProvider>
        </HelmetProvider>
      </LocalizationProvider>
    </I18nextProvider>
  </StrictMode>
);
