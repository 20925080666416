import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useClientWorkout } from "./queries";
import BottomButton from "components/BottomButton";
import { useTranslation } from "react-i18next";

interface Workout {
  title: string;
}

export default function WorkoutCompletePage() {
  const { t } = useTranslation("client");
  const { width, height } = useWindowSize();
  const workout = useClientWorkout() as Workout | null;

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "80vh" }}
        spacing={2}
      >
        <Typography variant="subtitle1" color="textSecondary" align="center">
          {t("screens.workoutComplete.title")}
        </Typography>
        <Typography variant="h4" color="textPrimary" align="center">
          {workout?.title}
        </Typography>

        <img src="/flaticon/trophy.png" alt="" width="100" />

        <BottomButton
          variant="contained"
          color="primary"
          size="large"
          to="../.."
        >
          {t("screens.workoutComplete.button")}
        </BottomButton>
      </Stack>
      <Confetti width={width} height={height} />
    </>
  );
}
