import { collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";

interface Team {
  id: string;
  title: string;
}

export function useTeams(search?: string) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "teams"),
      where("members", "array-contains", auth.currentUser!.uid),
      orderBy("title", "asc")
    )
  );

  if (error) throw error;

  if (loading) return [];

  if (!snapshot) return "empty";
  if (snapshot.empty) return "empty";

  const docs = snapshot.docs
    .map(
      (doc) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as Team)
    )
    .filter((team: Team) => {
      if (!search) return true;

      return search
        .toLowerCase()
        .split(" ")
        .every((word) => {
          return team.title.toLowerCase().includes(word.trim());
        });
    });

  if (docs.length === 0) return "empty";

  return docs;
}
