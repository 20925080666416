import FormList, { type Schema } from "components/FormList";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useProgramWorkout } from "./queries";
import { updateProgramWorkout } from "./actions";

export default function EditProgramWorkoutScreen() {
  const { t } = useTranslation("coach");
  const { programId, workoutId } = useParams();
  const programWorkout = useProgramWorkout();

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.edit-program-workout.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.edit-program-workout.fields.description"),
          multiline: true,
        },
        {
          type: "number",
          name: "day",
          placeholder: t("screens.edit-program-workout.fields.day.placeholder"),
          endAdornment: t("screens.edit-program-workout.fields.day.adornment"),
          required: true,
        },
      ],
      footer: t("screens.edit-program-workout.fields.day.footer"),
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.edit-program-workout.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    title: string;
    description?: string;
    day: number;
  }) => {
    await updateProgramWorkout({
      programId: programId!,
      workoutId: workoutId!,
      ...values,
    });

    enqueueSnackbar(t("screens.edit-program-workout.success"), {
      variant: "success",
    });
  };

  if (!programWorkout) return null;

  const values = {
    title: programWorkout.title,
    description: programWorkout.description,
    day: programWorkout.day,
  };

  return <FormList schema={schema} values={values} onSubmit={onSubmit} />;
}
