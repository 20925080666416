import HeaderButton from "components/HeaderButton";
import SearchIcon from "@mui/icons-material/Search";

export default function HeaderSearchButton({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <HeaderButton title="Search" icon={<SearchIcon />} onClick={onClick} />
  );
}
