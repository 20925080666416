import {
  SectionList,
  SectionListItem,
  SectionListEmpty,
  SectionListItemThumbnail,
} from "components/SectionList";
import { useClientWorkout, useClientWorkoutExercises } from "./queries";
import formatTextToHtml from "utils/formatTextToHtml";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useTranslation } from "react-i18next";
import { getThumbnailUrl } from "utils/mux";
import { useParams } from "react-router";
import type { Exercise } from "features/exercises";
import ErrorScreen from "components/ErrorScreen";
import Paper from "@mui/material/Paper";
import { updateClientWorkout } from "./actions";

export default function ClientWorkoutPage() {
  const { clientId, workoutId } = useParams() as {
    clientId: string;
    workoutId: string;
  };

  const {
    loading,
    error,
    data: clientWorkout,
  } = useClientWorkout({ clientId, workoutId });

  if (!clientWorkout) return null;
  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  return (
    <>
      <Paper elevation={0} square>
        <SectionListItem
          primary={clientWorkout.title}
          slotProps={{
            primary: {
              variant: "h6",
              gutterBottom: !!clientWorkout.description,
            },
          }}
          secondary={
            clientWorkout.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: formatTextToHtml(clientWorkout.description),
                }}
              />
            )
          }
          action={clientWorkout.completed && <TaskAltIcon color="success" />}
        />
      </Paper>
      <ClientWorkoutExerciseList
        clientId={clientId}
        workoutId={workoutId}
        exerciseIds={clientWorkout.exerciseIds}
      />
    </>
  );
}

function ClientWorkoutExerciseList({
  clientId,
  workoutId,
  exerciseIds,
}: {
  clientId: string;
  workoutId: string;
  exerciseIds: string[];
}) {
  const { t } = useTranslation("coach");

  const { loading, error, data } = useClientWorkoutExercises({
    clientId,
    workoutId,
    exerciseIds,
  });

  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  if (data.length === 0) {
    return (
      <SectionListEmpty
        title={t("screens.client-workout.exercises.empty.title")}
        text={t("screens.client-workout.exercises.empty.text")}
        buttonText={t("screens.client-workout.exercises.empty.button")}
        buttonTo="exercises/add"
        icon="/flaticon/computer.png"
      />
    );
  }

  const renderItem = (exercise: Exercise) => {
    const thumbnailUrl = getThumbnailUrl(exercise?.video?.playbackId, {
      width: "80",
      height: "80",
      fit_mode: "smartcrop",
    });

    return (
      <SectionListItem
        component="div"
        to={`exercises/${exercise.id}`}
        primary={exercise.title}
        avatar={<SectionListItemThumbnail src={thumbnailUrl} />}
        action={exercise.completed && <TaskAltIcon color="success" />}
      />
    );
  };

  return (
    <SectionList
      renderItem={renderItem}
      sections={[
        {
          header: t("screens.client-workout.exercises.header", {
            count: data.length,
          }),
          items: data,
          square: true,
          onReorder: (ids: string[]) => {
            updateClientWorkout({ clientId, workoutId, exerciseIds: ids });
          },
        },
      ]}
    />
  );
}
