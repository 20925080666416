import {
  collection,
  query,
  where,
  orderBy,
  limit,
  FirestoreError,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import {
  ChatType,
  ChatData,
  ChatMessage,
  ChatMessageData,
} from "features/chat";

export function useChat({
  type,
  clientId,
  coachId,
  workoutId,
  exerciseId,
}: {
  type: ChatType;
  clientId?: string;
  coachId?: string;
  workoutId?: string;
  exerciseId?: string;
}) {
  let q = query(collection(firestore, "chats"));

  if (workoutId) {
    q = query(q, where("workoutId", "==", workoutId));
  }

  if (exerciseId) {
    q = query(q, where("exerciseId", "==", exerciseId));
  }

  if (clientId) {
    q = query(q, where("clientId", "==", clientId));
  }

  if (coachId) {
    q = query(q, where("coachId", "==", coachId));
  }

  const [snapshot, loading, error] = useCollection(
    query(
      q,
      where("memberIds", "array-contains", auth.currentUser!.uid),
      where("type", "==", type),
      limit(1)
    )
  );

  return {
    loading,
    error,
    empty: snapshot?.empty,
    chatId: snapshot?.docs[0]?.id,
    chatData: snapshot?.docs[0]?.data() as ChatData,
  };
}

export function useChatMessages({ chatId }: { chatId: string }) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "chats", chatId, "messages"),
      orderBy("createdAt", "asc")
    )
  );

  if (error) {
    console.log("useChatMessages", error);
  }

  let chatMessages: ChatMessage[] = [];

  if (snapshot) {
    chatMessages = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...(doc.data() as ChatMessageData),
      };
    });
  }

  return {
    chatMessages,
    loading,
    error,
  };
}

export function useUnreadMessagesCount({ chatId }: { chatId: string }): {
  count: number;
  loading: boolean;
  error: FirestoreError | undefined;
} {
  const { chatMessages, loading, error } = useChatMessages({ chatId });

  if (error) {
    console.log("useUnreadMessagesCount", error);
  }

  const unreadMessagesCount = chatMessages.filter(
    (message) => !message.readerIds.includes(auth.currentUser!.uid)
  ).length;

  return {
    count: unreadMessagesCount,
    loading,
    error,
  };
}
