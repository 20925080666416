import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { useTranslation } from "react-i18next";
import { archiveClient, restoreClient } from "./actions";
import { useCallback, useState } from "react";
import { enqueueSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { ClientStatus } from "../../types";

export default function ToggleClientArchivedButton({
  clientId,
  status,
}: {
  clientId: string;
  status: ClientStatus;
}) {
  const { t } = useTranslation("components");
  const [loading, setLoading] = useState(false);

  const onArchive = useCallback(() => {
    setLoading(true);
    archiveClient({ clientId })
      .then(() => {
        enqueueSnackbar(t("toggle-client-archived-button.archive.success"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId, t]);

  const onRestore = useCallback(() => {
    setLoading(true);
    restoreClient({ clientId })
      .then(() => {
        enqueueSnackbar(t("toggle-client-archived-button.restore.success"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId, t]);

  const isArchived = status === ClientStatus.Archived;

  return (
    <Tooltip
      title={
        isArchived
          ? t("toggle-client-archived-button.restore.title")
          : t("toggle-client-archived-button.archive.title")
      }
    >
      <IconButton
        disabled={loading}
        onClick={isArchived ? onRestore : onArchive}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : isArchived ? (
          <RestoreIcon color="action" />
        ) : (
          <DeleteIcon color="action" />
        )}
      </IconButton>
    </Tooltip>
  );
}
