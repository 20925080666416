import { useState } from "react";
import { SectionList, SectionListItem } from "components/SectionList";
import { usePlans } from "./queries";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
import ErrorScreen from "components/ErrorScreen";

interface Plan {
  id: string;
  name: string;
  description: string;
  monthlyPrice: number;
  annuallyPrice: number;
  clientLimit: number;
}

export default function BillingPlanPage() {
  const [billingCycle, setBillingCycle] = useState("monthly");
  const { data: plans, loading, error } = usePlans();

  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  const handleChange = (_: unknown, newBillingCycle: string) => {
    setBillingCycle(newBillingCycle);
  };

  const sections = [
    {
      component: (
        <Typography variant="h5" align="center">
          Find The Right Plan For
          <br /> Your Personal Training Business
        </Typography>
      ),
    },
    {
      component: (
        <ToggleButtonGroup
          fullWidth
          color="primary"
          value={billingCycle}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="monthly">Monthly</ToggleButton>
          <ToggleButton value="annually">Annually</ToggleButton>
        </ToggleButtonGroup>
      ),
      footerAlign: "center" as const,
      footer:
        billingCycle === "monthly"
          ? "Billed monthly. Cancel anytime."
          : "Billed annually. Save 20%.",
    },
    {
      items: plans,
      dividerVariant: "fullWidth" as const,
      renderItem: (plan: Plan) => (
        <SectionListItem
          to={plan.id}
          primary={plan.name}
          // primaryTypographyProps={{ variant: "h6" }}
          secondary={plan.description}
          action={
            <Box sx={{ pl: 1, minWidth: 120 }}>
              <Typography
                variant="subtitle1"
                color="success.main"
                noWrap
                gutterBottom
              >
                {billingCycle === "monthly"
                  ? `$${plan.monthlyPrice}/mo`
                  : `$${plan.annuallyPrice}/mo`}
              </Typography>

              <Typography
                variant="body2"
                color="textPrimary"
                noWrap
                gutterBottom
              >{`Up to ${plan.clientLimit} clients`}</Typography>
            </Box>
          }
          alignItems="center"
        />
      ),
    },
  ];

  return (
    <>
      <SectionList sections={sections} spacing={2} />

      {/* <button onClick={() => createPlans()}>Create Plans</button> */}
    </>
  );
}
