import Paper from "@mui/material/Paper";

export default function MessageBubble({
  children,
  align,
  backgroundColor,
}: {
  children: React.ReactNode;
  align?: "left" | "right";
  backgroundColor: "primary.dark" | "background.paper";
}) {
  return (
    <Paper
      elevation={1}
      test-id="MessageBubble"
      sx={{
        p: 1,
        borderRadius: 3,
        ml: align === "right" ? "auto" : "48px",
        mr: align === "right" ? "0px" : "auto",
        bgcolor: backgroundColor,
        position: "relative",
        maxWidth: "calc(100% - 48px)",
        boxShadow: "none",
      }}
    >
      {children}
    </Paper>
  );
}
