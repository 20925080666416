import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";

export default function ClientArchived({
  client,
}: {
  client: { displayName: string };
}) {
  const { t } = useTranslation("coach");

  return (
    <Alert sx={{ borderRadius: 0 }} severity="warning">
      {t("screens.client.archived", { displayName: client.displayName })}
    </Alert>
  );
}
