import Box from "@mui/material/Box";
import { plans } from "utils/pricing";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function Pricing() {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        {plans.map((plan) => (
          <Grid size={{ xs: 12, sm: 4 }} key={plan.id}>
            <Card elevation={0}>
              <CardHeader
                title={plan.title}
                subheader={plan.description}
                slotProps={{
                  title: {
                    variant: "h4",
                  },
                  subheader: {
                    variant: "body2",
                  },
                }}
              />
              <CardContent>
                <div>
                  <Typography
                    variant="h5"
                    display="inline"
                    color="textPrimary"
                    sx={{ fontWeight: 700 }}
                  >
                    {plan.clients}
                  </Typography>
                  <Typography
                    variant="body1"
                    display="inline"
                    color="textSecondary"
                  >
                    {" clients"}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="h6"
                    display="inline"
                    color="textPrimary"
                    sx={{ fontWeight: 700 }}
                  >
                    ${plan.price}
                  </Typography>
                  <Typography
                    variant="body1"
                    display="inline"
                    color="textSecondary"
                  >
                    {" / month"}
                  </Typography>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  ${plan.price}/month
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
