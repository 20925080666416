import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import dayjs, { Dayjs } from "utils/dayjs";
import { useCallback } from "react";
import { Link } from "react-router";
import SelectDay from "./SelectDay";
import SelectMonth from "./SelectMonth";
import SelectYear from "./SelectYear";

export default function WorkoutCalendarNavigation({
  view,
  onChangeDate,
  date,
}: {
  view: "month" | "day";
  onChangeDate: (date: Dayjs) => void;
  date: Dayjs;
}) {
  const onChangeDay = useCallback(
    (value: number) => {
      onChangeDate(dayjs(date).set("date", value));
    },
    [date, onChangeDate]
  );

  const onChangeMonth = useCallback(
    (value: number) => {
      onChangeDate(dayjs(date).set("month", value));
    },
    [date, onChangeDate]
  );

  const onChangeYear = useCallback(
    (value: number) => {
      onChangeDate(dayjs(date).set("year", value));
    },
    [date, onChangeDate]
  );

  const formatByView = {
    month: "YYYY-MM",
    day: "YYYY-MM-DD",
  };

  const format = formatByView[view];
  const isToday = dayjs().isSame(date, view);

  return (
    <Paper
      elevation={0}
      square
      sx={{ mb: "1px", py: { xs: 1, sm: 2 }, px: { xs: 2, sm: 3 } }}
    >
      <Grid container spacing={1}>
        {view === "day" && (
          <Grid>
            <SelectDay
              onChange={onChangeDay}
              value={date.date()}
              daysInMonth={date.daysInMonth()}
            />
          </Grid>
        )}
        <Grid>
          <SelectMonth onChange={onChangeMonth} value={date.month()} />
        </Grid>
        <Grid>
          <SelectYear onChange={onChangeYear} value={date.year()} />
        </Grid>
        <Grid size="grow" />
        <Grid>
          <ButtonGroup variant="outlined" size="small">
            <Button
              component={Link}
              to={`?date=${dayjs(date).subtract(1, view).format(format)}`}
            >
              <ChevronLeftIcon />
            </Button>
            <Button
              component={Link}
              to={`?date=${dayjs().format(format)}`}
              disabled={isToday}
            >
              Today
            </Button>
            <Button
              component={Link}
              to={`?date=${dayjs(date).add(1, view).format(format)}`}
            >
              <ChevronRightIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
}
