import { useDocument } from "react-firebase-hooks/firestore";
import { doc, collection, query } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import { useParams } from "react-router";

export function useClientWorkout() {
  const { workoutId } = useParams();
  const [snapshot, loading, error] = useDocument(
    doc(firestore, "users", auth.currentUser!.uid, "workouts", workoutId!)
  );

  if (error) throw error;

  if (loading) return null;

  return {
    id: snapshot!.id,
    ...snapshot!.data(),
  };
}

export function useClientWorkoutExercises() {
  const { workoutId } = useParams();
  const [snapshot, loading, error] = useCollection(
    query(
      collection(
        firestore,
        "users",
        auth.currentUser!.uid,
        "workouts",
        workoutId!,
        "exercises"
      )
    )
  );

  if (error) throw error;

  if (loading) return [];

  return snapshot!.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}
