import { useChat } from "../../queries";
import Box from "@mui/material/Box";
import { ChatType } from "features/chat";
import ErrorScreen from "components/ErrorScreen";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";

export default function Chat({
  type,
  coachId,
  clientId,
  exerciseId,
  workoutId,
  onCloseSidebar,
}: {
  type: ChatType;
  coachId?: string;
  clientId?: string;
  exerciseId?: string;
  workoutId?: string;
  onCloseSidebar: () => void;
}) {
  const { error, loading, empty, chatData, chatId } = useChat({
    type,
    clientId,
    coachId,
    exerciseId,
    workoutId,
  });

  // console.log("Chat", {
  //   chatId,
  //   chatData,
  // });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (empty) return null;
  if (!chatId) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
        userSelect: "none !important" /* disable selection/Copy of UIWebView */,
        touchCallout:
          "none !important" /* disable the IOS popup when long-press on a link */,
      }}
    >
      <ChatHeader
        onClose={onCloseSidebar}
        type={chatData.type}
        memberIds={chatData.memberIds}
      />

      <ChatBody chatId={chatId} chatData={chatData} />
    </Box>
  );
}
