import Box from "@mui/material/Box";
import Container from "components/Container";
import Logo from "components/Logo";
import { Outlet, Navigate } from "react-router";
import { ScrollRestoration } from "react-router";
import { useViewer } from "features/viewer";

const LOGO_SIZE = 80;

export default function AuthLayout() {
  const viewer = useViewer();

  if (viewer) {
    return <Navigate to={"/" + viewer.role} replace />;
  }

  return (
    <Container margin="auto" maxWidth="xs">
      <ScrollRestoration />

      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Logo width={LOGO_SIZE} height={LOGO_SIZE} />
      </Box>

      <Outlet />

      <Box sx={{ width: LOGO_SIZE, height: LOGO_SIZE }} />
    </Container>
  );
}
