import { useInvitation } from "../../queries";
import { useTranslation } from "react-i18next";
import { resendInvitation } from "../../actions";
import { getInvitationLink } from "../../utils";
import { useCallback } from "react";
import copy from "copy-to-clipboard";
import { enqueueSnackbar } from "notistack";
import ErrorScreen from "components/ErrorScreen";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import { InvitationData, InvitationStatus } from "../../types";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";

export default function InvitationDetails({
  invitationId,
}: {
  invitationId: string;
}) {
  const { t } = useTranslation("invitation");

  const [snapshot, loading, error] = useInvitation({ invitationId });

  const invitationLink = getInvitationLink(invitationId);

  const onClickResendInvitation = useCallback(() => {
    resendInvitation(invitationId)
      .then(() => {
        enqueueSnackbar(t("invitationDetails.resendSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("invitationDetails.resendError"), {
          variant: "error",
        });
      });
  }, [invitationId, t]);

  const onClickCopyInvitationLink = useCallback(() => {
    copy(invitationLink);
    enqueueSnackbar(t("invitationDetails.linkCopied"), {
      variant: "success",
    });
  }, [invitationLink, t]);

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;

  const invitationData = snapshot!.data() as InvitationData;

  const primaryTextByStatus: Record<InvitationStatus, string> = {
    [InvitationStatus.Pending]: t("invitationDetails.pending.primary"),
    [InvitationStatus.Accepted]: t("invitationDetails.accepted.primary"),
    [InvitationStatus.Rejected]: t("invitationDetails.rejected.primary"),
  };

  const secondaryTextByStatus: Record<InvitationStatus, string> = {
    [InvitationStatus.Pending]: t("invitationDetails.pending.secondary"),
    [InvitationStatus.Accepted]: t("invitationDetails.accepted.secondary"),
    [InvitationStatus.Rejected]: t("invitationDetails.rejected.secondary"),
  };

  return (
    <Paper square elevation={0} sx={{ px: 2, py: 10 }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid>
          <Avatar
            sx={{ width: 60, height: 60 }}
            src="/flaticon/party.png"
            variant="square"
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h4" color="textPrimary" align="center">
            {primaryTextByStatus[invitationData.status]}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" align="center">
            {secondaryTextByStatus[invitationData.status]}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Button
            // color="inherit"
            variant="contained"
            size="large"
            startIcon={<EmailIcon />}
            onClick={onClickResendInvitation}
            fullWidth
          >
            {t("invitationDetails.resendInvitation")}
          </Button>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Button
            // color="inherit"
            variant="contained"
            size="large"
            startIcon={<ContentCopyIcon />}
            onClick={onClickCopyInvitationLink}
            fullWidth
          >
            {t("invitationDetails.copyLink")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
