import { useMemo } from "react";
import AddIcon from "@mui/icons-material/Add";
import HeaderActionButton from "components/HeaderActionButton";
import { useSearchParams } from "react-router";
import dayjs from "utils/dayjs";
import Button from "@mui/material/Button";
import useIsMobile from "utils/hooks/useIsMobile";
import { Link } from "react-router";

export default function HeaderAddWorkoutButton({
  title,
  edge,
  to = "create",
}: {
  title: string;
  edge?: "start" | "end";
  to: string;
}) {
  const isMobile = useIsMobile("sm");
  const [searchParams] = useSearchParams();
  const date = useMemo(
    () => searchParams.get("date") || dayjs().format("YYYY-MM-DD"),
    [searchParams]
  );

  const href =
    [location.pathname].concat(to.split("/").filter(Boolean)).join("/") +
    "?date=" +
    date;

  if (isMobile) {
    return (
      <HeaderActionButton
        edge={edge}
        icon={<AddIcon />}
        title={title}
        replace
        to={href}
      />
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={href}
      sx={{ ml: 1 }}
    >
      {title}
    </Button>
  );
}
