import { createUpload, UpChunk } from "@mux/upchunk";

type Uploads = Record<
  string,
  {
    error: string | null | unknown;
    progress: number;
    success: boolean;
    upload: UpChunk;
  }
>;

type Listener = (uploads: Uploads) => void;

let uploads: Uploads = {};
let listeners: Listener[] = [];

export const uploadsStore = {
  addUpload(id: string, url: string, file: File) {
    const upload = createUpload({
      endpoint: url,
      file,
    });

    uploads[id] = { error: null, progress: 0, success: false, upload };
    uploads = { ...uploads };

    upload.on("error", (err) => {
      console.log("error", err);
      uploads[id] = {
        ...uploads[id],
        error: err,
      };
      uploads = { ...uploads };

      emitChange();
    });

    upload.on("progress", (progress) => {
      //   console.log("progress", progress.detail);
      uploads[id] = {
        ...uploads[id],
        progress: progress.detail,
      };
      uploads = { ...uploads };

      emitChange();
    });

    upload.on("success", () => {
      //   console.log("success");
      uploads[id] = {
        ...uploads[id],
        success: true,
      };
      uploads = { ...uploads };

      emitChange();
    });

    emitChange();
  },
  subscribe(listener: Listener) {
    listeners = [...listeners, listener];

    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },

  getSnapshot() {
    return uploads;
  },
};

function emitChange() {
  for (const listener of listeners) {
    listener(uploads);
  }
}
