import { useCallback } from "react";
import Toolbar from "@mui/material/Toolbar";
import { useSearchParams } from "react-router";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import HeaderButton from "components/HeaderButton";
import { logEvent } from "utils/analytics";

export default function SearchToolbar() {
  const [searchParams, setSearchParams] = useSearchParams();

  const onBlurSearchField = useCallback(() => {
    if (searchParams.get("search")) return;
    searchParams.delete("search");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onChangeSearchField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      searchParams.set("search", event.target.value);
      logEvent("search", {
        search_term: event.target.value,
      });
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const onClickCloseSearchButton = useCallback(() => {
    searchParams.delete("search");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const onKeyUpSearchField = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Escape") {
        onBlurSearchField();
      }
    },
    [onBlurSearchField]
  );

  const search = searchParams.get("search") || "";

  return (
    <Toolbar>
      <HeaderButton
        edge="start"
        onClick={onClickCloseSearchButton}
        title="Close"
        icon={<CloseIcon />}
      />
      <TextField
        variant="standard"
        fullWidth
        placeholder="Search"
        slotProps={{
          input: {
            disableUnderline: true,
          },
        }}
        sx={{ ml: 1, marginRight: "auto" }}
        autoFocus
        value={search}
        onBlur={onBlurSearchField}
        onChange={onChangeSearchField}
        onKeyUp={onKeyUpSearchField}
      />
    </Toolbar>
  );
}
