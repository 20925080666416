import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router";
import EmailIcon from "@mui/icons-material/Email";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import { useTranslation } from "react-i18next";
import { InvitationStatus, InvitationData } from "../../types";

export default function InvitationNotAcceptedItem({
  invitationId,
  invitationData,
}: {
  invitationId: string;
  invitationData: InvitationData;
}) {
  const { t } = useTranslation("invitation");

  const primaryTextByStatus: Record<InvitationStatus, string> = {
    [InvitationStatus.Pending]: t("invitationListItem.pending.primary"),
    [InvitationStatus.Accepted]: t("invitationListItem.accepted.primary"),
    [InvitationStatus.Rejected]: t("invitationListItem.rejected.primary"),
  };

  const secondaryTextByStatus: Record<InvitationStatus, string> = {
    [InvitationStatus.Pending]: t("invitationListItem.pending.secondary"),
    [InvitationStatus.Accepted]: t("invitationListItem.accepted.secondary"),
    [InvitationStatus.Rejected]: t("invitationListItem.rejected.secondary"),
  };

  const iconByStatus: Record<InvitationStatus, React.ReactNode> = {
    [InvitationStatus.Pending]: <EmailIcon />,
    [InvitationStatus.Accepted]: <MarkEmailReadIcon />,
    [InvitationStatus.Rejected]: <UnsubscribeIcon />,
  };

  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={`invitations/${invitationId}`}>
        <ListItemAvatar>
          <Avatar>{iconByStatus[invitationData.status]}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={primaryTextByStatus[invitationData.status]}
          secondary={secondaryTextByStatus[invitationData.status]}
        />
      </ListItemButton>
    </ListItem>
  );
}
