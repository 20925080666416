import React, { useCallback } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router";
import HeaderSearchButton from "components/HeaderSearchButton";

export default function BaseToolbar({
  back,
  menuButton,
  chatButton,
  title,
  action,
  search,
}: {
  back?: React.ReactNode;
  menuButton?: React.ReactNode;
  chatButton?: React.ReactNode;
  title: string;
  action?: React.ReactNode;
  search?: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onClickSearchButton = useCallback(() => {
    searchParams.set("search", "");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <Toolbar>
      {back || menuButton}
      <Typography
        variant="h6"
        align="center"
        noWrap
        sx={{
          ml: back
            ? 1
            : menuButton
            ? { xs: 1, md: 0 } //
            : 0,
          marginRight: "auto",
        }}
      >
        {title}
      </Typography>
      {search && <HeaderSearchButton onClick={onClickSearchButton} />}
      {action}
      {chatButton}
    </Toolbar>
  );
}
