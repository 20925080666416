import {
  collection,
  query,
  where,
  limit,
  orderBy,
  onSnapshot,
  FirestoreError,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, messaging, auth } from "utils/firebase";
import { useEffect, useState, useCallback } from "react";
import { getToken } from "firebase/messaging";
import {
  PushNotificationStatus,
  Permission,
  type Notification,
  NotificationData,
} from "./types";
import { enableNotifications, disableNotifications } from "./actions";
import { enqueueSnackbar } from "notistack";

export function useNotifications() {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "users", auth.currentUser!.uid, "notifications"),
      // where("isRead", "==", params.isRead),
      orderBy("createdAt", "desc"),
      limit(1000)
    )
  );

  let docs: Notification[] = [];
  if (snapshot?.docs) {
    docs = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...(doc.data() as NotificationData),
    }));
  }

  return { loading, error, data: docs };
}

export function getPushNotificationToken() {
  return getToken(messaging, {
    vapidKey: import.meta.env.VITE_FIREBASE_MESSAGING_VAPID_KEY,
  });
}

export function usePushNotificationStatus() {
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<FirestoreError | Error | null>(null);
  const [status, setStatus] = useState<PushNotificationStatus>(() => {
    switch (Notification.permission) {
      case "denied":
        return PushNotificationStatus.Denied;
      case "default":
        return PushNotificationStatus.Default;
      case "granted":
      default:
        return PushNotificationStatus.Loading;
    }
  });

  useEffect(() => {
    if (status === PushNotificationStatus.Denied) return;
    if (status === PushNotificationStatus.Default) return;
    if (token) return;

    // Enabled = "enabled",
    // Disabled = "disabled",
    // Denied = "denied",
    // Loading = "loading",
    // Error = "error",

    getPushNotificationToken()
      .then(setToken)
      .catch((error) => {
        setError(error);
        setStatus(PushNotificationStatus.Error);
      });
  }, [status, token]);

  useEffect(() => {
    if (!token) return;

    return onSnapshot(
      query(
        collection(
          firestore,
          "users",
          auth.currentUser!.uid,
          "pushNotificationTokens"
        ),
        where("token", "==", token),
        limit(1)
      ),
      (snapshot) => {
        if (snapshot.empty) {
          setStatus(PushNotificationStatus.Disabled);
        } else {
          setStatus(PushNotificationStatus.Enabled);
        }
      },
      (error) => {
        setError(error);
        setStatus(PushNotificationStatus.Error);
      }
    );
  }, [token]);

  const onClick = useCallback(() => {
    if (status === PushNotificationStatus.Denied) return;

    setStatus(PushNotificationStatus.Loading);

    requestPermission()
      .then((permission) => {
        if (permission === "denied") {
          setStatus(PushNotificationStatus.Denied);
          return;
        }

        return getPushNotificationToken().then((token) => {
          if (status === PushNotificationStatus.Enabled) {
            return disableNotifications({ token }).then(() => {
              setStatus(PushNotificationStatus.Disabled);
              enqueueSnackbar("Push notifications disabled", {
                variant: "success",
              });
            });
          } else {
            return enableNotifications({ token }).then(() => {
              setStatus(PushNotificationStatus.Enabled);
              enqueueSnackbar("Push notifications enabled", {
                variant: "success",
              });
            });
          }
        });
      })
      .catch((error) => {
        setError(error);
        setStatus(PushNotificationStatus.Error);
      });
  }, [status]);

  return {
    onClick,
    status,
    error,
  };
}

function requestPermission(): Promise<Permission> {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(resolve);

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  });
}
