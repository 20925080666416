import FormList, { type Schema } from "components/FormList";
import { useParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useClientWorkout } from "./queries";
import { updateClientWorkout } from "./actions";
import dayjs from "utils/dayjs";
import ErrorScreen from "components/ErrorScreen";
import { logEvent } from "utils/analytics";

export default function EditClientWorkoutScreen() {
  const { t } = useTranslation("coach");

  const { clientId, workoutId } = useParams() as {
    clientId: string;
    workoutId: string;
  };

  const { loading, error, data } = useClientWorkout({ clientId, workoutId });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!data) return null;

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.edit-client-workout.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.edit-client-workout.fields.description"),
          multiline: true,
        },
        {
          type: "date",
          name: "date",
          // label: t("screens.create-client-workout.fields.date"),
          required: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.edit-client-workout.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    title: string;
    text?: string;
    date: string;
  }) => {
    await updateClientWorkout({
      ...values,
      clientId,
      workoutId,
      date: dayjs(values.date).format("YYYY-MM-DD"),
    });

    logEvent("client_workout_updated", {
      clientId,
      workoutId,
    });

    enqueueSnackbar(t("screens.edit-client-workout.success"), {
      variant: "success",
    });
  };

  const values = {
    title: data.title,
    description: data.description,
    date: data.date,
  };

  return <FormList schema={schema} values={values} onSubmit={onSubmit} />;
}
