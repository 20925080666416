import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import type { ChatMessage } from "../../../../types";

export default function EditMessagePreview({
  editedChatMessage,
  onClickCancel,
}: {
  editedChatMessage: ChatMessage;
  onClickCancel: () => void;
}) {
  return (
    <Box sx={{ px: 1, pt: 1 }}>
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <IconButton disabled size="large">
          <EditIcon color="primary" />
        </IconButton>
        <Box sx={{ flex: 1, maxWidth: "calc(100% - 48px - 48px - 6px - 6px)" }}>
          <Typography variant="subtitle2" color="primary">
            Editing message
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            {editedChatMessage.text}
          </Typography>
        </Box>
        <Tooltip title="Cancel edit" placement="top">
          <IconButton onClick={onClickCancel} size="large">
            <CancelOutlinedIcon color="action" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
