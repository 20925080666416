import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    // right: -3,
    // top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    // padding: "0 4px",
    height: 16,
    minHeight: 16,
    width: 16,
    minWidth: 16,
    borderRadius: "50%",
    right: "auto",
    left: -10,
    top: 9,
  },
}));

export default function Duration({ duration }: { duration: number }) {
  const minutes = String(Math.floor(duration / 60)).padStart(2, "0");
  const seconds = Number(duration % 60)
    .toFixed(2)
    .padStart(5, "0");

  const invisible = Math.floor(duration) % 2 === 0;

  return (
    <div style={{ paddingLeft: 10 }}>
      <StyledBadge color="primary" variant="dot" invisible={invisible}>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ pl: 3, width: 85 }}
        >
          {minutes}:{seconds}
        </Typography>
      </StyledBadge>
    </div>
  );
}
