import Header from "components/Header";
import Footer from "components/Footer";
import { Outlet } from "react-router";
import { ThemeProvider, extendTheme, Theme } from "@mui/material/styles";
import { theme } from "components/ThemeProvider/theme";

interface ThemeCustom extends Theme {
  colorSchemes: {
    dark: unknown;
  };
}

const darkTheme = extendTheme(theme, {
  colorSchemes: {
    light: (theme as ThemeCustom).colorSchemes.dark,
  },
  palette: {
    mode: "dark",
  },
});

export default function HomePageLayout() {
  return (
    <>
      <ThemeProvider theme={darkTheme} defaultMode="dark" noSsr>
        <Header color="transparent" position="absolute" />
        <Outlet />
      </ThemeProvider>
      <Footer />
    </>
  );
}
