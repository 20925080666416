import Paper from "@mui/material/Paper";
import Container from "components/Container";
import Typography from "@mui/material/Typography";
import { Link } from "react-router";
import MuiLink from "@mui/material/Link";
import { Helmet } from "react-helmet-async";

export default function PrivacyPolicyPage() {
  return (
    <Paper elevation={0}>
      <Container maxWidth="sm">
        <Typography variant="body1" component="div">
          <Helmet>
            <title>Privacy Policy</title>
          </Helmet>

          <h2>Privacy Policy</h2>

          <p>
            Effective Date: <b>1 January 2025</b>
          </p>

          <p>
            At OnlineFitness.app (the “Service”, “we”, “our” or “us”), we are
            committed to protecting your privacy. This Privacy Policy explains
            how we collect, use, disclose, and safeguard your information when
            you use our platform. By accessing or using the Service, you agree
            to the terms of this Privacy Policy.
          </p>

          <h3>1. Information We Collect</h3>

          <p>We collect the following types of information:</p>

          <h4>Personal Information:</h4>
          <ul>
            <li>
              Name, email address, and billing information when you register for
              an account or subscribe to a plan.
            </li>
            <li>Profile details, such as your bio, photo, and preferences.</li>
          </ul>

          <h4>Usage Data:</h4>
          <ul>
            <li>
              Information about how you use the Service, including pages viewed,
              time spent on the platform, and actions taken.
            </li>
          </ul>

          <h4>Device Information:</h4>
          <ul>
            <li>
              Data about the device you use to access the Service, such as IP
              address, browser type, operating system, and unique device
              identifiers.
            </li>
          </ul>

          <h4>Content:</h4>
          <ul>
            <li>
              Videos, training programs, and other materials you upload to the
              Service.
            </li>
          </ul>

          <h3>2. How We Use Your Information</h3>

          <p>We use the information we collect to:</p>

          <ul>
            <li>Provide and maintain the Service.</li>
            <li>Process payments and manage subscriptions.</li>
            <li>Personalize your experience and improve the platform.</li>
            <li>
              Communicate with you about updates, features, and customer
              support.
            </li>
            <li>
              Enforce our{" "}
              <MuiLink component={Link} to="/terms-of-service">
                Terms of Service
              </MuiLink>{" "}
              and comply with legal obligations.
            </li>
          </ul>

          <h3>3. Sharing Your Information</h3>

          <p>We may share your information in the following circumstances:</p>

          <h4>With Service Providers:</h4>
          <ul>
            <li>
              We work with third-party vendors to process payments, host data,
              and provide analytics.
            </li>
          </ul>

          <h4>For Legal Compliance:</h4>
          <ul>
            <li>
              We may disclose your information if required by law or if we
              believe it is necessary to protect our rights or the safety of
              others.
            </li>
          </ul>

          <h4>Business Transfers:</h4>
          <ul>
            <li>
              In the event of a merger, sale, or transfer of our assets, your
              information may be included in the transaction.
            </li>
          </ul>

          <h3>4. Cookies and Tracking Technologies</h3>

          <p>We use cookies and similar technologies to:</p>

          <ul>
            <li>Remember your preferences and login details.</li>
            <li>Analyze platform usage and improve functionality.</li>
            <li>
              You can manage your cookie preferences through your browser
              settings.
            </li>
          </ul>

          <h3>5. Data Security</h3>

          <p>
            We take reasonable measures to protect your data from unauthorized
            access, use, or disclosure. However, no security system is
            foolproof, and we cannot guarantee the absolute security of your
            information.
          </p>

          <h3>6. Your Choices and Rights</h3>

          <h4>Access and Correction:</h4>
          <ul>
            <li>
              You can review and update your personal information through your
              account settings.
            </li>
          </ul>

          <h4>Account Deletion:</h4>
          <ul>
            <li>
              You may request to delete your account and associated data by
              contacting us.
            </li>
          </ul>

          <h4>Marketing Preferences:</h4>
          <ul>
            <li>
              You can opt out of marketing communications by clicking the
              unsubscribe link in our emails.
            </li>
          </ul>

          <h3>7. Third-Party Links</h3>

          <p>
            The Service may contain links to third-party websites. We are not
            responsible for the privacy practices of those websites and
            encourage you to review their policies.
          </p>

          <h3>8. Children’s Privacy</h3>

          <p>
            The Service is not intended for individuals under the age of 18. We
            do not knowingly collect personal information from children. If we
            become aware of such data, we will delete it promptly.
          </p>

          <h3>9. International Users</h3>

          <p>
            If you access the Service from outside Georgia, your information may
            be transferred to and processed in a country with different privacy
            laws. By using the Service, you consent to such transfers.
          </p>

          <h3>10. Changes to This Privacy Policy</h3>

          <p>
            We may update this Privacy Policy periodically. We will notify you
            of any material changes by posting the updated policy on the
            Service. Your continued use of the Service after such changes
            constitutes acceptance of the new terms.
          </p>

          <h3>11. Contact Us</h3>

          <p>
            If you have questions or concerns about this Privacy Policy, please
            contact us at:
          </p>

          <p>
            Email:{" "}
            <MuiLink href="mailto:support@onlinefitness.app">
              support@onlinefitness.app
            </MuiLink>
          </p>

          <p>
            Thank you for trusting OnlineFitness.app. Your privacy is important
            to us.
          </p>
        </Typography>
      </Container>
    </Paper>
  );
}
