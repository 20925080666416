import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HeaderActionButton from "components/HeaderActionButton";
import { useLocation } from "react-router";

export default function HeaderBackButton({
  to = -1,
  edge = "start",
}: {
  to?: string | number;
  edge?: "start" | "end";
}) {
  const location = useLocation();
  let pathname = to;

  if (typeof to === "string") {
    if ([".", "..", "../.."].includes(to)) {
      const url = new URL(to, window.origin + location.pathname);
      pathname = url.pathname.split("/").filter(Boolean).join("/");
    }
  }

  return (
    <HeaderActionButton
      title="Back"
      to={pathname}
      edge={edge}
      icon={<ArrowBackIcon />}
    />
  );
}
