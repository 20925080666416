import api from "utils/api";

export function addExerciseToProgramWorkout({
  programId,
  workoutId,
  exerciseId,
}: {
  programId: string;
  workoutId: string;
  exerciseId: string;
}) {
  return api("addExerciseToProgramWorkout", {
    programId,
    workoutId,
    exerciseId,
  });
}
