import { useState, useCallback } from "react";
import HeaderMenu from "components/HeaderMenu";
import { Outlet } from "react-router";
import LeftSidebar from "components/LeftSidebar";
import RightSidebar from "components/RightSidebar";
import { NotificationsCountInTitle } from "features/notifications";
import Box from "@mui/material/Box";
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "utils/ui";
import { useMenuItems } from "./menu";
import { useViewer } from "features/viewer";
import { Navigate, useSearchParams } from "react-router";

export default function AppLayout() {
  const viewer = useViewer();
  const [searchParams, setSearchParams] = useSearchParams();
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(false);
  const rightSidebarOpen = Boolean(searchParams.has("chat"));
  const menuItems = useMenuItems();

  const toggleLeftSidebar = useCallback((newOpen: boolean) => {
    return () => {
      setLeftSidebarOpen(newOpen);
    };
  }, []);

  const toggleRightSidebar = useCallback(
    (newOpen: boolean) => {
      return () => {
        if (newOpen) {
          searchParams.set("chat", "true");
        } else {
          searchParams.delete("chat");
          searchParams.delete("messageId");
        }
        setSearchParams(searchParams, { replace: true });
      };
    },
    [searchParams, setSearchParams]
  );

  if (!viewer) return <Navigate to="/signin" />;

  return (
    <>
      <HeaderMenu
        toggleLeftSidebar={toggleLeftSidebar}
        toggleRightSidebar={toggleRightSidebar}
      />

      <LeftSidebar
        menuItems={menuItems}
        drawerWidth={LEFT_SIDEBAR_WIDTH}
        open={leftSidebarOpen}
        toggleSidebar={toggleLeftSidebar}
      />

      <Box
        sx={{
          marginLeft: {
            xs: 0,
            md: `${LEFT_SIDEBAR_WIDTH}px`,
          },
          marginRight: {
            xs: 0,
            lg: `${RIGHT_SIDEBAR_WIDTH}px`,
          },
        }}
      >
        <Outlet />
      </Box>

      <RightSidebar
        drawerWidth={RIGHT_SIDEBAR_WIDTH}
        open={rightSidebarOpen}
        toggleSidebar={toggleRightSidebar}
      />

      <NotificationsCountInTitle />
    </>
  );
}
