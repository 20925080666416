import api from "utils/api";

export async function createTeamMember(values: {
  displayName: string;
  email: string;
  teamId: string;
}) {
  const res = await api("createTeamMember", values);

  return res.data as {
    memberId: string;
    invitationCode: string;
  };
}
