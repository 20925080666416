import Drawer from "@mui/material/Drawer";
import useIsMobile from "utils/hooks/useIsMobile";
import { Chat } from "features/chat";
import { useMatches, useParams } from "react-router";
import { ChatType } from "features/chat";
import pick from "utils/pick";

export default function RightSidebar({
  open,
  toggleSidebar,
  drawerWidth,
}: {
  open: boolean;
  toggleSidebar: (open: boolean) => () => void;
  drawerWidth: number;
}) {
  const isMobile = useIsMobile("lg");
  const matches = useMatches();
  const routerParams = useParams();

  const onCloseSidebar = toggleSidebar(false);

  const match = matches[matches.length - 1] as {
    handle?: {
      chat?: {
        type: ChatType;
        params: string[];
      };
    };
  };

  // console.log("match", match);
  const { type, params } = match?.handle?.chat || {};

  const { workoutId, exerciseId, clientId, coachId } = pick(
    routerParams,
    params
  ) as {
    workoutId?: string;
    exerciseId?: string;
    clientId?: string;
    coachId?: string;
  };

  return (
    <Drawer
      open={open || !isMobile}
      onClose={onCloseSidebar}
      elevation={0}
      variant={isMobile ? "temporary" : "permanent"}
      anchor={isMobile ? "bottom" : "right"}
      sx={{
        "& .MuiDrawer-paper": {
          width: isMobile ? "100vw" : drawerWidth,
          height: "100%",
          msOverflowStyle: "none", // IE 10+
          scrollbarWidth: "none", // Firefox
          "&::-webkit-scrollbar": {
            display: "none", // Safari and Chrome
          },
          borderLeft: "1px solid",
          borderColor: "background.default",
        },
      }}
    >
      {type && (
        <Chat
          type={type}
          clientId={clientId}
          workoutId={workoutId}
          exerciseId={exerciseId}
          coachId={coachId}
          onCloseSidebar={onCloseSidebar}
        />
      )}
    </Drawer>
  );
}
