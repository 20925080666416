import Input from "@mui/material/Input";

export default function TextInput({
  onKeyDown,
  onChange,
  onFocus,
  onBlur,
  defaultValue,
}: {
  onKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  defaultValue: string;
}) {
  return (
    <Input
      fullWidth
      placeholder="Write a message..."
      multiline
      maxRows={10}
      disableUnderline
      name="message"
      sx={{ pb: 1.5, pt: 1.5 }}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      defaultValue={defaultValue}
      slotProps={{
        input: {
          inputMode: "text",
        },
      }}
      id="chat-text-input"
    />
  );
}
