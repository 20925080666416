import Stack from "@mui/material/Stack";
import Account from "components/Account";
import { InvitationList } from "features/invitations";
import {
  ClientArchived,
  ToggleClientArchivedButton,
  ClientStatus,
} from "features/clients";
import Menu from "components/Menu";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { purple } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useClient } from "./queries";
import ErrorScreen from "components/ErrorScreen";
import NotFound from "components/NotFound";
import Paper from "@mui/material/Paper";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { useParams } from "react-router";
import { useViewer } from "features/viewer";
import { InvitationStatus } from "features/invitations";

export default function ClientPage() {
  const { t } = useTranslation("coach");
  const viewer = useViewer();
  const { clientId } = useParams() as { clientId: string };
  const { error, loading, clientData } = useClient(clientId);

  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  if (!clientData) {
    return (
      <NotFound
        icon={<NoAccountsIcon sx={{ fontSize: 60 }} />}
        title={t("screens.client.notFound.title")}
        text={t("screens.client.notFound.text")}
      />
    );
  }

  const menu = [
    [
      {
        primary: t("screens.client.menu.workouts"),
        icon: <FitnessCenterIcon color="inherit" />,
        iconColor: purple[500],
        link: {
          to: `workouts`,
        },
      },
    ],
  ];

  const coachId = viewer!.uid;

  return (
    <Stack spacing={0}>
      <Paper elevation={0} square>
        <Account
          title={clientData.displayName}
          subheader={clientData.email}
          avatar={clientData.photoURL}
          action={
            <ToggleClientArchivedButton
              clientId={clientId}
              status={clientData.status}
            />
          }
        />
      </Paper>

      {clientData.status === ClientStatus.Archived && (
        <ClientArchived client={clientData} />
      )}

      <Menu menu={menu} />

      <InvitationList
        coachId={coachId}
        clientId={clientId}
        status={InvitationStatus.Pending}
      />
    </Stack>
  );
}
