import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";

export default function Account({
  action,
  title,
  subheader,
  avatar,
}: {
  action?: React.ReactNode;
  title?: string;
  subheader?: string;
  avatar?: string;
}) {
  return (
    <CardHeader
      avatar={<Avatar src={avatar} />}
      title={title}
      subheader={subheader}
      action={action}
      slotProps={{
        title: {
          variant: "h6",
          noWrap: true,
        },
      }}
      sx={{
        "& .MuiCardHeader-content": {
          overflow: "hidden",
        },
        "& .MuiCardHeader-title": {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        "& .MuiCardHeader-subheader": {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      }}
    />
  );
}
