import api from "utils/api";

export async function updateProgramWorkoutExercise(values: {
  programId: string;
  workoutId: string;
  exerciseId: string;
  title?: string;
  description?: string;
}) {
  await api("updateProgramWorkoutExercise", values);
}

export async function deleteProgramWorkoutExercise({
  programId,
  workoutId,
  exerciseId,
}: {
  programId: string;
  workoutId: string;
  exerciseId: string;
}) {
  await api("deleteProgramWorkoutExercise", {
    programId,
    workoutId,
    exerciseId,
  });
}
