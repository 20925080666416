import Paper from "@mui/material/Paper";
import Container from "components/Container";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";

export default function AboutPage() {
  return (
    <Paper elevation={0}>
      <Container maxWidth="sm">
        <Typography variant="body1" component="div">
          <Helmet>
            <title>About</title>
          </Helmet>

          <h1>About Online Fitness App</h1>

          <p>
            Welcome to OnlineFitness.app – the ultimate platform for personal
            trainers who want to take their fitness business online. Whether
            you're just starting or looking to expand your client base, we've
            got everything you need to succeed in the digital fitness world.
          </p>

          <h2>Why We Built OnlineFitness.app</h2>
          <p>
            As personal trainers ourselves, we know the challenges of juggling
            client schedules, managing workouts, and building a scalable online
            presence. That’s why we created OnlineFitness.app – to give fitness
            professionals a simple, effective tool to manage their business and
            provide a premium experience for their clients.
          </p>

          <h2>What Makes Us Different</h2>
          <p>
            At OnlineFitness.app, we believe every trainer deserves to shine.
            That’s why we empower you with:
          </p>
          <ul>
            <li>
              <b>Your Own Branded App:</b> Stand out with a fully customizable
              app featuring your brand, logo, and style.
            </li>
            <li>
              <b>Simple Client Management:</b> Easily track progress, create
              personalized programs, and stay connected with your clients.
            </li>
            <li>
              <b>Flexible Video Solutions:</b> Upload and organize video
              courses, create chapters, and deliver high-quality workouts.
            </li>
            <li>
              <b>Scalable Pricing:</b> Grow your business with subscription
              plans tailored to the number of clients you train.
            </li>
            <li>
              <b>Global Accessibility:</b> Launch your services anywhere in the
              world with our multilingual and user-friendly platform.
            </li>
          </ul>

          <h2>Our Mission</h2>
          <p>
            We’re here to make the transition to online training seamless, so
            you can focus on what you do best – transforming lives through
            fitness.
          </p>

          <h2>Join Us Today!</h2>
          <p>
            Take the leap into online fitness coaching with OnlineFitness.app.
            Start building your online fitness empire and connect with clients
            like never before.
          </p>

          <p>Let’s get moving!</p>
        </Typography>
      </Container>
    </Paper>
  );
}
