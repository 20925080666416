export enum CoachStatus {
  Active = "active",
  Archived = "archived",
}

export interface Coach extends CoachData {
  id: string;
}

export interface CoachData {
  status: CoachStatus;
  displayName: string;
  email: string;
  photoURL: string;
}
