import { firestore } from "utils/firebase";
import {
  collection,
  doc,
  getDoc,
  where,
  query,
  documentId,
  // getDocs,
} from "firebase/firestore";
import { UserData, User, UserRole } from "./types";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import {
  useMemo,
  // useEffect
} from "react";

/**
 * Кэш для профилей пользователей
 */
const userByIdCache = new Map();

export async function getUserById(userId: string): Promise<User> {
  if (userByIdCache.has(userId)) {
    return userByIdCache.get(userId);
  }

  const userRef = doc(firestore, "users", userId);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    const userData = userSnap.data() as UserData;

    const user: User = {
      id: userId,
      ...userData,
    };

    userByIdCache.set(userId, user);

    return user;
  }

  throw new Error(`User with id ${userId} not found`);
}

export function useUsersById({ userIds }: { userIds: string[] }) {
  // TODO очень странный баг. Почему то useCollection странно работает когда where(documentId(), "in", userIds)
  const [snapshot, loading, error] = useCollectionOnce(
    query(
      collection(firestore, "users"),
      where(documentId(), "in", userIds) //
    )
  );

  const usersById = useMemo(() => {
    if (!snapshot?.docs) return {};

    return snapshot.docs.reduce(
      (acc: Record<string, User>, doc) => {
        const userData = doc.data() as UserData;

        acc[doc.id] = {
          id: doc.id,
          ...userData,
        };

        return acc;
      },
      {
        system: {
          id: "system",
          role: UserRole.System,
          displayName: "AI Assistant",
          photoURL: "/flaticon/bot.png",
        },
      }
    );
  }, [snapshot]);

  return { usersById, loading, error };
}
