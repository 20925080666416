import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { enqueueSnackbar } from "notistack";
import { createClientWorkout } from "./actions";
import dayjs from "utils/dayjs";
import { logEvent } from "utils/analytics";

export default function CreateClientWorkoutPage() {
  const { t } = useTranslation("coach");
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.create-client-workout.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.create-client-workout.fields.description"),
          multiline: true,
        },
        {
          type: "date",
          name: "date",
          // label: t("screens.create-client-workout.fields.date"),
          required: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-client-workout.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    title: string;
    description?: string;
    date: string;
  }) => {
    const workout = await createClientWorkout({
      ...values,
      clientId: clientId!,
      date: dayjs(values.date).format("YYYY-MM-DD"),
    });

    logEvent("client_workout_created", {
      clientId,
      workoutId: workout.id,
    });

    enqueueSnackbar(t("screens.create-client-workout.success"), {
      variant: "success",
    });

    navigate(`../${workout.id}`);
  };

  return <FormList schema={schema} onSubmit={onSubmit} values={{ date }} />;
}
