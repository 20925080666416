import AppLayout from "./(app)/layout";
import appRoutes from "./(app)/routes";

import AuthLayout from "./(auth)/layout";
import authRoutes from "./(auth)/routes";

import WebsiteLayout from "./(website)/layout";
import websiteRoutes from "./(website)/routes";

import InvitationLayout from "./invitation/layout";
import invitationRoutes from "./invitation/routes";

const routes = [
  {
    element: <AppLayout />,
    children: appRoutes,
  },
  {
    element: <AuthLayout />,
    children: authRoutes,
  },
  {
    element: <WebsiteLayout />,
    children: websiteRoutes,
  },
  {
    path: "invitation",
    element: <InvitationLayout />,
    children: invitationRoutes,
  },
];

export default routes;
