import WorkoutsPage from "./page";
import WorkoutPage from "./[workoutId]/page";
import WorkoutExercisePage from "./[workoutId]/exercises/[exerciseId]/page";
import CreateWorkoutExerciseResultPage from "./[workoutId]/exercises/[exerciseId]/results/create/page";
import WorkoutCompletePage from "./[workoutId]/complete/page";

import HeaderBackButton from "components/HeaderBackButton";
import { ChatType } from "features/chat";

const routes = [
  {
    index: true,
    element: <WorkoutsPage />,
    handle: {
      header: {
        title: "client.screens.workouts.title",
      },
    },
  },

  {
    path: ":workoutId",
    children: [
      {
        index: true,
        element: <WorkoutPage />,
        handle: {
          chat: {
            title: "Workout chat",
            type: ChatType.Workout,
            params: ["workoutId"],
          },
          header: {
            title: "Workout",
            back: <HeaderBackButton to="." />,
          },
        },
      },
      {
        path: "exercises",
        children: [
          {
            path: ":exerciseId",
            children: [
              {
                index: true,
                element: <WorkoutExercisePage />,
                handle: {
                  chat: {
                    title: "Exercise chat",
                    type: ChatType.Exercise,
                    params: ["exerciseId"],
                  },
                  header: {
                    title: "Exercise",
                    back: <HeaderBackButton to=".." />,
                  },
                },
              },

              {
                path: "results",
                children: [
                  {
                    path: "create",
                    element: <CreateWorkoutExerciseResultPage />,
                    handle: {
                      chat: {
                        type: ChatType.Exercise,
                        params: ["exerciseId"],
                      },
                      header: {
                        title: "Create result",
                        back: <HeaderBackButton to=".." />,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "complete",
        element: <WorkoutCompletePage />,
        handle: {
          chat: {
            type: ChatType.Workout,
            params: ["workoutId"],
          },
        },
      },
    ],
  },
];

export default routes;
