import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "utils/dayjs";

export default function DateFromNow({ date, sx }: { date: Date; sx?: object }) {
  const title = dayjs.tz(date).format("LLL");
  const time = getTime(date);

  return (
    <Tooltip title={title}>
      <Typography variant="caption" color="textSecondary" sx={sx}>
        {time}
      </Typography>
    </Tooltip>
  );
}

function getTime(date: Date) {
  return dayjs(date).fromNow();
  // const now = ;
  // const diffInMinutes = now.diff(date, "minute");
  // const diffInHours = now.diff(date, "hour");
  // const diffInDays = now.diff(date, "day");
  // const diffInMonths = now.diff(date, "month");
  // const diffInYears = now.diff(date, "year");

  // if (diffInMinutes < 60) return `${diffInMinutes}m`;
  // if (diffInHours < 24) return `${diffInHours}h`;
  // if (diffInDays < 30) return `${diffInDays}d`;
  // if (diffInMonths < 12) return `${diffInMonths}mo`;

  // return `${diffInYears}y`;
}
