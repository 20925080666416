import api from "utils/api";

export async function updateClientWorkout({
  clientId,
  workoutId,
  exerciseIds,
}: {
  clientId: string;
  workoutId: string;
  exerciseIds: string[];
}) {
  await api("updateClientWorkout", {
    clientId,
    workoutId,
    exerciseIds,
  });
}
