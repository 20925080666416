import SignIn from "./signin/page";
import SignUp from "./signup/page";
import ForgotPassword from "./forgot-password/page";

const routes = [
  {
    path: "signup",
    element: <SignUp />,
  },
  {
    path: "signin",
    element: <SignIn />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
];

export default routes;
