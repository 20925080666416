import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

export default function HeaderButton({
  title = "",
  icon,
  ...props
}: {
  title?: string;
  icon: React.ReactNode;
  [key: string]: unknown;
}) {
  return (
    <Tooltip title={title}>
      <span>
        <IconButton size="large" color="default" {...props}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}
