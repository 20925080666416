import FormList, { type Schema } from "components/FormList";
import { useParams, useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useProgram } from "./queries";
import { updateProgram } from "./actions";

export default function EditProgramScreen() {
  const { t } = useTranslation("coach");
  const { programId } = useParams();
  const program = useProgram();
  const navigate = useNavigate();

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.edit-program.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.edit-program.fields.description"),
          multiline: true,
        },
      ],
    },
    {
      square: true,
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.edit-program.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: { title: string; description?: string }) => {
    await updateProgram({ programId: programId!, ...values });
    enqueueSnackbar(t("screens.edit-program.success"), {
      variant: "success",
    });
    navigate(-1);
  };

  if (!program) return null;

  const values = {
    title: program.title,
    description: program.description,
  };

  return (
    <FormList schema={schema} values={values} onSubmit={onSubmit} spacing={0} />
  );
}
