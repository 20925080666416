import FormList, { type Schema } from "components/FormList";
import copy from "copy-to-clipboard";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { createClient } from "./actions";
import Link from "@mui/material/Link";
import { logEvent } from "utils/analytics";
import { getInvitationLink } from "features/invitations";

interface ClientCreated {
  clientId: string;
  displayName: string;
  email: string;
  invitationLink: string;
}

export default function CreateClientPage() {
  const [createdClient, setCreatedClient] = useState<ClientCreated | null>(
    null
  );

  if (createdClient) {
    return <ClientCreated createdClient={createdClient} />;
  }

  return <CreateClientForm setCreatedClient={setCreatedClient} />;
}

function CreateClientForm({
  setCreatedClient,
}: {
  setCreatedClient: (client: ClientCreated) => void;
}) {
  const { t } = useTranslation("coach");

  const schema: Schema = [
    {
      // footer: t("screens.create-client.footer"),
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "displayName",
          placeholder: t("screens.create-client.displayName"),
          required: true,
        },
        {
          type: "email",
          name: "email",
          placeholder: t("screens.create-client.email"),
          required: true,
          lowercase: true,
          trim: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-client.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: { displayName: string; email: string }) => {
    const { displayName, email } = values;

    const { clientId, invitationCode } = await createClient({
      email,
      displayName,
    });

    logEvent("client_created", {
      clientId,
    });

    setCreatedClient({
      clientId,
      displayName,
      email,
      invitationLink: getInvitationLink(invitationCode),
    });
  };

  return <FormList schema={schema} onSubmit={onSubmit} />;
}

function ClientCreated({ createdClient }: { createdClient: ClientCreated }) {
  const { t } = useTranslation("coach");

  const { clientId, displayName, email, invitationLink } = createdClient;

  const schema: Schema = [
    {
      square: true,
      fields: [
        {
          type: "typography",
          variant: "h6",
          color: "textPrimary",
          text: t("screens.create-client.invitation-sent.header", {
            displayName,
            email,
          }),
        },
        {
          type: "typography",
          variant: "body1",
          color: "textSecondary",
          text: (
            <>
              {t("screens.create-client.invitation-sent.footer1", {
                displayName,
                email,
              })}{" "}
              <Link href={`mailto:${email}`} underline="hover">
                {email}
              </Link>
              {". "}
            </>
          ),
        },
        {
          type: "typography",
          variant: "body1",
          color: "textSecondary",
          text: (
            <>
              {t("screens.create-client.invitation-sent.footer2", {
                displayName,
                email,
              })}
            </>
          ),
        },
      ],
    },
    {
      square: true,
      footer: t("screens.create-client.invitation-sent.helperText"),
      fields: [
        {
          type: "text",
          name: "invitationLink",
          onClick: (event: React.MouseEvent<HTMLInputElement>) => {
            (event.target as HTMLInputElement).select();
            copy(invitationLink);
            enqueueSnackbar(
              t("screens.create-client.invitation-sent.linkCopied"),
              {
                variant: "success",
              }
            );
          },
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "button",
          size: "large",
          label: t("screens.create-client.invitation-sent.startCoaching", {
            displayName,
          }),
          color: "primary",
          variant: "contained",
          position: "fixed",
          link: {
            to: `../${clientId}`,
            replace: true,
          },
        },
      ],
    },
  ];

  const values = {
    invitationLink,
  };

  return <FormList schema={schema} values={values} spacing={1} />;
}
