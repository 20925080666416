import HeaderAddButton from "components/HeaderAddButton";
import HeaderBackButton from "components/HeaderBackButton";
import HeaderDeleteButton from "components/HeaderDeleteButton";
import HeaderEditButton from "components/HeaderEditButton";
import HeaderAddWorkoutButton from "components/HeaderAddWorkoutButton";

import CreateClientPage from "./create/page";
import ClientsPage from "./page";
import ClientPage from "./[clientId]/page";

import ClientInvitationPage from "./[clientId]/invitations/[invitationId]/page";

import ClientWorkoutsPage from "./[clientId]/workouts/page";
import ClientWorkoutPage from "./[clientId]/workouts/[workoutId]/page";
import CreateClientWorkoutPage from "./[clientId]/workouts/create/page";
import EditClientWorkoutPage from "./[clientId]/workouts/[workoutId]/edit/page";
import { deleteClientWorkout } from "./[clientId]/workouts/[workoutId]/edit/actions";

import AddClientWorkoutExercisePage from "./[clientId]/workouts/[workoutId]/exercises/add/page";
import EditClientWorkoutExercisePage from "./[clientId]/workouts/[workoutId]/exercises/[exerciseId]/edit/page";
import ClientWorkoutExercisePage from "./[clientId]/workouts/[workoutId]/exercises/[exerciseId]/page";
import { deleteClientWorkoutExercise } from "./[clientId]/workouts/[workoutId]/exercises/[exerciseId]/edit/actions";

import { ChatType } from "features/chat";

const routes = [
  {
    index: true,
    element: <ClientsPage />,
    handle: {
      header: {
        title: "coach.screens.clients.title",
        action: <HeaderAddButton title="Add client" to="create" />,
        search: true,
      },
    },
  },
  {
    path: "create",
    element: <CreateClientPage />,
    handle: {
      header: {
        title: "coach.screens.create-client.title",
        back: <HeaderBackButton />,
      },
    },
  },
  {
    path: ":clientId",
    children: [
      {
        index: true,
        element: <ClientPage />,
        handle: {
          chat: {
            type: ChatType.Client,
            params: ["clientId"],
          },
          header: {
            title: "coach.screens.client.title",
            back: <HeaderBackButton to="." />,
          },
        },
      },
      {
        path: "workouts",
        children: [
          {
            index: true,
            element: <ClientWorkoutsPage />,
            handle: {
              header: {
                title: "coach.screens.client-workouts.title",
                back: <HeaderBackButton to="." />,
                action: [
                  <HeaderAddWorkoutButton
                    title="Create workout"
                    key="add"
                    edge="end"
                    to="create"
                  />,
                ],
              },
            },
          },
          {
            path: "create",
            element: <CreateClientWorkoutPage />,
            handle: {
              header: {
                title: "coach.screens.create-client-workout.title",
                back: <HeaderBackButton />,
              },
            },
          },

          {
            path: ":workoutId",
            children: [
              {
                index: true,
                element: <ClientWorkoutPage />,
                handle: {
                  chat: {
                    type: ChatType.Workout,
                    params: ["clientId", "workoutId"],
                  },
                  header: {
                    title: "coach.screens.client-workout.title",
                    back: <HeaderBackButton to="." />,
                    action: [
                      <HeaderEditButton title="Edit workout" key="edit" />,
                      <HeaderAddButton
                        key="add"
                        title="Add exercise"
                        to="exercises/add"
                      />,
                    ],
                  },
                },
              },
              {
                path: "edit",
                element: <EditClientWorkoutPage />,
                handle: {
                  header: {
                    title: "coach.screens.edit-client-workout.title",
                    back: <HeaderBackButton />,
                    action: (
                      <HeaderDeleteButton
                        key="delete"
                        title="Delete workout"
                        action={deleteClientWorkout}
                        success="Workout deleted"
                        redirectTo=".."
                      />
                    ),
                  },
                },
              },
              {
                path: "exercises",
                children: [
                  {
                    path: "add",
                    element: <AddClientWorkoutExercisePage />,
                    handle: {
                      header: {
                        search: true,
                        title:
                          "coach.screens.add-client-workout-exercise.title",
                        back: <HeaderBackButton to=".." />,
                      },
                    },
                  },

                  {
                    path: ":exerciseId",
                    children: [
                      {
                        index: true,
                        element: <ClientWorkoutExercisePage />,
                        handle: {
                          chat: {
                            type: ChatType.Exercise,
                            params: ["clientId", "exerciseId"],
                          },
                          header: {
                            title:
                              "coach.screens.client-workout-exercise.title",
                            back: <HeaderBackButton />,
                            action: <HeaderEditButton title="Edit exercise" />,
                          },
                        },
                      },
                      {
                        path: "edit",
                        element: <EditClientWorkoutExercisePage />,
                        handle: {
                          header: {
                            title:
                              "coach.screens.edit-client-workout-exercise.title",
                            back: <HeaderBackButton />,
                            action: (
                              <HeaderDeleteButton
                                title="Delete exercise"
                                success="Exercise deleted"
                                action={deleteClientWorkoutExercise}
                                redirectTo="../.."
                              />
                            ),
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "invitations",
        children: [
          {
            path: ":invitationId",
            element: <ClientInvitationPage />,
            handle: {
              header: {
                title: "coach.screens.client-invitation.title",
                back: <HeaderBackButton to=".." />,
              },
            },
          },
        ],
      },
    ],
  },
];

export default routes;
