import { useColorScheme, PaletteMode } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import FormList, { type Schema } from "components/FormList";

export default function ThemeScreen() {
  const { t } = useTranslation("core");
  const { mode, setMode } = useColorScheme();

  const schema: Schema = [
    {
      square: true,
      fields: [
        {
          type: "radio",
          name: "mode",
          onChange: (value: string) => setMode(value as PaletteMode),
          options: [
            { value: "dark", label: t("screens.theme.dark") },
            { value: "light", label: t("screens.theme.light") },
            { value: "system", label: t("screens.theme.system") },
          ],
        },
      ],
    },
  ];

  const values = {
    mode,
  };

  return <FormList schema={schema} values={values} />;
}
