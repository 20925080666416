import Container from "components/Container";
import { Outlet } from "react-router";
import { ScrollRestoration } from "react-router";

export default function InvitationLayout() {
  return (
    <Container margin="auto" maxWidth="xs">
      <ScrollRestoration />

      <Outlet />
    </Container>
  );
}
