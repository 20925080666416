import api from "utils/api";

export async function createProgramWorkout(values: {
  programId: string;
  title: string;
  description?: string;
  day: number;
}) {
  const res = await api("createProgramWorkout", values);

  return res.data as {
    id: string;
  };
}
