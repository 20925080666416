import { createSystemChatMessage } from "./actions";
import { SystemChatMessageType } from "./types";
import { useEffect } from "react";

export function useCreateSystemChatMessage(type: SystemChatMessageType) {
  const key = `systemChatMessageCreated-${type}`;
  useEffect(() => {
    if (localStorage.getItem(key)) return;
    createSystemChatMessage({ type }).catch(console.error);
    localStorage.setItem(key, "true");
  }, [type, key]);
}
