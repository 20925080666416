import FormList, { type Schema } from "components/FormList";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";
import { useOutletContext, Link } from "react-router";
import {
  InvitationData,
  InvitationStatus,
  acceptInvitation,
} from "features/invitations";
import { useUsersById } from "features/user";
import ErrorScreen from "components/ErrorScreen";
import Logo from "components/Logo";
import Button from "@mui/material/Button";
import { useViewer } from "features/viewer";
import { logEvent } from "utils/analytics";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";

export default function InvitationPage() {
  const { t } = useTranslation("invitation");
  const viewer = useViewer();
  const navigate = useNavigate();

  const { invitationId, invitationData } = useOutletContext<{
    invitationId: string;
    invitationData: InvitationData;
  }>();

  const { usersById, loading, error } = useUsersById({
    userIds: [invitationData.coachId, invitationData.clientId],
  });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;

  if (invitationData.status === InvitationStatus.Accepted) {
    return (
      <ErrorScreen
        icon={<Logo width={60} height={60} />}
        title={t("screens.alreadyAccepted.title")}
        text={t("screens.alreadyAccepted.text")}
        button={
          <Button component={Link} to="/signin" variant="contained">
            {t("screens.alreadyAccepted.button")}
          </Button>
        }
      />
    );
  }

  const coach = usersById[invitationData.coachId];
  const client = usersById[invitationData.clientId];

  const schema: Schema = [
    {
      // backgroundColor: "transparent",
      fields: [
        {
          type: "typography",
          text: t("screens.invitation.hi", {
            displayName: client.displayName,
          }),
          color: "textPrimary",
          variant: "body1",
        },
        {
          type: "typography",
          text: t("screens.invitation.text"),
          color: "textPrimary",
          variant: "body1",
        },
        {
          type: "item",
          primary: coach.displayName,
          secondary: t("screens.invitation.your-coach"),
          avatar: <Avatar src={coach.photoURL} />,
        },
      ],
    },
  ];

  if (viewer?.uid === invitationData.clientId) {
    const onClickAcceptInvitation = () => {
      acceptInvitation({ invitationId })
        .then(() => {
          logEvent("accept_invitation", {
            invitationId,
          });

          enqueueSnackbar(t("screens.invitation.accepted"), {
            variant: "success",
          });

          navigate("/client/coaches", { replace: true });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    };

    schema.push({
      backgroundColor: "transparent",
      fields: [
        {
          type: "button",
          label: "Accept invitation",
          color: "primary",
          variant: "contained",
          onClick: onClickAcceptInvitation,
          size: "large",
        },
      ],
    });
  } else {
    schema.push({
      backgroundColor: "transparent",
      fields: [
        {
          type: "button",
          label: "Continue",
          color: "primary",
          variant: "contained",
          link: { to: "account" },
          size: "large",
        },
      ],
    });
  }

  return <FormList schema={schema} />;
}
