import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";

export default function SectionListItemThumbnail({
  src,
  width = 40,
  height = 40,
}: {
  src?: string | null | undefined;
  width?: number;
  height?: number;
}) {
  if (src) {
    return <Avatar variant="rounded" src={src} sx={{ width, height }} />;
  }

  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={width}
      height={height}
    />
  );
}
