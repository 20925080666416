import AddIcon from "@mui/icons-material/Add";
import HeaderButton from "components/HeaderButton";
import { useRef } from "react";
import { createVideoUpload, createExercise } from "./actions";
import { uploadsStore } from "utils/upload-video";
import Button from "@mui/material/Button";
import useIsMobile from "utils/hooks/useIsMobile";
import { logEvent } from "utils/analytics";

export default function CreateVideoUploadButton({
  edge,
  title,
}: {
  edge?: "start" | "end";
  title: string;
}) {
  const isMobile = useIsMobile("sm");
  const inputRef = useRef<HTMLInputElement>(null!);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    Array.from(event.target.files).forEach(async (file) => {
      const exercise = await createExercise({
        title: file.name,
      });

      const url = await createVideoUpload({
        exerciseId: exercise.id,
      });

      uploadsStore.addUpload(exercise.id, url, file);
    });

    logEvent("create_video_upload", {
      count: event.target.files.length,
    });
  };

  const onClickButton = () => {
    inputRef.current.click();
    inputRef.current.value = "";
  };

  return (
    <>
      <input
        type="file"
        multiple
        accept="video/*"
        onChange={onChange}
        style={{ display: "none" }}
        ref={inputRef}
      />
      {isMobile ? (
        <HeaderButton
          edge={edge}
          icon={<AddIcon />}
          title={title}
          onClick={onClickButton}
        />
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={onClickButton}
          size="large"
          sx={{ ml: 1 }}
        >
          {title}
        </Button>
      )}
    </>
  );
}
