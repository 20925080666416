import Typography from "@mui/material/Typography";

export default function MessageCreator({
  children,
  color,
}: {
  children: React.ReactNode;
  color: string;
}) {
  return (
    <Typography test-id="MessageCreator" variant="subtitle2" color={color}>
      {children}
    </Typography>
  );
}
