import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { firestore, auth } from "utils/firebase";
import { User } from "features/user";

export function useInvitedCoaches() {
  const [users, setItems] = useState<User[]>([]);

  useEffect(() => {
    const q = query(
      collection(firestore, "users"),
      where("role", "==", "coach"),
      where("invitedBy", "==", auth.currentUser!.uid),
      orderBy("createdAt", "desc")
    );

    return onSnapshot(q, (querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        } as User;
      });

      setItems(users);
    });
  }, []);

  return users;
}
