import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "utils/firebase";
import type { ProgramWorkoutExerciseData } from "features/programs";

export function useProgramWorkoutExercise({
  programId,
  workoutId,
  exerciseId,
}: {
  programId: string;
  workoutId: string;
  exerciseId: string;
}) {
  const [value, loading, error] = useDocumentDataOnce(
    doc(
      firestore,
      "programs",
      programId,
      "workouts",
      workoutId,
      "exercises",
      exerciseId
    )
  );

  return {
    data: value as ProgramWorkoutExerciseData | undefined,
    loading,
    error,
  };
}
