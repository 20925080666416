import { Helmet } from "react-helmet-async";
import { useNotifications } from "../queries";
import { useMemo } from "react";

export default function NotificationsCountInTitle() {
  const { data } = useNotifications();

  const unreadNotificationsCount = useMemo(
    () => data.filter((n) => !n.isRead).length,
    [data]
  );

  if (unreadNotificationsCount === 0) return null;

  return (
    <Helmet
      titleTemplate={`(${unreadNotificationsCount}) %s | OnlineFitness.app`}
    />
  );
}
