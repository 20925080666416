import { Exercise, ExerciseData } from "features/exercises";
import { collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";

export function useExercises(search?: string) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "exercises"),
      where("video.status", "==", "ready"),
      where("creatorId", "==", auth.currentUser!.uid),
      orderBy("createdAt", "desc")
    )
  );

  let data = [] as Exercise[];

  if (snapshot?.docs) {
    data = snapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...(doc.data() as ExerciseData),
      }))
      .filter((exercise: { id: string; title?: string }) => {
        if (!search) return true;

        return search
          .toLowerCase()
          .split(" ")
          .every((word) => {
            return exercise.title?.toLowerCase().includes(word.trim());
          });
      });
  }

  return {
    loading,
    error,
    data,
  };
}
