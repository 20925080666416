import { useTranslation } from "react-i18next";
import PeopleIcon from "@mui/icons-material/People";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SettingsIcon from "@mui/icons-material/Settings";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SportsIcon from "@mui/icons-material/Sports";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import {
  purple,
  pink,
  red,
  blue,
  green,
  deepOrange,
} from "@mui/material/colors";
import { isStandalone } from "utils/env";
import { useViewer, ViewerRole, Viewer } from "features/viewer";
import { useMemo } from "react";
import { NotificationsIconButton } from "features/notifications";
import NotificationsIcon from "@mui/icons-material/Notifications";

function getMenuItemsForClient(viewer: Viewer, t: (key: string) => string) {
  const menuItems = [
    [
      {
        avatar: viewer.photoURL,
        primary: viewer.displayName,
        secondary: viewer.email,
        link: { to: "/settings/profile" },
      },
    ],
    [
      {
        primary: t("screens.menu.notifications"),
        icon: <NotificationsIcon color="inherit" />,
        iconColor: red[500],
        link: { to: "/notifications" },
        rightIcon: <NotificationsIconButton />,
      },
      {
        primary: t("screens.menu.workouts"),
        icon: <FitnessCenterIcon color="inherit" />,
        iconColor: purple[500],
        link: { to: "/client/workouts" },
      },
      {
        primary: t("screens.menu.coaches"),
        icon: <SportsIcon color="inherit" />,
        iconColor: deepOrange[500],
        link: { to: "/client/coaches" },
      },
      {
        primary: t("screens.menu.settings"),
        icon: <SettingsIcon color="inherit" />,
        iconColor: green[500],
        link: { to: "/settings" },
      },
      {
        primary: t("screens.menu.install"),
        icon: <InstallMobileIcon color="inherit" />,
        iconColor: blue[500],
        link: { to: "/install" },
        disabled: isStandalone,
      },
    ],
  ];

  return menuItems;
}

function getMenuItemsForCoach(viewer: Viewer, t: (key: string) => string) {
  const menuItems = [
    [
      {
        avatar: viewer.photoURL,
        primary: viewer.displayName,
        secondary: viewer.email,
        link: { to: "/settings/profile" },
      },
    ],
    [
      {
        primary: t("screens.menu.notifications"),
        icon: <NotificationsIcon color="inherit" />,
        iconColor: red[500],
        link: { to: "/notifications" },
        rightIcon: <NotificationsIconButton />,
      },
      {
        primary: t("screens.menu.clients"),
        icon: <PeopleIcon color="inherit" />,
        iconColor: pink[500],
        link: { to: "/coach/clients" },
      },
      {
        primary: t("screens.menu.exercises"),
        icon: <VideoLibraryIcon color="inherit" />,
        iconColor: purple[500],
        link: { to: "/coach/exercises" },
      },
      // {
      //   primary: t("screens.menu.programs"),
      //   icon: <FitnessCenterIcon color="inherit" />,
      //   iconColor: indigo[500],
      //   link: { to: "/coach/programs" },
      // },
      // {
      //   primary: t("screens.menu.billing"),
      //   icon: <AttachMoneyIcon color="inherit" />,
      //   iconColor: deepOrange[500],
      //   link: { to: "/coach/billing" },
      // },
      // {
      //   primary: t("screens.menu.refferal"),
      //   icon: <FitnessCenterIcon color="inherit" />,
      //   iconColor: indigo[500],
      //   link: { to: "/coach/refferal" },
      // },
      // {
      //   primary: t("screens.menu.teams"),
      //   icon: <FitnessCenterIcon color="inherit" />,
      //   iconColor: indigo[500],
      //   link: { to: "/coach/teams" },
      // },
      {
        primary: t("screens.menu.settings"),
        icon: <SettingsIcon color="inherit" />,
        iconColor: green[500],
        link: { to: "/settings" },
      },
      {
        primary: t("screens.menu.install"),
        icon: <InstallMobileIcon color="inherit" />,
        iconColor: blue[500],
        link: { to: "/install" },
        disabled: isStandalone,
      },
    ],
  ];

  return menuItems;
}

const menuItemsByRole = {
  [ViewerRole.Client]: getMenuItemsForClient,
  [ViewerRole.Coach]: getMenuItemsForCoach,
};

export function useMenuItems() {
  const { t } = useTranslation("components");
  const viewer = useViewer();

  return useMemo(() => {
    if (!viewer) return [];

    return menuItemsByRole[viewer.role](viewer, t);
  }, [viewer, t]);
}
