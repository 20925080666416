import { Outlet } from "react-router";
import { Navigate } from "react-router";
import { useViewer } from "features/viewer";

export default function SettingsLayout() {
  const viewer = useViewer();

  if (!viewer) {
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
}
