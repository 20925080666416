import AddIcon from "@mui/icons-material/Add";
import HeaderActionButton from "components/HeaderActionButton";
import { useLocation } from "react-router";
import Button from "@mui/material/Button";
import useIsMobile from "utils/hooks/useIsMobile";
import { Link } from "react-router";

export default function HeaderAddButton({
  title,
  edge,
  to = "/create",
}: {
  title: string;
  edge?: "start" | "end";
  to: string;
}) {
  const location = useLocation();
  const isMobile = useIsMobile("sm");

  const href = [location.pathname]
    .concat(to.split("/").filter(Boolean))
    .join("/");

  if (isMobile) {
    return (
      <HeaderActionButton
        edge={edge}
        icon={<AddIcon />}
        title={title}
        to={href}
      />
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={href}
      size="large"
      sx={{ ml: 1 }}
    >
      {title}
    </Button>
  );
}
