import Fab from "@mui/material/Fab";
// import MicNoneIcon from "@mui/icons-material/MicNone";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function SendMicButton(
  props: React.ComponentPropsWithoutRef<typeof Fab>
) {
  return (
    <div>
      <Fab size="medium" color="primary" {...props}>
        <ArrowUpwardIcon />
      </Fab>
    </div>
  );
}
