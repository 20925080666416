import { collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import type { Exercise } from "features/exercises";

export function useExercises({ search }: { search?: string }) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "exercises"),
      where("creatorId", "==", auth.currentUser!.uid),
      orderBy("createdAt", "desc")
    )
  );

  let data: Exercise[] = [];

  if (snapshot?.docs) {
    data = snapshot.docs
      .map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Exercise)
      )
      .filter((exercise: Exercise) => {
        if (!search) return true;

        return search
          .toLowerCase()
          .split(" ")
          .every((word) => {
            return exercise?.title?.toLowerCase().includes(word.trim());
          });
      });
  }

  return { loading, error, data };
}
