import { storage } from "utils/firebase";
import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { useRef, useState, useEffect, useCallback } from "react";
import { AudioVisualizer } from "react-audio-visualize";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

export default function ChatMessageAudio({ audio }: { audio: string }) {
  const [
    value,
    // loading,
    // error
  ] = useDownloadURL(ref(storage, audio));
  const [blob, setBlob] = useState<Blob>();
  const visualizerRef = useRef<HTMLCanvasElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (!value) return;

    fetch(value)
      .then((response) => response.blob())
      .then(setBlob)
      .catch(console.error);
  }, [value]);

  const onClickPlay = useCallback(() => {
    audioRef.current?.play();
    setPlaying(true);
  }, []);

  const onClickPause = useCallback(() => {
    audioRef.current?.pause();
    setPlaying(false);
  }, []);

  if (!value) return null;

  return (
    <>
      <Stack direction="row" alignItems="center">
        {playing ? (
          <IconButton size="large" onClick={onClickPause}>
            <PauseIcon />
          </IconButton>
        ) : (
          <IconButton size="large" onClick={onClickPlay}>
            <PlayArrowIcon />
          </IconButton>
        )}
        {blob && (
          <Box>
            <Box sx={{ width: 300, height: 48, overflow: "hidden" }}>
              <AudioVisualizer
                ref={visualizerRef}
                blob={blob}
                width={300}
                height={48}
                barWidth={2}
                gap={1}
                barColor={theme.palette.text.secondary}
                barPlayedColor={theme.palette.text.primary}
                currentTime={currentTime}
              />
            </Box>
            {currentTime}
          </Box>
        )}
      </Stack>
      <audio
        ref={audioRef}
        src={value}
        controls
        preload="auto"
        style={{ width: "100%" }}
        onTimeUpdate={(e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
          if ("currentTime" in e.target) {
            setCurrentTime(e.target.currentTime as number);
          }
        }}
      />
    </>
  );
}
