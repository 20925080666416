import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import type { Program, ProgramWorkout } from "features/programs";

export function useProgram({ programId }: { programId: string }) {
  const [value, loading, error] = useDocumentData(
    doc(firestore, "programs", programId)
  );

  return {
    data: value as Program | null,
    loading,
    error,
  };
}

export function useProgramWorkouts({ programId }: { programId: string }) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "programs", programId, "workouts"),
      where("creatorId", "==", auth.currentUser!.uid), // TODO ой это не очень красиво
      orderBy("day", "asc")
    )
  );

  let docs: ProgramWorkout[] = [];

  if (snapshot?.docs) {
    docs = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as ProgramWorkout[];
  }

  return {
    data: docs,
    loading,
    error,
  };
}
