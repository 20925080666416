import { useClientWorkoutExercise } from "./queries";
import {
  SectionList,
  SectionListItem,
  type Section,
} from "components/SectionList";
import Player from "components/Player";
import formatTextToHtml from "utils/formatTextToHtml";
import BottomButton from "components/BottomButton";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ErrorScreen from "components/ErrorScreen";
import NotFound from "components/NotFound";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { ClientWorkoutExercise } from "features/exercises";
import DoneIconButton from "components/DoneIconButton";
import { updateWorkoutExercise } from "./actions";

export default function WorkoutExercisePage() {
  const { t } = useTranslation("client");

  const { workoutId, exerciseId } = useParams() as {
    workoutId: string;
    exerciseId: string;
  };

  const { loading, error, data } = useClientWorkoutExercise({
    workoutId,
    exerciseId,
  });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!data) {
    return (
      <NotFound
        icon={<VideoLibraryIcon sx={{ fontSize: 60 }} />}
        title={t("screens.workout-exercise.not-found.title")}
        text={t("screens.workout-exercise.not-found.text")}
      />
    );
  }

  const sections: Section<ClientWorkoutExercise>[] = [
    {
      square: true,
      items: [data],
      renderItem: (item) => (
        <SectionListItem
          primary={item.title}
          slotProps={{
            primary: {
              variant: "h6",
              gutterBottom: !!item.description,
            },
          }}
          secondary={
            item.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: formatTextToHtml(item.description),
                }}
              />
            )
          }
          alignItems="flex-start"
          action={
            item.completed && (
              <DoneIconButton
                done={item.completed}
                onClick={() => {
                  updateWorkoutExercise({
                    workoutId,
                    exerciseId,
                    completed: !item.completed,
                  });
                }}
                title={
                  item.completed
                    ? t("screens.workout-exercise.markAsUncompleted")
                    : t("screens.workout-exercise.markAsCompleted")
                }
              />
            )
          }
        />
      ),
    },
  ];

  if (!data.completed) {
    sections.push({
      component: (
        <BottomButton
          variant="contained"
          color="primary"
          size="large"
          to="results/create"
        >
          {t("screens.workout-exercise.add-result")}
        </BottomButton>
      ),
    });
  }

  return (
    <>
      <Player
        video={data.video}
        metadataVideoId={exerciseId}
        metadataVideoTitle={data.title}
        loop
      />
      <SectionList sections={sections} spacing={0} />
    </>
  );
}
