import Paper from "@mui/material/Paper";
import { Section } from "./types";
import { Reorder } from "framer-motion";
import React, { useState, useEffect, useCallback } from "react";

export default function SectionListItemsSortable<Item extends { id: string }>({
  section,
  renderItem,
}: {
  section: Section<Item>;
  renderItem: (item: Item, index: number) => React.ReactNode;
}) {
  const groupRef = React.useRef<HTMLDivElement>(null);
  const [itemIds, setItemIds] = useState(() =>
    section.items!.map((item) => item.id)
  );

  useEffect(() => {
    if (section.items!.length === itemIds.length) return;
    setItemIds(section.items!.map((item) => item.id));
  }, [section.items, itemIds]);

  const onReorder = useCallback(
    (itemIds: string[]) => {
      setItemIds(itemIds);
      section.onReorder!(itemIds);
    },
    [section.onReorder]
  );

  useEffect(() => {
    if (!groupRef.current) return;

    const element = groupRef.current;

    let isDragging: boolean = false;
    let startX: number;
    let startY: number;

    const onTouchStart = (e: TouchEvent) => {
      isDragging = false;
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
    };

    const onTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0];
      const diffX = Math.abs(touch.clientX - startX);
      const diffY = Math.abs(touch.clientY - startY);

      if (diffX > 5 || diffY > 5) {
        // порог, после которого считаем, что начался drag
        isDragging = true;
      }
    };

    const onClick = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        e.stopImmediatePropagation();
        isDragging = false;
      }
    };

    element.addEventListener("touchstart", onTouchStart, true);
    element.addEventListener("touchmove", onTouchMove, true);
    element.addEventListener("click", onClick, true);

    return () => {
      element.removeEventListener("touchstart", onTouchStart, true);
      element.removeEventListener("touchmove", onTouchMove, true);
      element.removeEventListener("click", onClick, true);
    };
  }, []);

  return (
    <Reorder.Group
      values={itemIds}
      onReorder={onReorder}
      as="div"
      ref={groupRef}
    >
      {itemIds.map((itemId: string, index: number) => {
        const item = section.items!.find(
          (item: { id: string }) => item.id === itemId
        );

        if (!item) return null;

        return (
          <Reorder.Item key={itemId} value={itemId} as="div">
            <Paper elevation={0} square>
              {section.renderItem
                ? section.renderItem(item, index)
                : renderItem(item, index)}
            </Paper>
          </Reorder.Item>
        );
      })}
    </Reorder.Group>
  );
}
