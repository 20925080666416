import api from "utils/api";
import { SystemChatMessageType, ChatMessage } from "./types";
import { firestore, auth } from "utils/firebase";
import {
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  doc,
  Timestamp,
} from "firebase/firestore";

export async function createSystemChatMessage(values: {
  type: SystemChatMessageType;
}) {
  await api("createSystemChatMessage", values);
}

export async function createChatMessage(values: {
  chatId: string;
  text?: string;
  video?: string;
  audio?: string;
  file?: string;
  replyTo?: ChatMessage;
}) {
  if (!auth.currentUser) {
    throw new Error("User is not authenticated");
  }

  const { chatId, text, video, audio, file, replyTo } = values;
  const creatorId = auth.currentUser.uid;
  const chatMessageRef = collection(firestore, "chats", chatId, "messages");
  const createdAt = Timestamp.now();

  await addDoc(chatMessageRef, {
    text,
    video,
    audio,
    file,
    replyTo,
    readerIds: [creatorId],
    creatorId: creatorId,
    createdAt: createdAt,
    updatedAt: createdAt,
  });
}

export async function updateChatMessage(values: {
  chatId: string;
  chatMessageId: string;
  text?: string;
  video?: string;
  audio?: string;
  file?: string;
}) {
  if (!auth.currentUser) {
    throw new Error("User is not authenticated");
  }

  const { chatId, chatMessageId, text, video, audio, file } = values;

  const chatMessageRef = doc(
    firestore,
    "chats",
    chatId,
    "messages",
    chatMessageId
  );

  await updateDoc(chatMessageRef, {
    text,
    video,
    audio,
    file,
    updatedAt: Timestamp.now(),
  });
}

export async function markChatMessagesAsRead(values: {
  chatId: string;
  chatMessageIds: string[];
  isRead: boolean;
}) {
  const res = await api("markChatMessagesAsRead", values);

  return res.data;
}

export async function deleteChatMessage(values: {
  chatId: string;
  chatMessageId: string;
}) {
  const { chatId, chatMessageId } = values;

  const chatMessageRef = doc(
    firestore,
    "chats",
    chatId,
    "messages",
    chatMessageId
  );

  await deleteDoc(chatMessageRef);
}

export const uploadFile = () => {};
