import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import {
  useCallback,
  useEffect,
  useState,
  useRef,
  type ReactNode,
} from "react";
import SendTextButton from "./SendTextButton";
import AttachButton from "./AttachButton";
import MicButton from "./MicButton";
import VideoButton from "./VideoButton";
import TextInput from "./TextInput";
import CancelButton from "./CancelButton";
import SendMicButton from "./SendMicButton";
import SendVideoButton from "./SendVideoButton";
import Duration from "./Duration";
import Progress from "./Progress";
import FileList from "./FileList";
import { ref, uploadBytes } from "firebase/storage";
import { storage, auth } from "utils/firebase";
import Box from "@mui/material/Box";
import { uploadsStore } from "utils/upload-file";
import { ChatMessage } from "features/chat";
import EditMessagePreview from "./EditMessagePreview";
import ReplyToMessagePreview from "./ReplyToMessagePreview";
// import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "utils/ui";
import { Recorder } from "vmsg";

const MAX_VIDEO_DURATION = 60; // seconds
const MAX_AUDIO_DURATION = 60; // seconds

const AUDIO_CACHE_SECONDS = 60 * 60 * 24 * 30; // 1 month

const VIDEO_ENABLED = false;
const AUDIO_ENABLED = false;
const FILE_ENABLED = false;

function getInitialMode() {
  return localStorage.getItem("comment-form-mode") || "audio";
}

function setInitialMode(mode: string) {
  localStorage.setItem("comment-form-mode", mode);
}

// function isMediaRecorderSupported() {
//   if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
//     return false;
//   }
//   if (!window.MediaRecorder) {
//     return false;
//   }
//   return true;
// }

// if (!MediaRecorder.isTypeSupported("video/webm")) {
//   alert("Ваш браузер не поддерживает формат video/webm.");
// }

const recorder = new Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm", // TODO use local
});

export default function ChatForm({
  chatId,
  onSubmit,
  editedChatMessage,
  onClickCancelEdit,
  replyToChatMessage,
  onClickCancelReply,
}: // uploadFile,
//
{
  chatId: string;
  onSubmit: (data: {
    text?: string;
    file?: string;
    video?: string;
    audio?: string;
  }) => Promise<void>;
  editedChatMessage?: ChatMessage;
  onClickCancelEdit: () => void;
  replyToChatMessage?: ChatMessage;
  onClickCancelReply: () => void;
  uploadFile: (file: File) => void;
}) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const [mode, setMode] = useState(getInitialMode);
  const [files, setFiles] = useState([]);
  // const [submitting, setSubmitting] = useState(false);
  const [saved, setSaved] = useState(true);
  const [isRecordingVideo, setIsRecordingVideo] = useState(false);
  const [isRecordingAudio, setIsRecordingAudio] = useState(false);
  const [timer, setTimer] = useState(60);
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder>(null);
  const chunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<NodeJS.Timeout>(null);

  useEffect(() => setInitialMode(mode), [mode]);

  const onInputFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onInputBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const stopRecordingAudio = useCallback(async () => {
    try {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setIsRecordingAudio(false);

      const blob = await recorder.stopRecording();
      // const url = URL.createObjectURL(blob); // URL to the audio file
      const creatorId = auth!.currentUser!.uid;
      const storageRef = ref(
        storage,
        `chats/${chatId}/users/${creatorId}/audios/${Date.now()}.mp3`
      );
      await uploadBytes(storageRef, blob, {
        cacheControl: `private,max-age=${AUDIO_CACHE_SECONDS},immutable`,
        contentType: "audio/mpeg",
      });
      await onSubmit({ audio: storageRef.fullPath });
      setTimer(MAX_AUDIO_DURATION);
    } catch (error) {
      console.log("stopRecordingAudio error", error);
    }
  }, [onSubmit, chatId]);

  const startRecordingAudio = useCallback(async () => {
    if (isRecordingAudio) return;

    try {
      setIsRecordingAudio(true);

      await recorder.initAudio();
      await recorder.initWorker();
      recorder.startRecording();

      // Start countdown timer
      setTimer(MAX_AUDIO_DURATION);
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            stopRecordingAudio();
            return 0;
          }
          return prevTimer - 0.1;
        });
      }, 100);
    } catch (error) {
      console.error("startRecordingAudio error", error);
    }
  }, [isRecordingAudio, stopRecordingAudio]);

  const stopRecordingVideo = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (videoRef.current) {
      if (videoRef.current.srcObject) {
        (videoRef.current.srcObject as MediaStream)
          .getTracks()
          .forEach((track: MediaStreamTrack) => track.stop());
      }
    }
    setIsRecordingVideo(false);
  }, []);

  const startRecordingVideo = useCallback(async () => {
    try {
      chunksRef.current = [];

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          height: 480,
          facingMode: "user",
        },
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunksRef.current, {
          type: "video/mp4;codecs=h264",
        });
        // const url = URL.createObjectURL(blob);
        // setVideoURL(url);
        // Upload to Firebase Storage
        const creatorId = auth!.currentUser!.uid;
        const storageRef = ref(
          storage,
          `chats/${chatId}/users/${creatorId}/videos/${Date.now()}.mp4`
        );
        await uploadBytes(storageRef, blob);
        await onSubmit({ video: storageRef.fullPath });
        chunksRef.current = [];
        setTimer(MAX_VIDEO_DURATION);
      };
      mediaRecorderRef.current.start();
      setIsRecordingVideo(true);

      // Start countdown timer
      setTimer(MAX_VIDEO_DURATION);
      timerRef.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            stopRecordingVideo();
            return 0;
          }
          return prevTimer - 0.1;
        });
      }, 100);
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  }, [chatId, onSubmit, stopRecordingVideo]);

  const onClickAttachButton = useCallback(() => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  }, []);

  const onChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setFiles((files) => .concat(files));
    if (!event.target.files) return;
    Array.from(event.target.files).forEach((file) => {
      uploadsStore.addUpload(Date.now().toString(), file as File);
    });
  };

  const onChangeTextInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const text = event.target.value.trim();

      if (text) {
        setSaved(false);
      } else {
        setSaved(true);
      }
    },
    []
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        (event.target as HTMLInputElement).form?.["submit-button"].click();
      }

      if (event.key === "Escape") {
        if (editedChatMessage) {
          onClickCancelEdit();
        }
        if (replyToChatMessage) {
          onClickCancelReply();
        }
        // event.target.blur();
      }
    },
    [
      editedChatMessage,
      onClickCancelEdit,
      replyToChatMessage,
      onClickCancelReply,
    ]
  );

  const onClickMic = useCallback(() => {
    console.log("mic");
    setMode("video");
  }, []);

  const onLongPressMic = useCallback(() => {
    console.log("long press mic");
    setMode("audio-recording");
    startRecordingAudio();
  }, [startRecordingAudio]);

  const onClickCancelAudioRecording = useCallback(() => {
    console.log("cancel audio recording");
    setMode("audio");
    stopRecordingAudio();
  }, [stopRecordingAudio]);

  const onClickSendAudioRecording = useCallback(() => {
    console.log("send audio recording");
    setMode("audio");
    stopRecordingAudio();
  }, [stopRecordingAudio]);

  const onClickVideo = useCallback(() => {
    console.log("video");
    setMode("audio");
  }, []);

  const onLongPressVideo = useCallback(() => {
    console.log("long press video");
    setMode("video-recording");
    startRecordingVideo();
  }, [startRecordingVideo]);

  const onClickCancelVideoRecording = useCallback(() => {
    console.log("cancel video recording");
    setMode("video");
    stopRecordingVideo();
  }, [stopRecordingVideo]);

  const onClickSendVideoRecording = useCallback(() => {
    console.log("send video recording");
    setMode("video");
    stopRecordingVideo();
  }, [stopRecordingVideo]);

  const onClickSendText = useCallback(() => {
    console.log("send text");
  }, []);

  const onSubmitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);

      const text = formData.get("message") as string;

      // if (submitting) return; // prevent double submit

      if (text) {
        // setSubmitting(true);

        onSubmit({ text })
          // .then(() => {
          //   event.target.message.focus();
          //   setFiles([]);
          //   setSubmitting(false);
          //   setSaved(true);
          // })
          .catch((error) => {
            console.error(error);
            // setSubmitting(false);
          });

        form.message.value = "";
        setFiles([]);
        // setSubmitting(false);
        setSaved(true);
      }
    },
    [
      // submitting,
      // setSubmitting,
      onSubmit,
    ]
  );

  const defaultValue = editedChatMessage?.text || "";

  const inner: Record<string, ReactNode> = {
    audio: (
      <Stack direction="row" spacing={1} alignItems="flex-end">
        {FILE_ENABLED && <AttachButton onClick={onClickAttachButton} />}
        <TextInput
          onKeyDown={onKeyDown}
          onChange={onChangeTextInput}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          defaultValue={defaultValue}
        />
        {saved ? (
          AUDIO_ENABLED && (
            <MicButton onClick={onClickMic} onLongPress={onLongPressMic} />
          )
        ) : (
          <SendTextButton visible onClick={onClickSendText} />
        )}
      </Stack>
    ),
    "audio-recording": (
      <>
        <Progress
          progress={(100 / MAX_AUDIO_DURATION) * (MAX_AUDIO_DURATION - timer)}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <Duration duration={MAX_AUDIO_DURATION - timer} />
          <CancelButton onClick={onClickCancelAudioRecording} />
          <SendMicButton onClick={onClickSendAudioRecording} />
        </Stack>
      </>
    ),
    video: (
      <Stack direction="row" spacing={1} alignItems="flex-end">
        {FILE_ENABLED && <AttachButton onClick={onClickAttachButton} />}
        <TextInput
          onKeyDown={onKeyDown}
          onChange={onChangeTextInput}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          defaultValue={defaultValue}
        />
        {saved ? (
          VIDEO_ENABLED && (
            <VideoButton
              onClick={onClickVideo}
              onLongPress={onLongPressVideo}
            />
          )
        ) : (
          <SendTextButton visible onClick={onClickSendText} />
        )}
      </Stack>
    ),
    "video-recording": (
      <>
        <Progress
          progress={(100 / MAX_VIDEO_DURATION) * (MAX_VIDEO_DURATION - timer)}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <Duration duration={MAX_VIDEO_DURATION - timer} />
          <CancelButton onClick={onClickCancelVideoRecording} />
          <SendVideoButton onClick={onClickSendVideoRecording} />
        </Stack>
      </>
    ),
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      component="form"
      onSubmit={onSubmitForm}
      sx={{
        // position: "absolute",
        // bottom: 0,
        // left: 0,
        // right: 0,
        borderTop: "1px solid",
        borderColor: "background.default",
        bgcolor: "background.paper",
        paddingBottom: focus ? 0 : "calc(env(safe-area-inset-bottom, 0))",
      }}
    >
      {editedChatMessage && (
        <EditMessagePreview
          editedChatMessage={editedChatMessage}
          onClickCancel={onClickCancelEdit}
        />
      )}

      {replyToChatMessage && (
        <ReplyToMessagePreview
          replyToChatMessage={replyToChatMessage}
          onClickCancel={onClickCancelReply}
        />
      )}

      <FileList files={files} />

      <Box sx={{ py: { xs: 1, lg: "13px" }, px: 1 }} test-id="ChatForm.Inner">
        {inner[mode]}
      </Box>

      <input
        type="file"
        multiple
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={onChangeFiles}
      />

      {VIDEO_ENABLED && (
        <Box
          sx={{
            position: "fixed",
            right: 0,
            bottom: 100,
            width: "240px",
            height: "240px",
            borderRadius: "50%",
            overflow: "hidden",
            border: "5px solid red",
            borderColor: "primary.main",
            backgroundColor: "black",
            display: isRecordingVideo ? "block" : "none",
          }}
        >
          <video
            ref={videoRef}
            style={{ width: "100%", height: "100%" }}
            muted
            playsInline
          ></video>
        </Box>
      )}
    </AppBar>
  );
}
