import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { firestore } from "utils/firebase";
import type { ExerciseData } from "features/exercises";

export function useExercise({ exerciseId }: { exerciseId: string }) {
  const [value, loading, error] = useDocumentDataOnce(
    doc(firestore, "exercises", exerciseId)
  );

  return {
    data: value as ExerciseData | undefined,
    loading,
    error,
  };
}
