import FormList, { type Schema } from "components/FormList";
import copy from "copy-to-clipboard";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { createTeamMember } from "./actions";
import { useParams } from "react-router";

export default function CreateTeamMemberPage() {
  const [createdTeamMember, setCreatedTeamMember] = useState<TeamMember | null>(
    null
  );

  if (createdTeamMember) {
    return <TeamMemberCreated createdTeamMember={createdTeamMember} />;
  }

  return <CreateTeamMemberForm setCreatedTeamMember={setCreatedTeamMember} />;
}

interface TeamMember {
  teamId: string;
  memberId: string;
  displayName: string;
  email: string;
  invitationLink: string;
}

function CreateTeamMemberForm({
  setCreatedTeamMember,
}: {
  setCreatedTeamMember: (createdTeamMember: TeamMember) => void;
}) {
  const { t } = useTranslation("coach");
  const { teamId } = useParams() as { teamId: string };

  const schema: Schema = [
    {
      footer: t("screens.create-team-member.footer"),
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "displayName",
          placeholder: t("screens.create-team-member.fields.displayName"),
          required: true,
        },
        {
          type: "email",
          name: "email",
          placeholder: t("screens.create-team-member.fields.email"),
          required: true,
          lowercase: true,
          trim: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-team-member.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: Record<string, unknown>) => {
    const { displayName, email } = values as {
      displayName: string;
      email: string;
    };

    const { memberId, invitationCode } = await createTeamMember({
      teamId,
      email,
      displayName,
    });

    setCreatedTeamMember({
      teamId,
      memberId,
      displayName,
      email,
      invitationLink: `${window.location.origin}/invitation/${invitationCode}`,
    });
  };

  return <FormList schema={schema} onSubmit={onSubmit} />;
}

function TeamMemberCreated({
  createdTeamMember,
}: {
  createdTeamMember: {
    displayName: string;
    email: string;
    invitationLink: string;
  };
}) {
  const { t } = useTranslation("coach");

  const { displayName, email, invitationLink } = createdTeamMember;

  const schema: Schema = [
    {
      fields: [
        {
          type: "typography",
          variant: "h6",
          color: "textPrimary",
          text: t("screens.team-member-created.header", {
            displayName,
            email,
          }),
        },
        {
          type: "typography",
          variant: "body1",
          color: "textSecondary",
          text: t("screens.team-member-created.footer", {
            displayName,
            email,
          }),
        },
      ],
    },
    {
      fields: [
        {
          type: "text",
          name: "invitationLink",
          onClick: (event: React.MouseEvent<HTMLInputElement>) => {
            (event.target as HTMLInputElement).select();
            copy(invitationLink);
            enqueueSnackbar(t("screens.team-member-created.linkCopied"), {
              variant: "success",
            });
          },
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "button",
          size: "large",
          label: t("screens.team-member-created.startCoaching", {
            displayName,
          }),
          color: "primary",
          variant: "contained",
          position: "fixed",
          link: {
            to: `../..`,
            replace: true,
          },
        },
      ],
    },
  ];

  const values = {
    invitationLink,
  };

  return <FormList schema={schema} values={values} />;
}
