import api from "utils/api";

export async function updateProgram(values: {
  programId: string;
  title: string;
  description?: string;
}) {
  await api("updateProgram", values);
}

export async function deleteProgram({ programId }: { programId: string }) {
  await api("deleteProgram", { programId });
}
