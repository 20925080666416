import { Navigate, Outlet } from "react-router";
import { useViewer, ViewerRole } from "features/viewer";

export default function ClientLayout() {
  const viewer = useViewer();

  if (!viewer) {
    return <Navigate to="/signin" replace />;
  }

  if (viewer.role !== ViewerRole.Client) {
    return <Navigate to={"/" + viewer.role} replace />;
  }

  return <Outlet />;
}
