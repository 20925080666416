import { useMemo, useCallback, useState, useEffect } from "react";
import ChatForm from "./ChatForm";
import ChatMessageList from "./ChatMessageList";
import ChatMenu from "./ChatMenu";
import { enqueueSnackbar } from "notistack";
import {
  createChatMessage,
  deleteChatMessage,
  updateChatMessage,
  uploadFile,
  markChatMessagesAsRead,
} from "../../../actions";
import { useChatMessages } from "../../../queries";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
// import { useVisibilityChange } from "@uidotdev/usehooks";
import { ChatData, ChatMessage } from "features/chat";
import { useUsersById } from "features/user";
import { auth } from "utils/firebase";
import { useSearchParams } from "react-router";
import copy from "copy-to-clipboard";

interface ChatInputValues {
  text?: string;
  file?: string;
  video?: string;
  audio?: string;
}

const markAsUnreadEnabled = false; // TODO: move to config

export default function ChatBody({
  chatId,
  chatData,
}: {
  chatId: string;
  chatData: ChatData;
}) {
  const [searchParams] = useSearchParams();
  const scrollToMessageId = searchParams.get("messageId") as string | undefined;
  const { chatMessages } = useChatMessages({ chatId });

  const { usersById, loading: loadingUsers } = useUsersById({
    userIds: chatData.memberIds,
  });

  useEffect(() => {
    if (chatMessages.length === 0) return;
    if (!auth?.currentUser?.uid) return;

    const unreadChatMessages = chatMessages.filter(
      (chatMessage) => !chatMessage.readerIds.includes(auth.currentUser!.uid)
    );

    if (unreadChatMessages.length === 0) return;

    markChatMessagesAsRead({
      chatId,
      chatMessageIds: unreadChatMessages.map((chatMessage) => chatMessage.id),
      isRead: true,
    })
      .then(() => {
        console.log("markChatMessagesAsRead", unreadChatMessages);
      })
      .catch((error) => {
        console.log("markChatMessagesAsRead error", error);
      });
  }, [chatMessages, chatId]);

  //   console.log("ChatBody", {
  //     chatId,
  //     chatData,
  //     chatMessages,
  //   });

  const [selectedChatMessage, setSelectedChatMessage] = useState<
    ChatMessage | undefined
  >();

  const [replyToChatMessage, setReplyToChatMessage] = useState<
    ChatMessage | undefined
  >();

  const [editedChatMessage, setEditedChatMessage] = useState<
    ChatMessage | undefined
  >();

  // const documentVisible = useVisibilityChange();

  const onCloseMenu = useCallback(() => {
    setSelectedChatMessage(undefined);
  }, [setSelectedChatMessage]);

  const onOpenMenu = useCallback(() => {
    //
  }, []);

  const onClickDelete = useCallback(() => {
    if (!selectedChatMessage) return;
    deleteChatMessage({ chatId, chatMessageId: selectedChatMessage.id }).then(
      () => {
        return enqueueSnackbar("Chat message deleted", { variant: "success" });
      }
    );
    setSelectedChatMessage(undefined);
  }, [selectedChatMessage, setSelectedChatMessage, chatId]);

  const onClickEdit = useCallback(() => {
    if (!selectedChatMessage) return;
    setEditedChatMessage(selectedChatMessage);
    setSelectedChatMessage(undefined);
    setTimeout(() => {
      document.getElementById("chat-text-input")?.focus(); // TODO переделать на ref
    }, 100);
  }, [setEditedChatMessage, selectedChatMessage, setSelectedChatMessage]);

  const onClickReply = useCallback(() => {
    if (!selectedChatMessage) return;
    setReplyToChatMessage(selectedChatMessage);
    setSelectedChatMessage(undefined);
    setTimeout(() => {
      document.getElementById("chat-text-input")?.focus(); // TODO переделать на ref
    }, 100);
  }, [setReplyToChatMessage, selectedChatMessage, setSelectedChatMessage]);

  const onClickCancelReply = useCallback(() => {
    setReplyToChatMessage(undefined);
  }, [setReplyToChatMessage]);

  const onClickCancelEdit = useCallback(() => {
    setEditedChatMessage(undefined);
  }, [setEditedChatMessage]);

  const onClickCopy = useCallback(() => {
    if (!selectedChatMessage) return;
    if (!selectedChatMessage.text) return;
    copy(selectedChatMessage.text);
    enqueueSnackbar("Chat message copied", { variant: "success" });
    setSelectedChatMessage(undefined);
  }, [selectedChatMessage]);

  const onClickMarkAsRead = useCallback(() => {
    if (!selectedChatMessage) return;
    const viewerId = auth.currentUser?.uid;
    if (!viewerId) return;
    const isRead = !selectedChatMessage.readerIds.includes(viewerId);
    setSelectedChatMessage(undefined);
    markChatMessagesAsRead({
      chatId,
      chatMessageIds: [selectedChatMessage.id],
      isRead,
    });
  }, [selectedChatMessage, setSelectedChatMessage, chatId]);

  const onSubmit = useCallback(
    async (values: ChatInputValues) => {
      if (editedChatMessage) {
        await updateChatMessage({
          chatId,
          chatMessageId: editedChatMessage.id,
          ...values,
        });
        setEditedChatMessage(undefined);
        enqueueSnackbar("Chat message updated", {
          variant: "success",
        });
      } else {
        setReplyToChatMessage(undefined);
        await createChatMessage({
          chatId,
          replyTo: replyToChatMessage,
          ...values,
        });
      }
    },
    [chatId, editedChatMessage, replyToChatMessage]
  );

  const menuItems = useMemo(() => {
    if (!selectedChatMessage) return [];
    const viewerId = auth.currentUser?.uid;
    if (!viewerId) return [];
    const isRead = selectedChatMessage.readerIds.includes(viewerId);

    const items = [];

    if (selectedChatMessage.text) {
      items.push({
        icon: <CopyAllIcon />,
        label: "Copy selected text",
        onClick: onClickCopy,
      });
    }

    if (selectedChatMessage.creatorId === auth.currentUser?.uid) {
      items.push(
        {
          icon: <EditIcon />,
          label: "Edit",
          onClick: onClickEdit,
        },
        {
          icon: <DeleteIcon />,
          label: "Delete",
          onClick: onClickDelete,
        }
      );
    } else {
      items.push({
        icon: <ReplyIcon />,
        label: "Reply",
        onClick: onClickReply,
      });

      if (markAsUnreadEnabled) {
        items.push({
          icon: isRead ? (
            <MarkChatUnreadOutlinedIcon />
          ) : (
            <MarkChatReadOutlinedIcon />
          ),
          label: isRead ? "Mark as unread" : "Mark as read",
          onClick: onClickMarkAsRead,
        });
      }
    }

    return items;
  }, [
    onClickCopy,
    onClickReply,
    onClickEdit,
    onClickDelete,
    onClickMarkAsRead,
    selectedChatMessage,
  ]);

  return (
    <>
      {/* <button onClick={() => fetchOldMessages()} disabled={!hasMore}>
        load prev
      </button> */}
      <ChatMessageList
        // startReached={startReached}
        // endReached={endReached}
        // atTopStateChange={atTopStateChange}
        // atBottomStateChange={atBottomStateChange}
        scrollToMessageId={scrollToMessageId}
        usersById={usersById}
        chatMessages={loadingUsers ? [] : chatMessages}
        editedChatMessage={editedChatMessage}
        selectedChatMessage={selectedChatMessage}
        setSelectedChatMessage={setSelectedChatMessage}
      />

      <ChatForm
        chatId={chatId}
        onClickCancelEdit={onClickCancelEdit}
        editedChatMessage={editedChatMessage}
        onClickCancelReply={onClickCancelReply}
        replyToChatMessage={replyToChatMessage}
        onSubmit={onSubmit}
        uploadFile={uploadFile}
        key={editedChatMessage?.id}
      />

      <ChatMenu
        menuItems={menuItems}
        open={Boolean(selectedChatMessage)}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
      />
    </>
  );
}
