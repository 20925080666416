import { useState, useCallback } from "react";
import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { signUp, requestAnInvitation } from "./actions";
import { logEvent } from "utils/analytics";
import { getValue } from "firebase/remote-config";
import { remoteConfig } from "utils/firebase";

export default function SignUpPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("auth");
  const { language } = i18n;

  const isSignUpEnabled = getValue(remoteConfig, "sign_up_enabled").asBoolean();

  const onSubmit = useCallback(
    async (values: {
      email: string;
      password: string;
      displayName: string;
    }) => {
      const { email, password, displayName } = values;

      await signUp({ email, password, displayName, language });

      logEvent("sign_up", {
        method: "email",
      });

      navigate("/install");
    },
    [language, navigate]
  );

  const [savedEmail, setSavedEmail] = useState<string | null>(null);
  const onSubmitInvitation = useCallback(
    async (values: { email: string }) => {
      const { email } = values;

      await requestAnInvitation({ email, language });

      setSavedEmail(email);
    },
    [language]
  );

  if (!isSignUpEnabled) {
    const schema: Schema = [
      {
        backgroundColor: "transparent",
        fields: [
          {
            type: "typography",
            variant: "h6",
            color: "textPrimary",
            text: "🚀 OnlineFitness.app — Launching Soon!",
          },
          {
            type: "typography",
            variant: "body1",
            color: "textSecondary",
            text: "We’re in beta testing and preparing a powerful platform for online trainers. Leave your email to get a personal invitation as soon as registration opens!",
          },
        ],
      },
    ];

    if (savedEmail) {
      schema.push({
        backgroundColor: "transparent",
        fields: [
          {
            type: "typography",
            variant: "body1",
            color: "textPrimary",
            text: "📩 We’ll notify you as soon as registration is available! Early users get exclusive perks 😉",
          },
        ],
      });
    } else {
      schema.push(
        {
          // header: t("screens.sign-up.header"),
          dividerVariant: "fullWidth",
          fields: [
            {
              type: "email",
              placeholder: t("screens.sign-up.email"),
              name: "email",
              required: true,
            },
          ],
        },
        {
          backgroundColor: "transparent",
          fields: [
            {
              type: "submit",
              label: "Request an Invitation",
              color: "primary",
              variant: "contained",
              size: "large",
            },
          ],
        }
      );
    }

    return (
      <>
        <Helmet>
          <title>{t("screens.sign-up.title")}</title>
        </Helmet>
        <FormList schema={schema} onSubmit={onSubmitInvitation} />
      </>
    );
  }

  const schema: Schema = [
    {
      header: t("screens.sign-up.header"),
      dividerVariant: "fullWidth",
      fields: [
        {
          type: "text",
          placeholder: t("screens.sign-up.displayName"),
          name: "displayName",
          required: true,
        },
        {
          type: "email",
          placeholder: t("screens.sign-up.email"),
          name: "email",
          required: true,
        },
        {
          type: "password",
          placeholder: t("screens.sign-up.password"),
          name: "password",
          required: true,
        },
      ],
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.sign-up.submit"),
          color: "primary",
          variant: "contained",
          size: "large",
          // position: "fixed",
          // maxWidth: "xs",
        },
      ],
    },

    {
      fields: [
        {
          type: "link",
          primary: t("screens.sign-up.haveAccount"),
          to: "/signin",
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("screens.sign-up.title")}</title>
      </Helmet>
      <FormList schema={schema} onSubmit={onSubmit} />
    </>
  );
}
