import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { createProgramWorkout } from "./actions";
import { useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";

export default function CreateProgramWorkoutPage() {
  const { t } = useTranslation("coach");
  const navigate = useNavigate();
  const { programId } = useParams() as {
    programId: string;
  };

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.create-program-workout.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.create-program-workout.fields.description"),
          multiline: true,
        },
        {
          type: "number",
          name: "day",
          placeholder: t(
            "screens.create-program-workout.fields.day.placeholder"
          ),
          endAdornment: t(
            "screens.create-program-workout.fields.day.adornment"
          ),
          required: true,
        },
      ],
      footer: t("screens.create-program-workout.fields.day.footer"),
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-program-workout.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: {
    title: string;
    description?: string;
    day: number;
  }) => {
    const workout = await createProgramWorkout({ programId, ...values });

    enqueueSnackbar(t("screens.create-program-workout.success"), {
      variant: "success",
    });

    navigate(`../${workout.id}`);
  };

  const values = {
    day: 1,
  };

  return <FormList schema={schema} onSubmit={onSubmit} values={values} />;
}
