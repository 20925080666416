import FormList, { type Schema } from "components/FormList";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router";
import Avatar from "@mui/material/Avatar";
import { InvitationData, acceptInvitation } from "features/invitations";
import { logEvent } from "utils/analytics";
import { useUsersById } from "features/user";
import ErrorScreen from "components/ErrorScreen";

export default function InvitationAccountPage() {
  const { t } = useTranslation("invitation");
  const navigate = useNavigate();

  const { invitationId, invitationData } = useOutletContext<{
    invitationId: string;
    invitationData: InvitationData;
  }>();

  const { usersById, loading, error } = useUsersById({
    userIds: [invitationData.clientId, invitationData.coachId],
  });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;

  const coach = usersById[invitationData.coachId];
  const client = usersById[invitationData.clientId];

  const schema: Schema = [
    {
      // backgroundColor: "transparent",
      fields: [
        {
          type: "item",
          primary: coach.displayName,
          secondary: t("screens.invitation.your-coach"),
          avatar: <Avatar src={coach.photoURL} />,
        },
        {
          type: "typography",
          text: t("screens.invitation-account.coach.text", {
            displayName: client.displayName,
          }),
          color: "textPrimary",
          variant: "body1",
        },
      ],
    },
    {
      // header: t("screens.invitation-account.fields.password.header"),
      footer: t("screens.invitation-account.fields.password.footer"),
      fields: [
        {
          type: "password",
          name: "password",
          placeholder: t(
            "screens.invitation-account.fields.password.placeholder"
          ),
          required: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.invitation-account.submit"),
          color: "primary",
          variant: "contained",
          size: "large",
        },
      ],
    },
  ];

  const onSubmit = async ({ password }: { password: string }) => {
    await acceptInvitation({
      invitationId,
      password,
    });

    logEvent("accept_invitation", {
      invitationId,
    });

    navigate("../profile", { replace: true });
  };

  return <FormList schema={schema} onSubmit={onSubmit} />;
}
