import Stack from "@mui/material/Stack";
import Account from "components/Account";
import { useTranslation } from "react-i18next";
import { useCoach } from "./queries";
import ErrorScreen from "components/ErrorScreen";
import NotFound from "components/NotFound";
import Paper from "@mui/material/Paper";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { useParams } from "react-router";

export default function CoachPage() {
  const { t } = useTranslation("client");
  const { coachId } = useParams() as { coachId: string };
  const { error, loading, coachData } = useCoach(coachId);

  if (loading) return null;
  if (error) return <ErrorScreen error={error} />;

  if (!coachData) {
    return (
      <NotFound
        icon={<NoAccountsIcon sx={{ fontSize: 60 }} />}
        title={t("screens.coach.notFound.title")}
        text={t("screens.coach.notFound.text")}
      />
    );
  }

  return (
    <Stack spacing={0}>
      <Paper elevation={0} square>
        <Account
          title={coachData.displayName}
          subheader={coachData.email}
          avatar={coachData.photoURL}
        />
      </Paper>
    </Stack>
  );
}
