import { useDocument } from "react-firebase-hooks/firestore";
import { doc, collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import { useParams } from "react-router";
import { ProgramWorkout } from "features/workouts";
import { ProgramWorkoutExercise } from "features/exercises";

export function useProgramWorkout() {
  const { programId, workoutId } = useParams() as {
    programId: string;
    workoutId: string;
  };

  const [snapshot, loading, error] = useDocument(
    doc(firestore, "programs", programId, "workouts", workoutId)
  );

  if (error) throw error;

  if (loading) return null;

  return {
    id: snapshot!.id,
    ...snapshot!.data(),
  } as ProgramWorkout;
}

export function useProgramWorkoutExercises(exerciseIds: string[] = []) {
  const { programId, workoutId } = useParams() as {
    programId: string;
    workoutId: string;
  };

  const [snapshot, loading, error] = useCollection(
    query(
      collection(
        firestore,
        "programs",
        programId,
        "workouts",
        workoutId,
        "exercises"
      ),
      where("creatorId", "==", auth.currentUser!.uid),
      orderBy("createdAt", "desc")
    )
  );

  if (error) throw error;

  if (loading) return [];

  if (!snapshot) return "empty";

  if (snapshot.empty) return "empty";

  const exercisesById = snapshot.docs.reduce(
    (all: Record<string, ProgramWorkoutExercise>, doc) => {
      all[doc.id] = {
        id: doc.id,
        ...doc.data(),
      } as ProgramWorkoutExercise;
      return all;
    },
    {}
  );

  return exerciseIds.map((id) => exercisesById[id]).filter(Boolean);
}
