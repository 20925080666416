import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { updateUser } from "./actions";

export default function LanguageScreen() {
  const { t, i18n } = useTranslation("core");

  const onChange = (language: string) => {
    updateUser({ language }).catch((err) => {
      console.log(err);
      enqueueSnackbar(t("screens.language.error", { message: err.message }), {
        variant: "error",
      });
    });
  };

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "radio",
          name: "language",
          options: [
            { label: "English", value: "en" },
            { label: "Русский", value: "ru" },
            // { label: "Deutsch", value: "de" },
          ],
          onChange,
        },
      ],
    },
  ];

  const values = {
    language: i18n.language,
  };

  return <FormList schema={schema} values={values} />;
}
