import {
  SectionList,
  SectionListItem,
  SectionListEmpty,
} from "components/SectionList";
import { useTeams } from "./queries";
import { useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";

interface Team {
  id: string;
  title: string;
}

export default function TeamsPage() {
  const { t } = useTranslation("coach");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") as string | undefined;
  const teams = useTeams(search);

  if (teams === "empty") {
    if (search) {
      return (
        <SectionListEmpty
          title={t("screens.teams.search.empty.title")}
          text={t("screens.teams.search.empty.text")}
          icon="/flaticon/search.png"
        />
      );
    }
    return (
      <SectionListEmpty
        title={t("screens.teams.empty.title")}
        text={t("screens.teams.empty.text")}
        icon="/flaticon/employees.png"
      />
    );
  }

  const sections = [
    {
      dividerVariant: "fullWidth" as const,
      items: teams,
      renderItem: (team: Team) => (
        <SectionListItem to={team.id} primary={team.title} />
      ),
    },
  ];

  return <SectionList sections={sections} />;
}
