import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { useViewer } from "features/viewer";
import { enqueueSnackbar } from "notistack";
import { updateUser } from "./actions";
import {
  SystemChatMessageType,
  useCreateSystemChatMessage,
} from "features/chat";

export default function EmailScreen() {
  const { t } = useTranslation("core");
  const viewer = useViewer();

  useCreateSystemChatMessage(
    SystemChatMessageType.WELCOME_MESSAGE_ON_SETTINGS_EMAIL_PAGE
  );

  const onSubmit = async (values: { email: string }) => {
    await updateUser(values);

    enqueueSnackbar(t("screens.settings-email.success"), {
      variant: "success",
    });
  };

  const schema: Schema = [
    {
      square: true,
      footer: t("screens.settings-email.footer"),
      fields: [
        {
          type: "email",
          placeholder: t("screens.settings-email.email"),
          name: "email",
          trim: true,
          lowercase: true,
          required: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.settings-email.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
          size: "large",
        },
      ],
    },
  ];

  const values = {
    email: viewer?.email,
  };

  return <FormList schema={schema} values={values} onSubmit={onSubmit} />;
}
