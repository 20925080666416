import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function SectionTabs({
  tab,
  onChange,
  tabs,
}: {
  tab: string;
  onChange: (_: unknown, tab: string) => void;
  tabs: { value: string; label: string }[];
}) {
  return (
    <Tabs
      value={tab}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChange}
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} value={tab.value} label={tab.label} />
      ))}
    </Tabs>
  );
}
