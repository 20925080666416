import { Notifications } from "features/notifications";
import { Navigate } from "react-router";
import { useViewer } from "features/viewer";

export default function NotificationsPage() {
  const viewer = useViewer();

  if (!viewer) {
    return <Navigate to="/signin" />;
  }

  return <Notifications />;
}
