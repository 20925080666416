import api from "utils/api";

export async function createVideoUpload(values: {
  exerciseId: string;
}): Promise<string> {
  const res = await api("createVideoUpload", values);

  return res.data as string;
}

export async function createExercise(values: {
  title: string;
  description?: string;
}): Promise<{ id: string }> {
  const res = await api("createExercise", values);

  return res.data as {
    id: string;
  };
}
