import ListItem from "@mui/material/ListItem";
import ListItemText, {
  ListItemTextSlotsAndSlotProps,
} from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router";

type SlotProps = ListItemTextSlotsAndSlotProps["slotProps"];

export default function SectionListItem({
  to,
  avatar,
  icon,
  primary,
  secondary,
  slotProps,
  disabled,
  action,
  alignItems = "center",
  onClick,
  disableTypography,
  selected,
  component = "li",
}: {
  component?: React.ElementType;
  to?: string;
  avatar?: React.ReactNode;
  icon?: React.ReactNode;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  slotProps?: SlotProps;
  disabled?: boolean;
  action?: React.ReactNode;
  alignItems?: "center" | "flex-start";
  onClick?: () => void;
  disableTypography?: boolean;
  selected?: boolean;
}) {
  const inner = (
    <>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText
        disableTypography={disableTypography}
        primary={primary}
        secondary={secondary}
        slotProps={slotProps}
      />
      {action}
    </>
  );

  if (to || onClick) {
    return (
      <ListItem disablePadding component={component}>
        <ListItemButton
          selected={selected}
          component={to ? Link : "button"}
          to={to}
          disabled={disabled}
          sx={{
            alignItems,
            WebkitUserDrag: "none",
            WebkitTouchCallout: "none", // Отключает системное меню при долгом удержании
            WebkitUserSelect: "none", // Отключает выделение текста
          }}
          onClick={onClick}
        >
          {inner}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem component={component} sx={{ alignItems }}>
      {inner}
    </ListItem>
  );
}
