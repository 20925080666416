import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet-async";
import Container from "components/Container";

export default function GuaranteePage() {
  return (
    <Card elevation={0}>
      <CardContent>
        <Container disableGutters maxWidth="sm">
          <Typography variant="body1" component="div">
            <Helmet>
              <title>Guarantee</title>
            </Helmet>

            <h1>Online Fitness App Guarantee</h1>

            <p>
              90-day money-back guarantee offer: 90-day money-back guarantee
              applies to the first 90 days’ recurring service charges of
              OnlineFitness.app up to the service level selected (STARTER Plan
              $360.00, PRO Plan $600.00, and PREMIUM Plan $960.00). Charges for
              set up fees, merchant processing fees, unreturned hardware,
              international fees, fees for disputes, or any other third-party
              fees will not be refunded. Offer is limited to new customers
              without OnlineFitness.app services in the past twelve (12) months.
            </p>

            <p>
              Existing customers, or customers with newly added locations, are
              not eligible. Customer must send an e-mail to{" "}
              <Link href={`mailto:support@onlinefitness.app`}>
                support@onlinefitness.app
              </Link>{" "}
              prior to the 90th day of the subscription term to notify us of
              your cancellation of services and the email must include the
              request for the “money back offer” at the time of cancellation and
              specify your User email in the message.
            </p>

            <p>
              Once the subscription term is past the first 90-day window, the
              money-back guarantee ends. After the 90-day period ends, regular
              rates apply.
            </p>

            <p>
              Offer cannot be combined with any other offers. Offer cannot be
              utilized multiple times and is not transferable. Delinquent
              accounts will not receive a refund. Please allow two (2) weeks for
              refund crediting. Offer is subject to change without notice.
            </p>
          </Typography>
        </Container>
      </CardContent>
    </Card>
  );
}
