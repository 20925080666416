import api from "utils/api";

export async function createProgramWorkoutExercise(values: {
  programId: string;
  workoutId: string;
  title: string;
  description?: string;
}) {
  const res = await api("createProgramWorkoutExercise", values);

  return res.data;
}
