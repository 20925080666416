import api from "utils/api";

export async function createExerciseResult(values: {
  exerciseId?: string;
  workoutId?: string;
  text: string;
  completed: boolean;
}) {
  return api("createExerciseResult", values);
}
