import {
  collection,
  query,
  where,
  orderBy,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import { Workout, WorkoutData } from "features/workouts";

export function useClientWorkouts({ clientId }: { clientId: string }) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(firestore, "users", clientId, "workouts"),
      where("creatorId", "==", auth.currentUser!.uid),
      orderBy("date", "asc")
    )
  );

  return {
    loading,
    error,
    empty: snapshot?.empty,
    workoutsByDate:
      snapshot?.docs?.reduce(
        (acc: Record<string, Workout[]>, doc: QueryDocumentSnapshot) => {
          const workoutData = doc.data() as WorkoutData;
          const key = workoutData.date;

          if (!acc[key]) {
            acc[key] = [];
          }

          const workout: Workout = {
            id: doc.id,
            ...workoutData,
          };

          acc[key].push(workout);

          return acc;
        },
        {}
      ) || {},
  };
}
