import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router";
import Divider from "@mui/material/Divider";
import { LEFT_SIDEBAR_WIDTH, RIGHT_SIDEBAR_WIDTH } from "utils/ui";

export default function BottomButton({
  to,
  children,
  ...props
}: {
  to?: string;
  children: React.ReactNode;
  [key: string]: unknown;
}) {
  return (
    <>
      <Box sx={{ height: 200 }} />
      <Box
        sx={{
          padding: 0,
          margin: 0,
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          paddingBottom: "calc(env(safe-area-inset-bottom, 0))",
          bgcolor: "background.paper",
          zIndex: 1100,
        }}
      >
        <Divider sx={{ borderColor: "background.default" }} />
        <Box
          sx={{
            px: 2,
            py: 2,
            marginLeft: { xs: 0, md: `${LEFT_SIDEBAR_WIDTH}px` },
            marginRight: { xs: 0, lg: `${RIGHT_SIDEBAR_WIDTH}px` },
          }}
        >
          {to ? (
            <Button component={Link} to={to} fullWidth {...props}>
              {children}
            </Button>
          ) : (
            <Button fullWidth {...props}>
              {children}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}
