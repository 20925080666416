import Box from "@mui/material/Box";

export default function MessageMeta({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box test-id="MessageMeta" sx={{ float: "right", ml: 1 }}>
      {children}
    </Box>
  );
}
