import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentData } from "firebase/firestore";
import { firestore, auth } from "utils/firebase";

interface ClientData extends DocumentData {
  displayName: string;
  email: string;
  photoURL?: string;
  invitationId: string;
  isArchived?: boolean;
}

export function useClient(clientId: string) {
  const [value, loading, error] = useDocumentData(
    doc(firestore, "users", auth.currentUser!.uid, "clients", clientId)
  );

  return {
    loading,
    error,
    clientData: value as ClientData | undefined,
  };
}
