import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ChatMessageVideo from "./ChatMessageVideo";
import ChatMessageAudio from "./ChatMessageAudio";
import ChatMessageText from "./ChatMessageText";
import type { ChatMessage } from "features/chat";
import type { User } from "features/user";

export default function ChatMessageReplyTo({
  replyTo,
  creator,
  color,
}: {
  replyTo: ChatMessage;
  creator: User;
  color: "creator" | "read" | "default";
}) {
  return (
    <ListItem
      alignItems="flex-start"
      component="div"
      sx={{
        borderLeft: "3px solid",
        borderColor: "primary.light",
        bgcolor: "blockquote.main",
        mt: 1,
        mb: 1,
        pt: 0,
        pb: 0,
      }}
    >
      <ListItemText
        disableTypography
        primary={creator.displayName}
        secondary={
          <>
            {replyTo.video && <ChatMessageVideo video={replyTo.video} />}
            {replyTo.audio && <ChatMessageAudio audio={replyTo.audio} />}
            {replyTo.text && (
              <ChatMessageText text={replyTo.text} color={color} />
            )}
          </>
        }
      />
    </ListItem>
  );
}
