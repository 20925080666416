const data = [
  { code: "AD", code3: "AND", name: "Andorra", region: "Europe" },
  { code: "AE", code3: "ARE", name: "United Arab Emirates", region: "Asia" },
  { code: "AF", code3: "AFG", name: "Afghanistan", region: "Asia" },
  { code: "AG", code3: "ATG", name: "Antigua and Barbuda", region: "Americas" },
  { code: "AI", code3: "AIA", name: "Anguilla", region: "Americas" },
  { code: "AL", code3: "ALB", name: "Albania", region: "Europe" },
  { code: "AM", code3: "ARM", name: "Armenia", region: "Asia" },
  { code: "AO", code3: "AGO", name: "Angola", region: "Africa" },
  { code: "AQ", code3: "ATA", name: "Antarctica", region: "" },
  { code: "AR", code3: "ARG", name: "Argentina", region: "Americas" },
  { code: "AS", code3: "ASM", name: "American Samoa", region: "Oceania" },
  { code: "AT", code3: "AUT", name: "Austria", region: "Europe" },
  { code: "AU", code3: "AUS", name: "Australia", region: "Oceania" },
  { code: "AW", code3: "ABW", name: "Aruba", region: "Americas" },
  { code: "AX", code3: "ALA", name: "Åland Islands", region: "Europe" },
  { code: "AZ", code3: "AZE", name: "Azerbaijan", region: "Asia" },
  {
    code: "BA",
    code3: "BIH",
    name: "Bosnia and Herzegovina",
    region: "Europe",
  },
  { code: "BB", code3: "BRB", name: "Barbados", region: "Americas" },
  { code: "BD", code3: "BGD", name: "Bangladesh", region: "Asia" },
  { code: "BE", code3: "BEL", name: "Belgium", region: "Europe" },
  { code: "BF", code3: "BFA", name: "Burkina Faso", region: "Africa" },
  { code: "BG", code3: "BGR", name: "Bulgaria", region: "Europe" },
  { code: "BH", code3: "BHR", name: "Bahrain", region: "Asia" },
  { code: "BI", code3: "BDI", name: "Burundi", region: "Africa" },
  { code: "BJ", code3: "BEN", name: "Benin", region: "Africa" },
  { code: "BL", code3: "BLM", name: "Saint Barthélemy", region: "Americas" },
  { code: "BM", code3: "BMU", name: "Bermuda", region: "Americas" },
  { code: "BN", code3: "BRN", name: "Brunei Darussalam", region: "Asia" },
  {
    code: "BO",
    code3: "BOL",
    name: "Bolivia (Plurinational State of)",
    region: "Americas",
  },
  {
    code: "BQ",
    code3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    region: "Americas",
  },
  { code: "BR", code3: "BRA", name: "Brazil", region: "Americas" },
  { code: "BS", code3: "BHS", name: "Bahamas", region: "Americas" },
  { code: "BT", code3: "BTN", name: "Bhutan", region: "Asia" },
  { code: "BV", code3: "BVT", name: "Bouvet Island", region: "Americas" },
  { code: "BW", code3: "BWA", name: "Botswana", region: "Africa" },
  { code: "BY", code3: "BLR", name: "Belarus", region: "Europe" },
  { code: "BZ", code3: "BLZ", name: "Belize", region: "Americas" },
  { code: "CA", code3: "CAN", name: "Canada", region: "Americas" },
  {
    code: "CC",
    code3: "CCK",
    name: "Cocos (Keeling) Islands",
    region: "Oceania",
  },
  {
    code: "CD",
    code3: "COD",
    name: "Congo, Democratic Republic of the",
    region: "Africa",
  },
  {
    code: "CF",
    code3: "CAF",
    name: "Central African Republic",
    region: "Africa",
  },
  { code: "CG", code3: "COG", name: "Congo", region: "Africa" },
  { code: "CH", code3: "CHE", name: "Switzerland", region: "Europe" },
  { code: "CI", code3: "CIV", name: "Côte d'Ivoire", region: "Africa" },
  { code: "CK", code3: "COK", name: "Cook Islands", region: "Oceania" },
  { code: "CL", code3: "CHL", name: "Chile", region: "Americas" },
  { code: "CM", code3: "CMR", name: "Cameroon", region: "Africa" },
  { code: "CN", code3: "CHN", name: "China", region: "Asia" },
  { code: "CO", code3: "COL", name: "Colombia", region: "Americas" },
  { code: "CR", code3: "CRI", name: "Costa Rica", region: "Americas" },
  { code: "CU", code3: "CUB", name: "Cuba", region: "Americas" },
  { code: "CV", code3: "CPV", name: "Cabo Verde", region: "Africa" },
  { code: "CW", code3: "CUW", name: "Curaçao", region: "Americas" },
  { code: "CX", code3: "CXR", name: "Christmas Island", region: "Oceania" },
  { code: "CY", code3: "CYP", name: "Cyprus", region: "Asia" },
  { code: "CZ", code3: "CZE", name: "Czechia", region: "Europe" },
  { code: "DE", code3: "DEU", name: "Germany", region: "Europe" },
  { code: "DJ", code3: "DJI", name: "Djibouti", region: "Africa" },
  { code: "DK", code3: "DNK", name: "Denmark", region: "Europe" },
  { code: "DM", code3: "DMA", name: "Dominica", region: "Americas" },
  { code: "DO", code3: "DOM", name: "Dominican Republic", region: "Americas" },
  { code: "DZ", code3: "DZA", name: "Algeria", region: "Africa" },
  { code: "EC", code3: "ECU", name: "Ecuador", region: "Americas" },
  { code: "EE", code3: "EST", name: "Estonia", region: "Europe" },
  { code: "EG", code3: "EGY", name: "Egypt", region: "Africa" },
  { code: "EH", code3: "ESH", name: "Western Sahara", region: "Africa" },
  { code: "ER", code3: "ERI", name: "Eritrea", region: "Africa" },
  { code: "ES", code3: "ESP", name: "Spain", region: "Europe" },
  { code: "ET", code3: "ETH", name: "Ethiopia", region: "Africa" },
  { code: "FI", code3: "FIN", name: "Finland", region: "Europe" },
  { code: "FJ", code3: "FJI", name: "Fiji", region: "Oceania" },
  {
    code: "FK",
    code3: "FLK",
    name: "Falkland Islands (Malvinas)",
    region: "Americas",
  },
  {
    code: "FM",
    code3: "FSM",
    name: "Micronesia (Federated States of)",
    region: "Oceania",
  },
  { code: "FO", code3: "FRO", name: "Faroe Islands", region: "Europe" },
  { code: "FR", code3: "FRA", name: "France", region: "Europe" },
  { code: "GA", code3: "GAB", name: "Gabon", region: "Africa" },
  {
    code: "GB",
    code3: "GBR",
    name: "United Kingdom of Great Britain and Northern Ireland",
    region: "Europe",
  },
  { code: "GD", code3: "GRD", name: "Grenada", region: "Americas" },
  { code: "GE", code3: "GEO", name: "Georgia", region: "Asia" },
  { code: "GF", code3: "GUF", name: "French Guiana", region: "Americas" },
  { code: "GG", code3: "GGY", name: "Guernsey", region: "Europe" },
  { code: "GH", code3: "GHA", name: "Ghana", region: "Africa" },
  { code: "GI", code3: "GIB", name: "Gibraltar", region: "Europe" },
  { code: "GL", code3: "GRL", name: "Greenland", region: "Americas" },
  { code: "GM", code3: "GMB", name: "Gambia", region: "Africa" },
  { code: "GN", code3: "GIN", name: "Guinea", region: "Africa" },
  { code: "GP", code3: "GLP", name: "Guadeloupe", region: "Americas" },
  { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", region: "Africa" },
  { code: "GR", code3: "GRC", name: "Greece", region: "Europe" },
  {
    code: "GS",
    code3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    region: "Americas",
  },
  { code: "GT", code3: "GTM", name: "Guatemala", region: "Americas" },
  { code: "GU", code3: "GUM", name: "Guam", region: "Oceania" },
  { code: "GW", code3: "GNB", name: "Guinea-Bissau", region: "Africa" },
  { code: "GY", code3: "GUY", name: "Guyana", region: "Americas" },
  { code: "HK", code3: "HKG", name: "Hong Kong", region: "Asia" },
  {
    code: "HM",
    code3: "HMD",
    name: "Heard Island and McDonald Islands",
    region: "Oceania",
  },
  { code: "HN", code3: "HND", name: "Honduras", region: "Americas" },
  { code: "HR", code3: "HRV", name: "Croatia", region: "Europe" },
  { code: "HT", code3: "HTI", name: "Haiti", region: "Americas" },
  { code: "HU", code3: "HUN", name: "Hungary", region: "Europe" },
  { code: "ID", code3: "IDN", name: "Indonesia", region: "Asia" },
  { code: "IE", code3: "IRL", name: "Ireland", region: "Europe" },
  { code: "IL", code3: "ISR", name: "Israel", region: "Asia" },
  { code: "IM", code3: "IMN", name: "Isle of Man", region: "Europe" },
  { code: "IN", code3: "IND", name: "India", region: "Asia" },
  {
    code: "IO",
    code3: "IOT",
    name: "British Indian Ocean Territory",
    region: "Africa",
  },
  { code: "IQ", code3: "IRQ", name: "Iraq", region: "Asia" },
  {
    code: "IR",
    code3: "IRN",
    name: "Iran (Islamic Republic of)",
    region: "Asia",
  },
  { code: "IS", code3: "ISL", name: "Iceland", region: "Europe" },
  { code: "IT", code3: "ITA", name: "Italy", region: "Europe" },
  { code: "JE", code3: "JEY", name: "Jersey", region: "Europe" },
  { code: "JM", code3: "JAM", name: "Jamaica", region: "Americas" },
  { code: "JO", code3: "JOR", name: "Jordan", region: "Asia" },
  { code: "JP", code3: "JPN", name: "Japan", region: "Asia" },
  { code: "KE", code3: "KEN", name: "Kenya", region: "Africa" },
  { code: "KG", code3: "KGZ", name: "Kyrgyzstan", region: "Asia" },
  { code: "KH", code3: "KHM", name: "Cambodia", region: "Asia" },
  { code: "KI", code3: "KIR", name: "Kiribati", region: "Oceania" },
  { code: "KM", code3: "COM", name: "Comoros", region: "Africa" },
  {
    code: "KN",
    code3: "KNA",
    name: "Saint Kitts and Nevis",
    region: "Americas",
  },
  {
    code: "KP",
    code3: "PRK",
    name: "Korea (Democratic People's Republic of)",
    region: "Asia",
  },
  { code: "KR", code3: "KOR", name: "Korea, Republic of", region: "Asia" },
  { code: "KW", code3: "KWT", name: "Kuwait", region: "Asia" },
  { code: "KY", code3: "CYM", name: "Cayman Islands", region: "Americas" },
  { code: "KZ", code3: "KAZ", name: "Kazakhstan", region: "Asia" },
  {
    code: "LA",
    code3: "LAO",
    name: "Lao People's Democratic Republic",
    region: "Asia",
  },
  { code: "LB", code3: "LBN", name: "Lebanon", region: "Asia" },
  { code: "LC", code3: "LCA", name: "Saint Lucia", region: "Americas" },
  { code: "LI", code3: "LIE", name: "Liechtenstein", region: "Europe" },
  { code: "LK", code3: "LKA", name: "Sri Lanka", region: "Asia" },
  { code: "LR", code3: "LBR", name: "Liberia", region: "Africa" },
  { code: "LS", code3: "LSO", name: "Lesotho", region: "Africa" },
  { code: "LT", code3: "LTU", name: "Lithuania", region: "Europe" },
  { code: "LU", code3: "LUX", name: "Luxembourg", region: "Europe" },
  { code: "LV", code3: "LVA", name: "Latvia", region: "Europe" },
  { code: "LY", code3: "LBY", name: "Libya", region: "Africa" },
  { code: "MA", code3: "MAR", name: "Morocco", region: "Africa" },
  { code: "MC", code3: "MCO", name: "Monaco", region: "Europe" },
  { code: "MD", code3: "MDA", name: "Moldova, Republic of", region: "Europe" },
  { code: "ME", code3: "MNE", name: "Montenegro", region: "Europe" },
  {
    code: "MF",
    code3: "MAF",
    name: "Saint Martin (French part)",
    region: "Americas",
  },
  { code: "MG", code3: "MDG", name: "Madagascar", region: "Africa" },
  { code: "MH", code3: "MHL", name: "Marshall Islands", region: "Oceania" },
  { code: "MK", code3: "MKD", name: "North Macedonia", region: "Europe" },
  { code: "ML", code3: "MLI", name: "Mali", region: "Africa" },
  { code: "MM", code3: "MMR", name: "Myanmar", region: "Asia" },
  { code: "MN", code3: "MNG", name: "Mongolia", region: "Asia" },
  { code: "MO", code3: "MAC", name: "Macao", region: "Asia" },
  {
    code: "MP",
    code3: "MNP",
    name: "Northern Mariana Islands",
    region: "Oceania",
  },
  { code: "MQ", code3: "MTQ", name: "Martinique", region: "Americas" },
  { code: "MR", code3: "MRT", name: "Mauritania", region: "Africa" },
  { code: "MS", code3: "MSR", name: "Montserrat", region: "Americas" },
  { code: "MT", code3: "MLT", name: "Malta", region: "Europe" },
  { code: "MU", code3: "MUS", name: "Mauritius", region: "Africa" },
  { code: "MV", code3: "MDV", name: "Maldives", region: "Asia" },
  { code: "MW", code3: "MWI", name: "Malawi", region: "Africa" },
  { code: "MX", code3: "MEX", name: "Mexico", region: "Americas" },
  { code: "MY", code3: "MYS", name: "Malaysia", region: "Asia" },
  { code: "MZ", code3: "MOZ", name: "Mozambique", region: "Africa" },
  { code: "NA", code3: "NAM", name: "Namibia", region: "Africa" },
  { code: "NC", code3: "NCL", name: "New Caledonia", region: "Oceania" },
  { code: "NE", code3: "NER", name: "Niger", region: "Africa" },
  { code: "NF", code3: "NFK", name: "Norfolk Island", region: "Oceania" },
  { code: "NG", code3: "NGA", name: "Nigeria", region: "Africa" },
  { code: "NI", code3: "NIC", name: "Nicaragua", region: "Americas" },
  { code: "NL", code3: "NLD", name: "Netherlands", region: "Europe" },
  { code: "NO", code3: "NOR", name: "Norway", region: "Europe" },
  { code: "NP", code3: "NPL", name: "Nepal", region: "Asia" },
  { code: "NR", code3: "NRU", name: "Nauru", region: "Oceania" },
  { code: "NU", code3: "NIU", name: "Niue", region: "Oceania" },
  { code: "NZ", code3: "NZL", name: "New Zealand", region: "Oceania" },
  { code: "OM", code3: "OMN", name: "Oman", region: "Asia" },
  { code: "PA", code3: "PAN", name: "Panama", region: "Americas" },
  { code: "PE", code3: "PER", name: "Peru", region: "Americas" },
  { code: "PF", code3: "PYF", name: "French Polynesia", region: "Oceania" },
  { code: "PG", code3: "PNG", name: "Papua New Guinea", region: "Oceania" },
  { code: "PH", code3: "PHL", name: "Philippines", region: "Asia" },
  { code: "PK", code3: "PAK", name: "Pakistan", region: "Asia" },
  { code: "PL", code3: "POL", name: "Poland", region: "Europe" },
  {
    code: "PM",
    code3: "SPM",
    name: "Saint Pierre and Miquelon",
    region: "Americas",
  },
  { code: "PN", code3: "PCN", name: "Pitcairn", region: "Oceania" },
  { code: "PR", code3: "PRI", name: "Puerto Rico", region: "Americas" },
  { code: "PS", code3: "PSE", name: "Palestine, State of", region: "Asia" },
  { code: "PT", code3: "PRT", name: "Portugal", region: "Europe" },
  { code: "PW", code3: "PLW", name: "Palau", region: "Oceania" },
  { code: "PY", code3: "PRY", name: "Paraguay", region: "Americas" },
  { code: "QA", code3: "QAT", name: "Qatar", region: "Asia" },
  { code: "RE", code3: "REU", name: "Réunion", region: "Africa" },
  { code: "RO", code3: "ROU", name: "Romania", region: "Europe" },
  { code: "RS", code3: "SRB", name: "Serbia", region: "Europe" },
  { code: "RU", code3: "RUS", name: "Russian Federation", region: "Europe" },
  { code: "RW", code3: "RWA", name: "Rwanda", region: "Africa" },
  { code: "SA", code3: "SAU", name: "Saudi Arabia", region: "Asia" },
  { code: "SB", code3: "SLB", name: "Solomon Islands", region: "Oceania" },
  { code: "SC", code3: "SYC", name: "Seychelles", region: "Africa" },
  { code: "SD", code3: "SDN", name: "Sudan", region: "Africa" },
  { code: "SE", code3: "SWE", name: "Sweden", region: "Europe" },
  { code: "SG", code3: "SGP", name: "Singapore", region: "Asia" },
  {
    code: "SH",
    code3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    region: "Africa",
  },
  { code: "SI", code3: "SVN", name: "Slovenia", region: "Europe" },
  {
    code: "SJ",
    code3: "SJM",
    name: "Svalbard and Jan Mayen",
    region: "Europe",
  },
  { code: "SK", code3: "SVK", name: "Slovakia", region: "Europe" },
  { code: "SL", code3: "SLE", name: "Sierra Leone", region: "Africa" },
  { code: "SM", code3: "SMR", name: "San Marino", region: "Europe" },
  { code: "SN", code3: "SEN", name: "Senegal", region: "Africa" },
  { code: "SO", code3: "SOM", name: "Somalia", region: "Africa" },
  { code: "SR", code3: "SUR", name: "Suriname", region: "Americas" },
  { code: "SS", code3: "SSD", name: "South Sudan", region: "Africa" },
  { code: "ST", code3: "STP", name: "Sao Tome and Principe", region: "Africa" },
  { code: "SV", code3: "SLV", name: "El Salvador", region: "Americas" },
  {
    code: "SX",
    code3: "SXM",
    name: "Sint Maarten (Dutch part)",
    region: "Americas",
  },
  { code: "SY", code3: "SYR", name: "Syrian Arab Republic", region: "Asia" },
  { code: "SZ", code3: "SWZ", name: "Eswatini", region: "Africa" },
  {
    code: "TC",
    code3: "TCA",
    name: "Turks and Caicos Islands",
    region: "Americas",
  },
  { code: "TD", code3: "TCD", name: "Chad", region: "Africa" },
  {
    code: "TF",
    code3: "ATF",
    name: "French Southern Territories",
    region: "Africa",
  },
  { code: "TG", code3: "TGO", name: "Togo", region: "Africa" },
  { code: "TH", code3: "THA", name: "Thailand", region: "Asia" },
  { code: "TJ", code3: "TJK", name: "Tajikistan", region: "Asia" },
  { code: "TK", code3: "TKL", name: "Tokelau", region: "Oceania" },
  { code: "TL", code3: "TLS", name: "Timor-Leste", region: "Asia" },
  { code: "TM", code3: "TKM", name: "Turkmenistan", region: "Asia" },
  { code: "TN", code3: "TUN", name: "Tunisia", region: "Africa" },
  { code: "TO", code3: "TON", name: "Tonga", region: "Oceania" },
  { code: "TR", code3: "TUR", name: "Turkey", region: "Asia" },
  { code: "TT", code3: "TTO", name: "Trinidad and Tobago", region: "Americas" },
  { code: "TV", code3: "TUV", name: "Tuvalu", region: "Oceania" },
  {
    code: "TW",
    code3: "TWN",
    name: "Taiwan, Province of China",
    region: "Asia",
  },
  {
    code: "TZ",
    code3: "TZA",
    name: "Tanzania, United Republic of",
    region: "Africa",
  },
  { code: "UA", code3: "UKR", name: "Ukraine", region: "Europe" },
  { code: "UG", code3: "UGA", name: "Uganda", region: "Africa" },
  {
    code: "UM",
    code3: "UMI",
    name: "United States Minor Outlying Islands",
    region: "Oceania",
  },
  {
    code: "US",
    code3: "USA",
    name: "United States of America",
    region: "Americas",
  },
  { code: "UY", code3: "URY", name: "Uruguay", region: "Americas" },
  { code: "UZ", code3: "UZB", name: "Uzbekistan", region: "Asia" },
  { code: "VA", code3: "VAT", name: "Holy See", region: "Europe" },
  {
    code: "VC",
    code3: "VCT",
    name: "Saint Vincent and the Grenadines",
    region: "Americas",
  },
  {
    code: "VE",
    code3: "VEN",
    name: "Venezuela (Bolivarian Republic of)",
    region: "Americas",
  },
  {
    code: "VG",
    code3: "VGB",
    name: "Virgin Islands (British)",
    region: "Americas",
  },
  {
    code: "VI",
    code3: "VIR",
    name: "Virgin Islands (U.S.)",
    region: "Americas",
  },
  { code: "VN", code3: "VNM", name: "Viet Nam", region: "Asia" },
  { code: "VU", code3: "VUT", name: "Vanuatu", region: "Oceania" },
  { code: "WF", code3: "WLF", name: "Wallis and Futuna", region: "Oceania" },
  { code: "WS", code3: "WSM", name: "Samoa", region: "Oceania" },
  { code: "YE", code3: "YEM", name: "Yemen", region: "Asia" },
  { code: "YT", code3: "MYT", name: "Mayotte", region: "Africa" },
  { code: "ZA", code3: "ZAF", name: "South Africa", region: "Africa" },
  { code: "ZM", code3: "ZMB", name: "Zambia", region: "Africa" },
  { code: "ZW", code3: "ZWE", name: "Zimbabwe", region: "Africa" },
  {
    code: "XK",
    code3: "XXK",
    name: "Kosovo",
    region: "Europe",
  },
];

export const countries = data;
