import ClientLayout from "./client/layout";
import clientRoutes from "./client/routes";

import CoachLayout from "./coach/layout";
import coachRoutes from "./coach/routes";

// import FeedbackPage from "./feedback/page";
import InstallPWAPage from "./install/page";

import NotificationsPage from "./notifications/page";
import { MarkAllNotificationsAsReadButton } from "features/notifications";

import SettingsLayout from "./settings/layout";
import settingsRoutes from "./settings/routes";

const routes = [
  {
    path: "client",
    element: <ClientLayout />,
    children: clientRoutes,
  },
  {
    path: "coach",
    element: <CoachLayout />,
    children: coachRoutes,
  },
  // {
  //   path: "feedback",
  //   element: <FeedbackPage />,
  //   handle: {
  //     header: {
  //       // title: "Feedback",
  //       back: <HeaderBackButton />,
  //       action: <HeaderSkipButton />,
  //     },
  //   },
  // },
  {
    path: "install",
    element: <InstallPWAPage />,
    handle: {
      header: {
        title: "Install app",
      },
    },
  },
  {
    path: "notifications",
    element: <NotificationsPage />,
    handle: {
      header: {
        title: "Notifications",
        action: <MarkAllNotificationsAsReadButton />,
      },
    },
  },
  {
    path: "settings",
    element: <SettingsLayout />,
    children: settingsRoutes,
  },
];

export default routes;
