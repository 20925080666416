import { Navigate } from "react-router";

import workoutsRoutes from "./workouts/routes";
import coachesRoutes from "./coaches/routes";

const routes = [
  {
    index: true,
    element: <Navigate to="/client/workouts" />,
  },
  {
    path: "workouts",
    children: workoutsRoutes,
  },
  {
    path: "coaches",
    children: coachesRoutes,
  },
];

export default routes;
