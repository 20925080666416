import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { useViewer } from "features/viewer";
import { updateUser } from "./actions";
import { timeZones } from "utils/dayjs";
import { countries } from "utils/countries";
import { logEvent } from "utils/analytics";
import {
  SystemChatMessageType,
  useCreateSystemChatMessage,
} from "features/chat";

interface SubmitValues {
  displayName?: string;
  photoURL?: string;
  timeZone?: string;
  country?: string;
}

export default function ProfileScreen() {
  const { t } = useTranslation("core");
  const viewer = useViewer()!;

  useCreateSystemChatMessage(
    SystemChatMessageType.WELCOME_MESSAGE_ON_SETTINGS_PROFILE_PAGE
  );

  const onSubmit = async (values: SubmitValues) => {
    await updateUser(values);
    logEvent("profile_update");
    enqueueSnackbar(t("screens.settings-profile.success"), {
      variant: "success",
    });
  };

  const schema: Schema = [
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "avatar",
          name: "photoURL",
          label: t("screens.settings-profile.photo"),
        },
      ],
    },
    {
      square: true,
      fields: [
        {
          type: "text",
          startAdornment: t("screens.settings-profile.name") + ":",
          placeholder: t("screens.settings-profile.name"),
          name: "displayName",
          required: true,
        },
        {
          type: "select",
          startAdornment: t("screens.settings-profile.timeZone") + ":",
          placeholder: t("screens.settings-profile.timeZone"),
          name: "timeZone",
          options: timeZones.map((option) => ({
            label: option,
            value: option,
          })),
          required: true,
        },
        {
          type: "select",
          startAdornment: t("screens.settings-profile.country") + ":",
          placeholder: t("screens.settings-profile.country"),
          name: "country",
          options: countries.map((option) => ({
            label: option.name,
            value: option.code,
          })),
          required: true,
        },
      ],
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          label: t("screens.settings-profile.submit"),
          color: "primary",
          variant: "contained",
          size: "large",
          position: "fixed",
        },
      ],
    },
  ];

  const values = {
    displayName: viewer.displayName,
    photoURL: viewer.photoURL,
    timeZone: viewer.timeZone,
    country: viewer.country,
  };

  return <FormList schema={schema} values={values} onSubmit={onSubmit} />;
}
