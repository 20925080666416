import dayjs from "utils/dayjs";
import { useMemo } from "react";
import SelectField from "./SelectField";

const PROJECT_START_DATE = "2024-01-01";

export default function SelectYear({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  const options = useMemo(
    () =>
      Array.from(
        { length: dayjs().diff(PROJECT_START_DATE, "year") + 2 },
        (_, index) => {
          const year = dayjs(PROJECT_START_DATE).add(index, "year").year();
          return {
            value: year,
            label: year.toString(),
          };
        }
      ),
    []
  );

  return <SelectField onChange={onChange} options={options} value={value} />;
}
