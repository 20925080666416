import { doc } from "firebase/firestore";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import { firestore } from "utils/firebase";
import { useParams } from "react-router";

export function useProgramWorkout() {
  const { programId, workoutId } = useParams();
  const [snapshot, loading, error] = useDocumentOnce(
    doc(firestore, "programs", programId!, "workouts", workoutId!)
  );

  if (error) throw error;

  if (loading) return null;

  return {
    id: snapshot!.id,
    ...snapshot!.data(),
  } as {
    id: string;
    title: string;
    description?: string;
    day: number;
  };
}
