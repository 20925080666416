import { Navigate } from "react-router";

import clientsRoutes from "./clients/routes";
import exercisesRoutes from "./exercises/routes";
import programsRoutes from "./programs/routes";
import teamsRoutes from "./teams/routes";
import billingRoutes from "./billing/routes";
import referralRoutes from "./referral/routes";

const routes = [
  {
    index: true,
    element: <Navigate to="/coach/clients" />,
  },
  {
    path: "clients",
    children: clientsRoutes,
  },
  {
    path: "exercises",
    children: exercisesRoutes,
  },
  {
    path: "programs",
    children: programsRoutes,
  },
  {
    path: "teams",
    children: teamsRoutes,
  },
  {
    path: "billing",
    children: billingRoutes,
  },
  {
    path: "referral",
    children: referralRoutes,
  },
];

export default routes;
