import api from "utils/api";

export async function updateExercise(values: {
  exerciseId: string;
  title?: string;
  description?: string;
  tags?: string[];
  videoUrl?: string;
  reps?: number;
  sets?: number;
  rest?: number;
  weight?: number;
  duration?: number;
}) {
  await api("updateExercise", values);
}

export async function deleteExercise({ exerciseId }: { exerciseId: string }) {
  await api("deleteExercise", { exerciseId });
}
