import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function ChatMenu({
  menuItems,
  open,
  onClose,
  onOpen,
}: {
  menuItems: { label: string; icon: React.ReactNode; onClick: () => void }[];
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
}) {
  return (
    <SwipeableDrawer
      // disablePortal
      disableDiscovery
      disableSwipeToOpen
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      elevation={1}
      sx={{
        // position: "absolute",
        "& .MuiDrawer-paper": {
          // position: "absolute",
          // bgcolor: "primary.main",
          paddingBottom: "calc(env(safe-area-inset-bottom, 0))",
        },
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  );
}
