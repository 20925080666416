export function getThumbnailUrl(
  playbackId?: string,
  params?: {
    time?: string;
    width?: string;
    height?: string;
    fit_mode?: string;
    rotate?: string;
    quality?: string;
    format?: string;
    fps?: string;
  }
) {
  if (!playbackId) return null;

  if (params) {
    return `https://image.mux.com/${playbackId}/thumbnail.jpg?${new URLSearchParams(
      params
    )}`;
  }

  return `https://image.mux.com/${playbackId}/thumbnail.jpg`;
}
