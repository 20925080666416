import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Tooltip from "@mui/material/Tooltip";
import ReplyIcon from "@mui/icons-material/Reply";
import Stack from "@mui/material/Stack";
import type { ChatMessage } from "../../../../types";

export default function ReplyToMessagePreview({
  replyToChatMessage,
  onClickCancel,
}: {
  replyToChatMessage: ChatMessage;
  onClickCancel: () => void;
}) {
  return (
    <Box sx={{ px: 1, pt: 1 }}>
      <Stack direction="row" spacing={1} alignItems="flex-end">
        <IconButton disabled size="large">
          <ReplyIcon color="primary" />
        </IconButton>
        <Box sx={{ flex: 1, maxWidth: "calc(100% - 48px - 48px - 6px - 6px)" }}>
          <Typography variant="subtitle2" color="primary">
            Reply to message
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            {replyToChatMessage.text}
          </Typography>
        </Box>
        <Tooltip title="Cancel reply" placement="top">
          <IconButton onClick={onClickCancel} size="large">
            <CancelOutlinedIcon color="action" />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
