import api from "utils/api";

export function updateUser(values: {
  displayName?: string;
  photoURL?: string;
  timeZone?: string;
  country?: string;
}) {
  return api("updateUser", values);
}
