import { collection, query, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore } from "utils/firebase";

interface Plan {
  id: string;
  name: string;
  description: string;
  monthlyPrice: number;
  annuallyPrice: number;
  clientLimit: number;
}

export function usePlans() {
  const [snapshot, loading, error] = useCollection(
    query(collection(firestore, "plans"), orderBy("monthlyPrice", "asc"))
  );

  let docs = [] as Plan[];

  if (snapshot?.docs) {
    docs = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as Plan[];
  }

  return { error, loading, data: docs };
}
