import DeleteIcon from "@mui/icons-material/Delete";
import { useParams, useNavigate, useLocation } from "react-router";
import { enqueueSnackbar } from "notistack";
import HeaderButton from "components/HeaderButton";

export default function HeaderDeleteButton({
  title,
  success,
  action,
  redirectTo = -1,
  edge,
}: {
  title: string;
  success: string;
  action: (params: any) => Promise<void>;
  redirectTo?: string | number;
  edge?: "start" | "end";
}) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let pathname = redirectTo;

  if (typeof redirectTo === "string") {
    if ([".", "..", "../.."].includes(redirectTo)) {
      const url = new URL(redirectTo, window.origin + location.pathname);
      pathname = url.pathname.split("/").filter(Boolean).join("/");
    }
  }

  const onClick = () => {
    if (confirm(title + "?")) {
      navigate(pathname as string);
      action(params)
        .then(() => {
          enqueueSnackbar(success, {
            variant: "success",
          });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
  };

  return (
    <HeaderButton
      title={title}
      icon={<DeleteIcon />}
      onClick={onClick}
      edge={edge}
    />
  );
}
