import Fab from "@mui/material/Fab";
import VideocamIcon from "@mui/icons-material/Videocam";

export default function SendVideoButton(
  props: React.ComponentProps<typeof Fab>
) {
  return (
    <div>
      <Fab size="medium" color="primary" {...props}>
        <VideocamIcon />
      </Fab>
    </div>
  );
}
