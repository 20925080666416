import FormList, { type Schema } from "components/FormList";
import copy from "copy-to-clipboard";
import { enqueueSnackbar } from "notistack";
import { useInvitedCoaches } from "./queries";

export default function InvitationSentScreen() {
  const invitedCoaches = useInvitedCoaches();

  const subtitle = `Invite your friends to become a trainer in the online fitness app and get 1 month of free service for each trainer who registers using your link.`;
  const invitationLink = `https://onlinefitnessapp.com/invitation/123456`;
  const schema: Schema = [
    {
      fields: [
        {
          type: "typography",
          variant: "body1",
          color: "textSecondary",
          text: subtitle,
        },
      ],
    },
    {
      header: "Invitation Link",
      footer: "Copy and share this link with your friends",
      fields: [
        {
          type: "text",
          name: "invitationLink",
          onClick: () => {
            copy(invitationLink);
            enqueueSnackbar("Link copied", { variant: "success" });
          },
        },
      ],
    },
  ];

  if (invitedCoaches.length) {
    schema.push({
      header: "Invited Coaches",
      fields: invitedCoaches.map((coach) => ({
        type: "link",
        primary: coach.displayName,
        secondary: coach.email,
      })),
    });
  }

  const values = {
    invitationLink,
  };

  return <FormList schema={schema} values={values} />;
}
