import api from "utils/api";

export async function updateClientWorkoutExercise(values: {
  clientId: string;
  workoutId: string;
  exerciseId: string;
  title?: string;
  description?: string;
  completed?: boolean;
}) {
  await api("updateClientWorkoutExercise", values);
}
