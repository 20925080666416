import TeamsPage from "./page";
import TeamPage from "./[teamId]/page";
import EditTeamPage from "./[teamId]/edit/page";

import CreateTeamMemberPage from "./[teamId]/members/create/page";

import HeaderAddButton from "components/HeaderAddButton";
import HeaderBackButton from "components/HeaderBackButton";
import HeaderEditButton from "components/HeaderEditButton";

const routes = [
  {
    index: true,
    element: <TeamsPage />,
    handle: {
      header: {
        title: "coach.screens.teams.title",
        search: true,
      },
    },
  },
  {
    path: ":teamId",
    children: [
      {
        index: true,
        element: <TeamPage />,
        handle: {
          header: {
            title: "coach.screens.team.title",
            back: <HeaderBackButton to="." />,
            action: [
              <HeaderEditButton title="Edit team" key="edit" />,
              <HeaderAddButton
                key="add"
                title="Add team member"
                to="members/create"
              />,
            ],
          },
        },
      },
      {
        path: "edit",
        element: <EditTeamPage />,
        handle: {
          header: {
            title: "coach.screens.edit-team.title",
            back: <HeaderBackButton to=".." />,
          },
        },
      },
      {
        path: "members",
        children: [
          {
            path: "create",
            element: <CreateTeamMemberPage />,
            handle: {
              header: {
                title: "coach.screens.create-team-member.title",
                back: <HeaderBackButton to=".." />,
              },
            },
          },
        ],
      },
    ],
  },
];

export default routes;
