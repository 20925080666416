import {
  type Section,
  SectionList,
  SectionListItem,
  SectionListEmpty,
} from "components/SectionList";
import { usePrograms } from "./queries";
import { useSearchParams } from "react-router";
import { useTranslation } from "react-i18next";

interface Program {
  id: string;
  title: string;
}

export default function ProgramsPage() {
  const { t } = useTranslation("coach");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") as string | undefined;
  const programs = usePrograms(search);

  if (programs === "empty") {
    if (search) {
      return (
        <SectionListEmpty
          title={t("screens.programs.search.empty.title")}
          text={t("screens.programs.search.empty.text")}
          icon="/flaticon/search.png"
        />
      );
    }
    return (
      <SectionListEmpty
        title={t("screens.programs.empty.title")}
        text={t("screens.programs.empty.text")}
        buttonText={t("screens.programs.empty.button")}
        buttonTo="create"
        icon="/flaticon/schedule.png"
      />
    );
  }

  const sections: Section<Program>[] = [
    {
      square: true,
      dividerVariant: "fullWidth" as const,
      items: programs,
      renderItem: (program) => (
        <SectionListItem to={program.id} primary={program.title} />
      ),
    },
  ];

  return <SectionList sections={sections} />;
}
