import { type Dayjs } from "dayjs";
import dayjs from "dayjs";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import { useMemo } from "react";
import { Link } from "react-router";
import { Workout } from "features/workouts";

export default function WorkoutCalendar({
  date,
  workoutsByDate,
  openWorkoutPageIfOnlyOneWorkout,
  disableEmptyDays,
}: {
  date: Dayjs;
  workoutsByDate: Record<string, Workout[]>;
  openWorkoutPageIfOnlyOneWorkout?: boolean;
  disableEmptyDays?: boolean;
}) {
  const calendar = useMemo(() => createCalendar(date), [date]);

  return (
    <>
      <Grid
        container
        spacing={0.125}
        columns={7}
        alignItems="stretch"
        justifyContent={"center"}
      >
        {Array.from({ length: 7 }, (_: unknown, day: number) => (
          <Grid size={1} key={day}>
            <Card
              elevation={0}
              square
              sx={{
                height: "100%",
                width: "100%",
                p: 1,
              }}
            >
              <Typography
                variant="caption"
                align="center"
                color="textSecondary"
                display={"block"}
              >
                {dayjs().weekday(day).format("ddd")}
              </Typography>
            </Card>
          </Grid>
        ))}
        {calendar.map((day) => {
          const isCurrentMonth = day.isSame(date, "month");
          const isCurrentDay = day.isSame(dayjs(), "day");
          const key = day.format("YYYY-MM-DD");
          const workouts: Workout[] = workoutsByDate[key] || [];
          const to =
            openWorkoutPageIfOnlyOneWorkout && workouts.length === 1
              ? `${workouts[0].id}`
              : `?date=${key}`;
          return (
            <Grid size={1} key={day.format("YYYY-MM-DD")}>
              <Card
                elevation={0}
                square
                variant={isCurrentDay ? "outlined" : "elevation"}
                sx={{
                  height: "100%",
                  width: "100%",
                  borderColor: isCurrentDay ? "primary.main" : "transparent",
                }}
              >
                <CardActionArea
                  disabled={disableEmptyDays && workouts.length === 0}
                  component={Link}
                  to={to}
                  sx={{
                    p: 0.25,
                    minHeight: { xs: 75, sm: 100 },
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    justifyContent: "flex-start",
                    opacity: isCurrentMonth ? 1 : 0.3,
                  }}
                >
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    variant="subtitle2"
                    align="center"
                  >
                    {day.format("D")}
                  </Typography>
                  <DayItems workouts={workouts} />
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

function createCalendar(date: Dayjs) {
  const start = dayjs(date).startOf("month").startOf("week");
  const end = dayjs(date).endOf("month").endOf("week");

  const calendar = [];

  let current = start;

  while (current.isBefore(end)) {
    calendar.push(current);
    current = current.add(1, "day");
  }

  return calendar;
}

function DayItems({ workouts }: { workouts: Workout[] }) {
  const displayCount = 2;
  const moreCount = workouts.length - displayCount;
  return (
    <Grid container spacing={0.25}>
      {workouts.slice(0, displayCount).map((workout: Workout) => {
        return (
          <Grid size={12} key={workout.id}>
            <Paper
              elevation={0}
              sx={{
                // minHeight: 4,
                lineHeight: 1,
                bgcolor: workout.completed ? "success.main" : "primary.main",
                display: "flex",
                padding: { xs: "1px", sm: "3px" },
              }}
            >
              <Typography
                variant="caption"
                color="primary.contrastText"
                noWrap
                sx={{ lineHeight: 1, fontSize: "0.65rem", fontWeight: 500 }}
              >
                {workout.title}
              </Typography>
            </Paper>
          </Grid>
        );
      })}
      {moreCount > 0 && (
        <Grid size={12}>
          <Typography
            display="block"
            variant="caption"
            align="center"
            color="textSecondary"
            noWrap
            sx={{ lineHeight: 1, fontSize: "0.65rem", fontWeight: 500 }}
          >
            +{moreCount}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
