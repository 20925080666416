import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { firestore, auth } from "utils/firebase";
import { CoachUserData } from "features/user";

export function useCoach(coachId: string) {
  const [value, loading, error] = useDocumentData(
    doc(firestore, "users", auth.currentUser!.uid, "coaches", coachId)
  );

  return {
    loading,
    error,
    coachData: value as CoachUserData | undefined,
  };
}
