import FormList, { type Schema } from "components/FormList";
import { useTranslation } from "react-i18next";
import { createExerciseResult } from "./actions";
import { useNavigate, useParams } from "react-router";
import { enqueueSnackbar } from "notistack";

export default function CreateExerciseResultPage() {
  const { t } = useTranslation("client");
  const navigate = useNavigate();
  const { workoutId, exerciseId } = useParams();

  const schema: Schema = [
    {
      square: true,
      dividerVariant: "middle",
      fields: [
        {
          type: "switch",
          name: "completed",
          primary: t("screens.create-exercise-result.fields.completed"),
        },
        {
          type: "text",
          name: "text",
          placeholder: t("screens.create-exercise-result.fields.text"),
          multiline: true,
          autoFocus: true,
        },
      ],
    },

    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.create-exercise-result.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: { text: string; completed: boolean }) => {
    await createExerciseResult({
      ...values,
      workoutId,
      exerciseId,
      completed: !!values.completed,
    });

    enqueueSnackbar(t("screens.create-exercise-result.success"), {
      variant: "success",
    });

    navigate(`../..`);
  };

  return <FormList schema={schema} onSubmit={onSubmit} />;
}
