import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, collection, query, where, orderBy } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import type { WorkoutData } from "features/workouts";
import type { Exercise, ExerciseData } from "features/exercises";

export function useClientWorkout({
  clientId,
  workoutId,
}: {
  clientId: string;
  workoutId: string;
}) {
  const [value, loading, error] = useDocumentData(
    doc(firestore, "users", clientId, "workouts", workoutId)
  );

  return {
    error,
    loading,
    data: value as WorkoutData | null,
  };
}

export function useClientWorkoutExercises({
  clientId,
  workoutId,
  exerciseIds = [],
}: {
  clientId: string;
  workoutId: string;
  exerciseIds: string[];
}) {
  const [snapshot, loading, error] = useCollection(
    query(
      collection(
        firestore,
        "users",
        clientId,
        "workouts",
        workoutId,
        "exercises"
      ),
      where("creatorId", "==", auth.currentUser!.uid),
      orderBy("createdAt", "desc")
    )
  );

  let data: Exercise[] = [];

  if (snapshot?.docs) {
    const exercisesById = snapshot.docs.reduce(
      (all: Record<string, Exercise>, doc) => {
        all[doc.id] = {
          id: doc.id,
          ...(doc.data() as ExerciseData),
        } as Exercise;
        return all;
      },
      {}
    );

    data = exerciseIds.map((id) => exercisesById[id]).filter(Boolean);
  }

  return { error, loading, data };
}
