import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";

interface Upload {
  progress: number;
  success: boolean;
}

export default function VideoUploadProgress({ upload }: { upload: Upload }) {
  if (!upload) {
    return null;
  }

  if (upload.success) {
    return <DoneIcon color="success" />;
  }

  return (
    <CircularProgress variant="determinate" value={upload.progress} size={40} />
  );
}
