import { useClientWorkoutExercise } from "./queries";
import {
  SectionList,
  SectionListItem,
  type Section,
} from "components/SectionList";
import Player from "components/Player";
import formatTextToHtml from "utils/formatTextToHtml";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ErrorScreen from "components/ErrorScreen";
import NotFound from "components/NotFound";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { ClientWorkoutExercise } from "features/exercises";
import DoneIconButton from "components/DoneIconButton";
import { updateClientWorkoutExercise } from "./actions";
import { enqueueSnackbar } from "notistack";

export default function ClientWorkoutExercisePage() {
  const { t } = useTranslation("coach");

  const { clientId, workoutId, exerciseId } = useParams() as {
    clientId: string;
    workoutId: string;
    exerciseId: string;
  };

  const { loading, error, data } = useClientWorkoutExercise({
    clientId,
    workoutId,
    exerciseId,
  });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!data) {
    return (
      <NotFound
        icon={<VideoLibraryIcon sx={{ fontSize: 60 }} />}
        title={t("screens.client-workout-exercise.not-found.title")}
        text={t("screens.client-workout-exercise.not-found.text")}
      />
    );
  }

  const sections: Section<ClientWorkoutExercise>[] = [
    {
      square: true,
      items: [data],
      renderItem: (item) => (
        <SectionListItem
          primary={item.title}
          slotProps={{
            primary: {
              variant: "h6",
              gutterBottom: !!item.description,
            },
          }}
          secondary={
            item.description && (
              <span
                dangerouslySetInnerHTML={{
                  __html: formatTextToHtml(item.description),
                }}
              />
            )
          }
          alignItems="flex-start"
          action={
            <DoneIconButton
              done={item.completed}
              onClick={() => {
                updateClientWorkoutExercise({
                  clientId,
                  workoutId,
                  exerciseId,
                  completed: !item.completed,
                }).then(() => {
                  enqueueSnackbar(
                    item.completed
                      ? t("screens.client-workout-exercise.markedAsUncompleted")
                      : t("screens.client-workout-exercise.markedAsCompleted"),
                    {
                      variant: "success",
                    }
                  );
                });
              }}
              title={
                item.completed
                  ? t("screens.client-workout-exercise.markAsUncompleted")
                  : t("screens.client-workout-exercise.markAsCompleted")
              }
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <Player
        video={data.video}
        metadataVideoId={exerciseId}
        metadataVideoTitle={data.title}
      />
      <SectionList sections={sections} spacing={0} />
    </>
  );
}
