import { Suspense } from "react";
import SplashScreen from "components/SplashScreen";
import { ViewerProvider, useUserProfile } from "features/viewer";
import { Outlet, ScrollRestoration } from "react-router";

export default function RootLayout() {
  const { loading, userProfile } = useUserProfile();

  if (loading) return <SplashScreen />;

  return (
    <ViewerProvider viewer={userProfile}>
      <ScrollRestoration />

      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </ViewerProvider>
  );
}
