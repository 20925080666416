import React, { useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

export default function SelectField({
  value,
  options,
  onChange,
}: {
  value: number;
  options: { value: number; label: string }[];
  onChange: (value: number) => void;
}) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(Number(event.target.value));
    },
    [onChange]
  );

  return (
    <TextField
      select
      fullWidth
      value={value}
      onChange={handleChange}
      variant="standard"
      slotProps={{
        input: {
          disableUnderline: true,
        },
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
