import {
  type Section,
  SectionList,
  SectionListItem,
  SectionListEmpty,
} from "components/SectionList";
import { useProgram, useProgramWorkouts } from "./queries";
import groupToSections from "utils/groupToSections";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ErrorScreen from "components/ErrorScreen";
import NotFound from "components/NotFound";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import type { Program, ProgramWorkout } from "features/programs";

export default function ProgramScreen() {
  const { t } = useTranslation("coach");
  const { programId } = useParams() as { programId: string };
  const { loading, error, data } = useProgram({ programId });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;
  if (!data)
    return (
      <NotFound
        icon={<FitnessCenterIcon sx={{ fontSize: 60 }} />}
        title={t("screens.programs.not-found.title")}
        text={t("screens.programs.not-found.text")}
      />
    );

  const sections: Section<Program>[] = [
    {
      square: true,
      items: [data].filter(Boolean),
      renderItem: (program) => (
        <SectionListItem
          primary={program.title}
          secondary={program.description}
          slotProps={{
            primary: {
              variant: "h6",
              gutterBottom: !!program.description,
            },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <SectionList sections={sections} spacing={0} />
      <ProgramWorkoutList programId={programId} />
    </>
  );
}

function ProgramWorkoutList({ programId }: { programId: string }) {
  const { t } = useTranslation("coach");

  const { loading, error, data } = useProgramWorkouts({ programId });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;

  if (data.length === 0) {
    return (
      <SectionListEmpty
        title={t("screens.programs.workouts.empty.title")}
        text={t("screens.programs.workouts.empty.text")}
        buttonText={t("screens.programs.workouts.empty.button")}
        buttonTo="workouts/create"
        icon="/flaticon/schedule.png"
      />
    );
  }

  const renderHeader = (number: number) =>
    t("screens.programs.workouts.day", { number });
  let sections: Section<ProgramWorkout>[] = groupToSections(
    data,
    "day",
    renderHeader
  );

  sections = sections.map((section) => ({
    square: true,
    dividerVariant: "middle",
    header: section.header,
    items: section.items,
    renderItem: (workout) => (
      <SectionListItem
        to={`workouts/${workout.id}`}
        primary={workout.title}
        secondary={
          workout.exerciseIds.length > 0 &&
          t("screens.client-workouts.exercises", {
            count: workout.exerciseIds.length,
          })
        }
      />
    ),
  }));

  return <SectionList sections={sections} spacing={0} />;
}
