import InvitationLayout from "./[invitationId]/layout";
import InvitationPage from "./[invitationId]/page";
import AccountPage from "./[invitationId]/account/page";
import ProfilePage from "./[invitationId]/profile/page";

const routes = [
  {
    path: ":invitationId",
    element: <InvitationLayout />,
    children: [
      {
        index: true,
        element: <InvitationPage />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
    ],
  },
];

export default routes;
