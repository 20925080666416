import Container from "components/Container";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { Helmet } from "react-helmet-async";

export default function FeaturesPage() {
  const features = [
    {
      title: "Client Management",
      description: "This is a feature description",
      image: "/demo/feature-1.png",
    },
    {
      title: "Program Builder",
      description: "This is a feature description",
      image: "/demo/feature-2.png",
    },
    {
      title: "Exercise Library",
      description: "This is a feature description",
      image: "/demo/feature-2.png",
    },
    {
      title: "Sell Courses and Programs",
      description: "This is a feature description",
      image: "/demo/feature-1.png",
    },
    {
      title: "Offline Mode",
      description: "This is a feature description",
      image: "/demo/feature-1.png",
    },
    {
      title: "Bulk Video Upload",
      description: "This is a feature description",
      image: "/demo/feature-2.png",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Features</title>
      </Helmet>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {features.map((feature, index) => (
            <Grid
              key={index}
              size={{
                xs: 12,
                sm: 6,
                md: 4,
              }}
            >
              <Card elevation={0}>
                <CardMedia
                  component="img"
                  // height={200}
                  image={feature.image}
                  alt={feature.title}
                />
                <CardHeader
                  title={feature.title}
                  subheader={feature.description}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
