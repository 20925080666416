import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";

export default function SendTextButton({
  visible,
  onClick,
}: {
  visible: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <Zoom in={visible}>
      <IconButton
        onClick={onClick}
        edge="start"
        size="large"
        color="primary"
        type="submit"
        name="submit-button"
      >
        <SendIcon />
      </IconButton>
    </Zoom>
  );
}
