import ErrorScreen from "components/ErrorScreen";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { Link } from "react-router";
import Avatar from "@mui/material/Avatar";

export default function SectionListEmpty({
  title,
  text,
  icon,
  buttonText,
  buttonTo,
}: {
  title?: string;
  text?: string;
  icon?: string;
  buttonText?: string;
  buttonTo?: string;
}) {
  return (
    <Card elevation={0} sx={{ px: 2, py: 10 }}>
      <ErrorScreen
        icon={
          icon && (
            <Avatar
              variant="square"
              src={icon}
              alt="icon"
              sx={{ width: 60, height: 60 }}
            />
          )
        }
        title={title}
        text={text}
        button={
          buttonText &&
          buttonTo && (
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={buttonTo}
            >
              {buttonText}
            </Button>
          )
        }
      />
    </Card>
  );
}
