import FormList, { type Schema } from "components/FormList";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getToken } from "firebase/app-check";
import { auth, appCheck } from "utils/firebase";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router";
import { logEvent } from "utils/analytics";

export default function SignInScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  const onSubmit = async (values: { email: string; password: string }) => {
    const { email, password } = values;

    const token = await getToken(appCheck, true); // Запрашиваем принудительно новый токен на случай, если он устарел

    console.log("token", token);

    await signInWithEmailAndPassword(auth, email, password);

    logEvent("login", {
      method: "email",
    });

    enqueueSnackbar(t("screens.sign-in.success"), {
      variant: "success",
    });

    navigate("/coach");
  };

  const schema: Schema = [
    {
      header: t("screens.sign-in.header"),
      dividerVariant: "fullWidth",
      fields: [
        {
          type: "email",
          placeholder: t("screens.sign-in.email"),
          name: "email",
          required: true,
        },
        {
          type: "password",
          placeholder: t("screens.sign-in.password"),
          name: "password",
          required: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          // position: "fixed",
          label: t("screens.sign-in.submit"),
          color: "primary",
          variant: "contained",
          size: "large",
        },
      ],
    },

    {
      fields: [
        {
          type: "link",
          primary: t("screens.sign-in.forgotPassword"),
          to: "/forgot-password",
        },
        {
          type: "link",
          primary: t("screens.sign-in.noAccount"),
          to: "/signup",
        },
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("screens.sign-in.title")}</title>
      </Helmet>
      <FormList schema={schema} onSubmit={onSubmit} />
    </>
  );
}
