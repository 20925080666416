import FormList, { type Schema } from "components/FormList";
import { useParams, useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useTeam } from "./queries";
import { updateTeam } from "./actions";

export default function EditTeamScreen() {
  const { t } = useTranslation("coach");
  const { teamId } = useParams() as { teamId: string };
  const team = useTeam();
  const navigate = useNavigate();

  const schema: Schema = [
    {
      header: t("screens.edit-team.header"),
      dividerVariant: "middle",
      fields: [
        {
          type: "text",
          name: "title",
          placeholder: t("screens.edit-team.fields.title"),
          required: true,
          autoFocus: true,
          multiline: true,
        },
        {
          type: "text",
          name: "description",
          placeholder: t("screens.edit-team.fields.description"),
          multiline: true,
        },
      ],
    },
    {
      backgroundColor: "transparent",
      fields: [
        {
          type: "submit",
          size: "large",
          label: t("screens.edit-team.submit"),
          color: "primary",
          variant: "contained",
          position: "fixed",
        },
      ],
    },
  ];

  const onSubmit = async (values: { title: string; description?: string }) => {
    await updateTeam({ teamId, ...values });
    enqueueSnackbar(t("screens.edit-team.success"), {
      variant: "success",
    });
    navigate(-1);
  };

  if (!team) return null;

  const values = {
    title: team.title,
    description: team.description,
  };

  return <FormList schema={schema} values={values} onSubmit={onSubmit} />;
}
