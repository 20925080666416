import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, collection, query, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, auth } from "utils/firebase";
import { Workout } from "features/workouts";
import { ExerciseData, Exercise } from "features/exercises";

export function useClientWorkout({ workoutId }: { workoutId: string }) {
  const [value, loading, error] = useDocumentData(
    doc(firestore, "users", auth.currentUser!.uid, "workouts", workoutId)
  );

  return {
    error,
    loading,
    data: value as Workout | undefined,
  };
}

export function useClientWorkoutExercises({
  workoutId,
}: {
  workoutId: string;
}) {
  const [snapshot1] = useCollection(
    query(
      collection(firestore, "users", auth.currentUser!.uid, "coaches"),
      where("status", "==", "active")
    )
  );

  const coachIds = snapshot1?.docs.map((doc) => doc.id);
  const [snapshot, loading, error] = useCollection(
    coachIds &&
      query(
        collection(
          firestore,
          "users",
          auth.currentUser!.uid,
          "workouts",
          workoutId,
          "exercises"
        ),
        where("creatorId", "in", coachIds)
      )
  );

  return {
    error,
    loading,
    data:
      (snapshot?.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as ExerciseData),
      })) as Exercise[]) || [],
  };
}
