import HeaderBackButton from "components/HeaderBackButton";
import CoachesPage from "./page";
import CoachPage from "./[coachId]/page";
import { ChatType } from "features/chat";

const routes = [
  {
    index: true,
    element: <CoachesPage />,
    handle: {
      header: {
        title: "client.screens.coaches.title",
        search: true,
      },
    },
  },
  {
    path: ":coachId",
    children: [
      {
        index: true,
        element: <CoachPage />,
        handle: {
          chat: {
            type: ChatType.Client,
            params: ["coachId"],
          },
          header: {
            title: "client.screens.coach.title",
            back: <HeaderBackButton to="." />,
          },
        },
      },
    ],
  },
];

export default routes;
