import { filesize } from "filesize";

export default function FileListItem({ file }: { file: File }) {
  return (
    <div>
      <div>{file.name}</div>
      <div>{filesize(file.size)}</div>
      <div>{file.type}</div>
    </div>
  );
}
