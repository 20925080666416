import Paper from "@mui/material/Paper";
import ErrorScreen from "components/ErrorScreen";

export default function NotFound({
  title,
  text,
  icon,
}: {
  title: string;
  text: string;
  icon: React.ReactNode;
}) {
  return (
    <Paper elevation={0} sx={{ py: 10, px: 2 }}>
      <ErrorScreen icon={icon} title={title} text={text} />
    </Paper>
  );
}
