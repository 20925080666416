import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import FileListItem from "./FileListItem";

export default function FileList({ files }: { files: File[] }) {
  return (
    <Box>
      <Grid container spacing={1}>
        {files.map((file, index) => (
          <Grid key={index}>
            <FileListItem file={file} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
