// import Button from "@mui/material/Button";
import { PushNotificationStatus } from "../types";
import { usePushNotificationStatus } from "../queries";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";

// const mapStatusToButtonText = {
//   [PushNotificationStatus.Enabled]: "Disable notifications",
//   [PushNotificationStatus.Disabled]: "Enable notifications",
//   [PushNotificationStatus.Default]: "Enable notifications",
//   [PushNotificationStatus.Denied]: "Denied",
//   [PushNotificationStatus.Loading]: "Loading",
//   [PushNotificationStatus.Error]: "Error",
// };

// const mapStatusToPrimaryText = {
//   [PushNotificationStatus.Enabled]: "Push notifications enabled",
//   [PushNotificationStatus.Disabled]: "Push notifications disabled",
//   [PushNotificationStatus.Default]: "Push notifications",
//   [PushNotificationStatus.Denied]: "Push notifications denied",
//   [PushNotificationStatus.Loading]: "Push notifications",
//   [PushNotificationStatus.Error]: "Push notifications",
// };

export default function PushNotification() {
  const { status, error, onClick } = usePushNotificationStatus();

  const isError = [
    PushNotificationStatus.Denied,
    PushNotificationStatus.Error,
  ].includes(status);

  const mapStatusToSecondaryText = {
    [PushNotificationStatus.Enabled]: null,
    [PushNotificationStatus.Disabled]: null,
    [PushNotificationStatus.Default]: null,
    [PushNotificationStatus.Denied]:
      "You have blocked notifications. Please enable them in your browser settings",
    [PushNotificationStatus.Loading]: null,
    [PushNotificationStatus.Error]: error?.message,
  };

  return (
    <Paper square elevation={0}>
      <ListItem
        component="div"
        secondaryAction={
          !isError && (
            <Switch
              // variant="contained"
              // color="primary"
              // size="small"
              // fullWidth
              checked={status === PushNotificationStatus.Enabled}
              disabled={
                status === PushNotificationStatus.Denied ||
                status === PushNotificationStatus.Loading
              }
              // loading={status === PushNotificationStatus.Loading}
              onClick={onClick}
            />
          )
        }
      >
        <ListItemText
          primary={"Push notifications"}
          secondary={mapStatusToSecondaryText[status]}
          slotProps={{
            secondary: {
              color: isError ? "error" : "textSecondary",
            },
          }}
        />
      </ListItem>
    </Paper>
  );
}

// function Ololo() {
//   const [permission, setPermission] = useState<Permission>(
//     Notification.permission
//   );

//   switch (permission) {
//     // Пользователь ещё не выбрал, разрешать или нет уведомления.
//     case "default":
//       console.log("render default");
//       return (
//         <Alert severity="warning" sx={{ borderRadius: 0 }}>
//           <Box sx={{ pb: 1 }}>
//             You have not enabled notifications. Click the button to enable them.
//           </Box>
//           <TogglePushNotificationButton
//             enabled={false}
//             setPermission={setPermission}
//           />
//         </Alert>
//       );

//     // Пользователь запретил отправку уведомлений. Теперь ему необходимо сбросить это разрешение в настройках браузера.
//     case "denied":
//       console.log("render denied");
//       return (
//         <Alert severity="error" sx={{ borderRadius: 0 }}>
//           You have blocked notifications. Please enable them in your browser
//           settings.
//         </Alert>
//       );

//     // Пользователь разрешил отправку уведомлений.
//     case "granted":
//       console.log("render granted");
//       // console.log("Пользователь уже дал разрешение на уведомления.");
//       return <PushNotificationStatus />;

//     default:
//       return null;
//   }
// }

// function PushNotificationStatus() {
//   const { error, loading, enabled } = usePushNotificationStatus();

//   if (error) return "error";
//   if (loading) return "loading";

//   if (enabled) {
//     return (
//       <>
//         <Alert severity="success" sx={{ borderRadius: 0 }}>
//           <Box sx={{ pb: 1 }}>
//             You have enabled notifications. You will receive them when app is
//             closed.
//           </Box>
//         </Alert>
//         <TogglePushNotificationButton enabled={true} />
//       </>
//     );
//   }

//   return (
//     <>
//       <Alert severity="warning" sx={{ borderRadius: 0 }}>
//         <Box sx={{ pb: 1 }}>
//           You have disabled notifications. You will not receive them when app is
//           closed.
//         </Box>
//       </Alert>
//       <TogglePushNotificationButton enabled={false} />
//     </>
//   );
// }

// function TogglePushNotificationButton({
//   status,
//   setPermission,
// }: {
//   status: PushNotificationStatus;
//   setPermission?: (permission: Permission) => void;
// }) {
//   // const [loading, setLoading] = useState(false);
//   //   const [error, setError] = useState(null);
//   const onClick = () => {
//     // setLoading(true);
//     // setError(null);
//     // if (enabled) {
//     //   disableNotifications()
//     //     .then(setPermission)
//     //     .catch(setError)
//     //     .finally(() => setLoading(false));
//     // } else {
//     //   enableNotifications()
//     //     .then(setPermission)
//     //     .catch(setError)
//     //     .finally(() => setLoading(false));
//     // }
//   };

//   return (
//     <Button
//       variant="contained"
//       color="primary"
//       size="small"
//       fullWidth
//       loading={status === "loading"}
//       onClick={onClick}
//     >
//       {status === "enabled" ? "Disable notifications" : "Enable notifications"}
//     </Button>
//   );
// }
