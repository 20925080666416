import {
  type Section,
  SectionList,
  // SectionListItem,
  SectionListEmpty,
  // SectionListItemThumbnail,
} from "components/SectionList";
import { useProgramWorkout, useProgramWorkoutExercises } from "./queries";
// import { getThumbnailUrl } from "utils/mux";
// import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ProgramWorkout } from "features/workouts";
import { ProgramWorkoutExercise } from "features/exercises";

// interface ProgramWorkout {
//   id: string;
//   title: string;
//   description?: string;
//   exerciseIds: string[];
// }

export default function ProgramWorkoutScreen() {
  const programWorkout = useProgramWorkout();
  const programWorkoutExercises = useProgramWorkoutExercises(
    programWorkout?.exerciseIds
  );

  if (!programWorkout) return null;

  const sections: Section<ProgramWorkout | ProgramWorkoutExercise>[] = [
    {
      square: true,
      items: [programWorkout],
      // renderItem: (programWorkout: ProgramWorkout) => (
      //   <SectionListItem
      //     primary={programWorkout.title}
      //     slotProps={{
      //       primary: {
      //         variant: "h5",
      //         gutterBottom: !!programWorkout.description,
      //       },
      //     }}
      //     secondary={programWorkout.description}
      //   />
      // ),
    },
  ];

  if (programWorkoutExercises === "empty") {
    sections.push({
      component: (
        <SectionListEmpty
          title="No exercises"
          text="You don't have any exercises yet."
          buttonText="Add exercise"
          buttonTo="exercises/add"
          icon="/flaticon/computer.png"
        />
      ),
    });
  } else if (programWorkoutExercises.length > 0) {
    sections.push({
      header: "Exercises",
      // // onSortChange: (items) => {
      // //   const exerciseIds = items.map((item) => item.id);

      // //   updateProgramWorkout(programId, workoutId, { exerciseIds }).catch(
      // //     (error) => console.error("Failed to update program workout", error)
      // //   );
      // // },
      items: programWorkoutExercises,
      // renderItem: (programWorkoutExercise, index) => (
      //   <SectionListItem
      //     to={`exercises/${programWorkoutExercise.id}/edit`}
      //     primary={programWorkoutExercise.title}
      //     // secondary={programWorkoutExercise.type}
      //     avatar={<Avatar>{index + 1}</Avatar>}
      //   />
      // ),
      square: true,
      // renderItem: (exercise: any) => {
      //   const thumbnailUrl = getThumbnailUrl(exercise?.video?.playbackId, {
      //     width: "80",
      //     height: "80",
      //     fit_mode: "smartcrop",
      //   });
      //   return (
      //     <SectionListItem
      //       to={`exercises/${exercise.id}/edit`}
      //       primary={exercise.title}
      //       avatar={<SectionListItemThumbnail src={thumbnailUrl} />}
      //       action={exercise.completed && <TaskAltIcon color="success" />}
      //     />
      //   );
      // },
    });
  }

  return <SectionList sections={sections} spacing={0} />;
}
