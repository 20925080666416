import FaqPage from "./faq/page";
import AboutPage from "./about/page";
import PricingPage from "./pricing/page";
import FeaturesPage from "./features/page";
import GuaranteePage from "./guarantee/page";
import FoundersPage from "./founders/page";
import PrivacyPolicyPage from "./privacy-policy/page";
import RefundPolicyPage from "./refund-policy/page";
import TermsOfServicePage from "./terms-of-service/page";

const routes = [
  {
    path: "faq",
    element: <FaqPage />,
  },
  {
    path: "about",
    element: <AboutPage />,
  },
  {
    path: "pricing",
    element: <PricingPage />,
  },
  {
    path: "features",
    element: <FeaturesPage />,
  },
  {
    path: "guarantee",
    element: <GuaranteePage />,
  },
  {
    path: "founders",
    element: <FoundersPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "refund-policy",
    element: <RefundPolicyPage />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfServicePage />,
  },
];

export default routes;
