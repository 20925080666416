import NotificationReadStatusToggleButton from "./NotificationReadStatusToggleButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import getNotificationText from "./getNotificationText";
import DateFromNow from "components/DateFromNow";
import Avatar from "@mui/material/Avatar";
import { Notification } from "../../types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { markNotificationAsRead } from "../../actions";
import { useCallback } from "react";

export default function NotificationItem({
  notification,
}: {
  notification: Notification;
}) {
  const { t } = useTranslation("components");
  const { primary, secondary, to } = getNotificationText(notification, t);
  const { creator } = notification.data;

  const onClick = useCallback(() => {
    markNotificationAsRead({ notificationId: notification.id });
  }, [notification.id]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={to}
        disableRipple
        alignItems="flex-start"
        onClick={onClick}
      >
        <ListItemAvatar>
          <Avatar src={creator?.photoURL} />
        </ListItemAvatar>
        <ListItemText
          sx={{ opacity: notification.isRead ? 0.5 : 1 }}
          disableTypography
          primary={
            primary && (
              <Typography
                variant="body2"
                color={notification.isRead ? "textSecondary" : "textPrimary"}
              >
                {primary}
              </Typography>
            )
          }
          secondary={
            <>
              {secondary && (
                <Typography
                  variant="body2"
                  color={notification.isRead ? "textSecondary" : "textPrimary"}
                >
                  {secondary}
                </Typography>
              )}
              <DateFromNow date={notification.createdAt.toDate()} />
            </>
          }
        />
        <NotificationReadStatusToggleButton notification={notification} />
      </ListItemButton>
    </ListItem>
  );
}
