import Typography from "@mui/material/Typography";

export default function SectionFooter({
  children,
  align,
}: {
  children: React.ReactNode;
  align?: "left" | "center" | "right";
}) {
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      sx={{ px: 2, pt: 1 }}
      display="block"
      align={align}
    >
      {children}
    </Typography>
  );
}
