import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Pricing from "components/Pricing";
import { Helmet } from "react-helmet-async";
import Container from "components/Container";

export default function PricingPage() {
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Pricing</title>
      </Helmet>
      <Box
        sx={{
          minHeight: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
          sx={{
            fontSize: "1.8rem",
            // fontWeight: 900,
            maxWidth: 800,
            lineHeight: 1.2,
            mb: 3,
          }}
        >
          Choose Your Plan
        </Typography>
        <Pricing />
      </Box>
    </Container>
  );
}
