import { Outlet } from "react-router";
import { useParams } from "react-router";
import ErrorScreen from "components/ErrorScreen";
import NotFound from "components/NotFound";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { useTranslation } from "react-i18next";
import { useInvitation, InvitationData } from "features/invitations";

export default function InvitationLayout() {
  const { t } = useTranslation("invitation");
  const { invitationId } = useParams() as { invitationId: string };
  const [snapshot, loading, error] = useInvitation({ invitationId });

  if (error) return <ErrorScreen error={error} />;
  if (loading) return null;

  if (!snapshot?.exists()) {
    return (
      <NotFound
        icon={<NoAccountsIcon sx={{ fontSize: 60 }} />}
        title={t("screens.invitation.notFound.title")}
        text={t("screens.invitation.notFound.text")}
      />
    );
  }

  return (
    <Outlet
      context={{
        invitationData: snapshot.data() as InvitationData,
        invitationId,
      }}
    />
  );
}
