import {
  SectionListEmpty,
  SectionList,
  SectionListItem,
} from "components/SectionList";
import { useClients } from "./queries";
import Avatar from "@mui/material/Avatar";
import { useSearchParams, useNavigate } from "react-router";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Client, ClientStatus } from "features/clients";
import { ChatIconButton, ChatType } from "features/chat";
import { auth } from "utils/firebase";
import SectionTabs from "components/SectionTabs";

export default function ClientsPage() {
  const { t } = useTranslation("coach");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") as string;
  const tab = (searchParams.get("tab") as ClientStatus) || ClientStatus.Active;
  const { data, loading, error } = useClients(search);
  const activeClients = data.filter(
    (client: Client) => client.status === ClientStatus.Active
  );
  const archivedClients = data.filter(
    (client: Client) => client.status === ClientStatus.Archived
  );
  const activeClientsCount = activeClients.length;
  const archivedClientsCount = archivedClients.length;
  const items = tab === ClientStatus.Active ? activeClients : archivedClients;

  const sections = [
    {
      loading,
      error,
      items,
      dividerVariant: "fullWidth" as const,
      square: true,
      renderItem: (client: Client) => (
        <ClientListItem client={client} navigate={navigate} />
      ),
      renderEmpty: () => {
        if (search) {
          return (
            <SectionListEmpty
              title={t("screens.clients.search.empty.title")}
              text={t("screens.clients.search.empty.text")}
              icon="/flaticon/search.png"
            />
          );
        }
        return (
          <SectionListEmpty
            title={t("screens.clients.empty.title")}
            text={t("screens.clients.empty.text")}
            buttonText={t("screens.clients.empty.button")}
            buttonTo="create"
            icon="/flaticon/lifestyle.png"
          />
        );
      },
    },
  ];

  const handleChangeTab = useCallback(
    (_: unknown, newValue: string) => {
      searchParams.set("tab", newValue);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const displayTabs = activeClientsCount > 0 && archivedClientsCount > 0;

  return (
    <>
      {displayTabs && (
        <SectionTabs
          tab={tab}
          onChange={handleChangeTab}
          tabs={[
            {
              value: ClientStatus.Active,
              label: t("screens.clients.tabs.active", {
                count: activeClientsCount,
              }),
            },
            {
              value: ClientStatus.Archived,
              label: t("screens.clients.tabs.archived", {
                count: archivedClientsCount,
              }),
            },
          ]}
        />
      )}
      <SectionList sections={sections} />
    </>
  );
}

function ClientListItem({
  client,
  navigate,
}: {
  client: Client;
  navigate: (path: string) => void;
}) {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      navigate(client.id + "?chat=true");
    },
    [client.id, navigate]
  );

  return (
    <SectionListItem
      primary={client.displayName}
      secondary={client.email}
      to={client.id}
      avatar={<Avatar src={client.photoURL} />}
      action={
        <ChatIconButton
          type={ChatType.Client}
          clientId={client.id}
          coachId={auth.currentUser!.uid}
          onClick={onClick}
        />
      }
    />
  );
}
