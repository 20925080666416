import EditIcon from "@mui/icons-material/Edit";
import HeaderActionButton from "components/HeaderActionButton";
import { useLocation } from "react-router";

export default function HeaderEditButton({
  title,
  edge,
}: {
  title: string;
  edge?: "start" | "end";
}) {
  const location = useLocation();

  return (
    <HeaderActionButton
      edge={edge}
      icon={<EditIcon />}
      title={title}
      to={location.pathname + "/edit"}
    />
  );
}
