import api from "utils/api";

export async function createClient(values: {
  displayName: string;
  email: string;
}): Promise<{
  clientId: string;
  invitationCode: string;
}> {
  const doc = await api("createClient", values);

  return doc.data as {
    clientId: string;
    invitationCode: string;
  };
}
