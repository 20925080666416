import { Link } from "react-router";
import HeaderButton from "components/HeaderButton";

export default function HeaderActionButton({
  title,
  to,
  edge,
  icon,
  relative,
  replace,
  color,
}: {
  title?: string;
  to: string | number;
  edge?: "start" | "end";
  icon: React.ReactNode;
  relative?: boolean;
  color?: string;
  replace?: boolean;
}) {
  return (
    <HeaderButton
      color={color}
      title={title}
      component={Link}
      replace={replace}
      to={to}
      edge={edge}
      icon={icon}
      relative={relative}
    />
  );
}
